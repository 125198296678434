import React, { useCallback, useEffect, useState } from "react";
import styles from "./GameStatusList.module.scss";

import moment from "moment";
import { ReactComponent as EditIcon } from "../../Img/GameStatus/editSquare.svg";
import { ReactComponent as TerminateIcon } from "../../Img/GameStatus/terminate.svg";
import avataroneImg from "../../Img/GameStatus/avatar_one.png";
import avatarTwoImg from "../../Img/GameStatus/avatar_two.png";
import avatarThreeImg from "../../Img/GameStatus/avatar_three.png";
import avatarMoreImg from "../../Img/GameStatus/avatar_more.png";
import defaultImg from "../../Img/GameStatus/defaultImg.png";
import GameStatusSkeleton from "../GameStatusSkeleton/GameStatusSkeleton";

export default function GameStatusList({
  setIsUpdate,
  setIsTerminate,
  isLoading,
  setIsSelectedType,
  gameList,
  setSelectItem,
}: {
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTerminate: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsSelectedType: React.Dispatch<
    React.SetStateAction<{ id: number; type: string }>
  >;
  gameList: Array<GameInfo>;
  setSelectItem: React.Dispatch<React.SetStateAction<number>>;
}) {
  useEffect(() => {
    setIsSelectedType({ id: 2, type: "Commercial Launch" });
  }, [setIsSelectedType]);
  const selectImg = useCallback((length: number) => {
    if (length === 1) {
      return avataroneImg;
    } else if (length === 2) {
      return avatarTwoImg;
    } else if (length === 3) {
      return avatarThreeImg;
    } else {
      return avatarMoreImg;
    }
  }, []);
  const addDefaultImg = (e: any) => {
    e.target.src = defaultImg;
  };
  const checkTerminate = useCallback((date?: string) => {
    return date !== null;
  }, []);
  return (
    <table className={styles.container}>
      <thead>
        <tr>
          <th className={styles.game}>Game</th>
          <th className={styles.studio}>Studio</th>
          <th className={styles.pm}>Publishing Manager</th>
          <th className={styles.status}>Game Status</th>
          <th className={styles.contract_date}>Contract Date</th>
          <th className={styles.scaleup_date}>Scale-up Date</th>
          <th className={styles.empty}></th>
          <th className={styles.empty}></th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <GameStatusSkeleton />
        ) : (
          gameList?.map((item) => {
            return (
              <tr key={item.id}>
                <th className={styles.game_info}>
                  <img
                    className={styles.game_info_icon}
                    src={item.gameIconUrl}
                    alt=""
                    onError={addDefaultImg}
                  />
                  <p className={styles.game_info_mane}>{item?.name}</p>
                </th>
                <th className={styles.studio_name}>
                  <p>{item?.studio?.name}</p>
                </th>
                <th className={styles.pm_list}>
                  {item?.studio?.adminUserIds?.length ? (
                    <img
                      className={styles.avatar_img}
                      src={selectImg(item?.studio?.adminUserIds?.length)}
                      alt="img"
                      data-content={item?.studio?.adminUserIds?.length}
                    />
                  ) : (
                    ""
                  )}
                  <div className={styles.pm_email_list}>
                    {item.studio.adminUserIds.map((pms) => (
                      <div>
                        <p>{pms?.name}</p>
                        <p className={styles.pm_email}>{pms?.email}</p>
                      </div>
                    ))}
                  </div>
                </th>
                <th className={styles.game_status}>
                  {checkTerminate(item.terminate) ? (
                    <p data-status="Terminate">TERM</p>
                  ) : (
                    <p data-status={item?.stage}>{item?.stage}</p>
                  )}
                </th>
                <th className={styles.game_contract_date}>
                  {item?.contract
                    ? moment(item?.contract).format("DD MMM YYYY")
                    : ""}
                </th>
                <th className={styles.game_scaleup_date}>
                  {item?.scaleUp
                    ? moment(item?.scaleUp).format("DD MMM YYYY")
                    : ""}
                </th>
                <th className={styles.game_edit}>
                  <button
                    className={styles.game_edit_btn}
                    onClick={() => {
                      setIsUpdate(true);
                      setSelectItem(item.id);
                    }}
                  >
                    <EditIcon />
                  </button>
                </th>
                <th className={styles.game_terminate}>
                  <button
                    className={styles.game_terminate_btn}
                    onClick={() => {
                      setIsTerminate(true);
                      setSelectItem(item.id);
                    }}
                  >
                    <TerminateIcon />
                  </button>
                </th>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}
