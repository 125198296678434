import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { recoilPersist } from "recoil-persist";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "../pages/Login/Login";
import * as cookie from "../db/cookie";
import { atom, useRecoilState, RecoilEnv } from "recoil";
const AuthContext = createContext({} as AuthContextProps);
const contextRef = createRef();
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
export function AuthProvider({
  adminClientAuth,
  children,
}: {
  adminClientAuth: AuthServiceList;
  children: React.ReactNode;
}) {
  const { persistAtom } = recoilPersist({
    key: "invoiceUserInfo",
    storage: localStorage,
  });
  const userState = atom({
    key: "invoiceUserInfo",
    default: null,
    effects_UNSTABLE: [persistAtom],
  });
  const [user, setUser] = useState<UserInfo>({} as UserInfo);
  const [invoiceUser, setInvoiceUser] = useRecoilState(userState);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  // useImperativeHandle(contextRef, () => {
  //   console.log(user?.token);
  //   return user ? user.token : undefined;
  // });
  // useEffect(() => {
  //   authErrorEventBus.listen((err) => {
  //     setUser(undefined);
  //   });
  // }, [authErrorEventBus]);

  const logIn = useCallback(
    async (email: string, password: string) => {
      return adminClientAuth
        .login(email, password)
        .then((res: UserInfo) => {
          if (res.status === 401) {
            alert("비밀번호를 확인해주세요");
          } else {
            setUser(res);
          }
        })
        .then(() => (window.location.pathname = "/"));
    },
    [adminClientAuth, setUser]
  );

  useEffect(() => {
    adminClientAuth.me().then(setUser).catch(console.error);
  }, [adminClientAuth]);

  const invoiceLogin = useCallback(
    async (email: string, password: string, path: string) => {
      return await adminClientAuth
        .newLogin(email, password)
        .then((res: InvoiceUserInfo) => {
          if (res?.status === 201) {
            cookie.setCookie("Authentication", res.data.accessToken);
            setInvoiceUser(res.data);
            window.location.pathname = `${path}`;
            return;
          } else {
            alert(res.data.message);
            return;
          }
        });
    },
    [adminClientAuth, setInvoiceUser]
  );

  const isPublishing = useCallback(
    async (userId: number, appId: string) => {
      return adminClientAuth.isPublishing(userId, appId);
    },
    [adminClientAuth]
  );

  const logout = useCallback(
    async () =>
      adminClientAuth.logout().then(() => {
        window.location.href = "/login";
      }),

    [adminClientAuth]
  );
  //,
  const modifingInfo = useCallback(
    async (id: number, password: string, profileImg: string) =>
      adminClientAuth.modifingUserInfo(id, password, profileImg),
    [adminClientAuth]
  );

  useEffect(() => {
    if (!cookie.getCookie("token")) {
      navigate("/login", { state: pathname });
    } else if (pathname === "/login" && cookie.getCookie("token")) {
      navigate("/", { state: pathname });
    }
  }, [navigate, pathname]);

  const context = useMemo(
    () => ({
      user,
      invoiceUser,
      invoiceLogin,
      logIn,
      logout,
      modifingInfo,
      isPublishing,
    }),
    [user, invoiceUser, invoiceLogin, logIn, logout, modifingInfo, isPublishing]
  );
  return (
    <AuthContext.Provider value={context}>
      {cookie.getCookie("token") ? (
        children
      ) : (
        <Routes>
          <Route path="/login" element={<Login onLogin={logIn} />} />
        </Routes>
      )}
    </AuthContext.Provider>
  );
}

// export class AuthErrorEventBus {
//   listen(callback) {
//     this.callback = callback;
//   }
//   notify(error) {
//     this.callback(error);
//   }
// }

export default AuthContext;
export const fetchToken = () => contextRef.current;
export const useAuth = () => useContext(AuthContext);
