import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import AdData from "../../components/AdData/AdData";
import StudioProfile from "../../components/StudioProfile/StudioProfile";
import styles from "./AdsDetail.module.scss";
import MaxData from "../../components/MaxData/MaxData";
import PlayTime from "../../components/PlayTime/PlayTime";
import IPM from "../../components/IPM/IPM";
import CPI from "../../components/CPI/CPI";
import RetentionData from "../../components/RetentionData/RetentionData";
import PlayTimeData from "../../components/PlayTimeData/PlayTimeData";
import googleIcon from "../../Img/adsData/googleplay.png";
import Modal from "../../common/Modal/Modal";
import { useMaxData } from "../../Context/MaxContext";
import { useAuth } from "../../Context/AuthContext";

const AdsDetail = ({
  setIPM,
  adsService,
  adminClientAuth,
  adsNetworkService,
}) => {
  const { getMaxData } = useMaxData();
  const { isPublishing } = useAuth();
  const location = useLocation();
  const studioData = location?.state?.data;
  const [adData, setadData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [adsetData, setAdsetData] = useState([]);
  const [status, setStatus] = useState("");
  const [ITR, setITR] = useState([]);
  const [ITR_Tap, setITR_Tap] = useState("");
  const [filterMaxData, setFilterMaxData] = useState([]);
  const [averageCpi, setAverageCpi] = useState(0);
  const [d1Retention, setD1Retention] = useState(0);
  const [d0PlayTime, setD0PlayTime] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [gameUrl, setGameUrl] = useState("");

  const gameName = location?.state?.data?.gameName;
  const gameIcon = location?.state?.data?.gameIcon;
  const appId = location?.state?.data?.appId;
  const email = location?.state?.data?.email;
  const userId = location?.state?.data?.userId;
  const onChange = (e) => {
    setStoreId(e.target.value);
  };

  const onClick = async () => {
    if (storeId.length === 0) {
      alert("Please input Apple StoreID");
    } else {
      await adminClientAuth.isPublishing(location?.state?.data?.userId, appId);
      await adsNetworkService.insertAds(
        { appId, email, gameName, gameIcon, userId },
        storeId
      );
      await adsNetworkService.insertPublishingGame(
        gameName,
        appId,
        `id${storeId}`
      );
      window.location.reload();
    }
  };

  useEffect(() => {
    let today = moment().format("YYYY-MM-DD");
    if (location.pathname.includes("complete")) {
      adsService.getList().then(async (data) => {
        setUserData(
          data?.data
            .filter((item) => moment(today).isAfter(item.endTime))
            .find((item) => item.appId === appId)
        );
        let checkPublishing = await data?.data
          .filter((item) => moment(today).isAfter(item.endTime))
          .find((item) => item.appId === appId);
        if (!checkPublishing?.publishingGame) {
          return setPublishing(false);
        } else {
          return setPublishing(true);
        }
      });
    }
    if (location.pathname.includes("progress")) {
      adsService.getList().then(async (data) => {
        setUserData(
          data.data
            .filter((item) => moment(today).isSameOrBefore(item.endTime))
            .find((item) => item.appId === appId)
        );
        let checkPublishing = await data.data
          .filter((item) => moment(today).isSameOrBefore(item.endTime))
          .find((item) => item.appId === appId);
        if (!checkPublishing.publishingGame) {
          return setPublishing(false);
        } else {
          return setPublishing(true);
        }
      });
    }
  }, [adsService, appId]);
  useEffect(() => {
    if (ITR.length === 1) {
      setITR_Tap("ITR#1");
    } else if (ITR.length > 1) {
      setITR_Tap(ITR[ITR.length - 1]);
    }
  }, [ITR]);
  useEffect(() => {
    const getCampaign = () =>
      new Promise((resolve, reject) => {
        resolve(
          adsService.getAds(appId, `${gameName}_${ITR_Tap}`).then((data) => {
            if (!data) {
              return;
            } else {
              setadData(() => data?.adsData?.data);
              setAdsetData(() => data?.adsetData);
            }
          })
        );
      });
    if (ITR_Tap) {
      getCampaign();
    }
  }, [ITR_Tap, adsService, appId, gameName]);

  useEffect(() => {
    adData?.map((data) => {
      switch (data?.effective_status) {
        case "ACTIVE":
          return setStatus("Active");
        case "CAMPAIGN_PAUSED":
          return setStatus("Complete");
        default:
          return setStatus("Inactive");
      }
    });
  }, [adData]);

  useEffect(() => {
    if (ITR_Tap) {
      return getMaxData(appId)?.then((data) =>
        setFilterMaxData(() =>
          data?.filter((i) => i.gameName.includes(ITR_Tap))
        )
      );
    }
  }, [ITR_Tap, location?.state?.data?.appId]);

  useEffect(() => {
    let type = document.getElementsByClassName(styles.type);
    if (type[0].innerText === "MondayOFF Party") {
      type[0].style.color = "#E63D43";
      type[0].style.background = "#FFEAED";
    }
  }, []);

  useEffect(() => {
    const data = async () => {
      await adsService.getGoogleUrl(appId).then((res) => {
        return setGameUrl(res.object_store_urls.google_play);
      });
    };
    data();
  }, []);

  return (
    <div className={styles.container}>
      {showModal === true && (
        <Modal
          setShowModal={setShowModal}
          adsService={adsService}
          appId={appId}
          gameName={gameName}
          onClick={onClick}
          onChange={onChange}
        />
      )}
      <StudioProfile studioData={studioData} />

      <div className={styles.firstSection}>
        <div className={styles.gameDataContainer}>
          <div className={styles.gameInfoContainer}>
            <img
              src={location?.state?.data?.gameIcon}
              alt=""
              className={styles.gameIcon}
            />
            <div className={styles.gameName}>{gameName.split("_")[0]}</div>
            {status === "" && <div className={styles.emptyBox}></div>}
            {status === "Active" && (
              <div className={styles.activeContainer}>
                <span className={styles.activeText}>{status}</span>
              </div>
            )}
            {status === "Inactive" && (
              <div className={styles.inActiveContainer}>
                <span className={styles.inActiveText}>{status}</span>
              </div>
            )}
            {status === "Complete" && (
              <div className={styles.completeContainer}>
                <span className={styles.completeText}>{status}</span>
              </div>
            )}
            <div
              className={styles.type}
              data-content={location?.state?.data?.type}
            >
              {location?.state?.data?.type}
            </div>
            <div className={styles.genre}>{location?.state?.data?.genre}</div>

            <div className={styles.linkBox}>
              <a
                className={styles.gameLink}
                href={gameUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={googleIcon}
                  alt="storeIcon"
                  className={styles.storeIcon}
                />
              </a>
              {userData?.length !== 0 ? (
                !publishing ? (
                  <button
                    onClick={() => setShowModal(true)}
                    className={styles.clickPublishing}
                  >
                    Publishing
                  </button>
                ) : (
                  <div className={styles.isPublishing}>Publishing</div>
                )
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.summaryTitle}>Summary</div>
            <div className={styles.summaryBox}>
              <IPM adsetData={adsetData} setIPM={setIPM} />
              <CPI averageCpi={averageCpi} adsetData={adsetData} />
              <RetentionData d1Retention={d1Retention} />
              <PlayTimeData d0PlayTime={d0PlayTime} />
            </div>
          </div>
          <AdData
            adData={adData}
            adsetData={adsetData}
            adsService={adsService}
            setITR_Tap={setITR_Tap}
            ITR_Tap={ITR_Tap}
            ITR={ITR}
            setITR={setITR}
            gameName={gameName}
            setAverageCpi={setAverageCpi}
            appId={appId}
          />
        </div>
      </div>

      <MaxData
        retData={filterMaxData[0]?.retention}
        setD1Retention={setD1Retention}
      />
      <PlayTime
        setD0PlayTime={setD0PlayTime}
        playTimeData={filterMaxData[0]?.playTime}
      />
    </div>
  );
};

export default AdsDetail;
