import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const InvoiceContext = createContext({} as InvoiceContextProp);
const contextRef = createRef();
export function InvoiceProvider({
  invoiceService,
  children,
}: {
  invoiceService: InvoiceServiceList;
  children: React.ReactNode;
}) {
  const [invoiceList, setInvoiceList] = useState([]);

  const getStudioInfo = useCallback(
    async (studioId: number) => {
      return await invoiceService
        .getStudioInfo(studioId)
        .then((res: StudioInfo) => {
          return res;
        });
    },
    [invoiceService]
  );
  const editStudioInfo = useCallback(
    async (data: EditStuioInfo) => {
      return await invoiceService.editStudioInfo(data).then((res: any) => res);
    },
    [invoiceService]
  );
  const getList = useCallback(
    async (date: string) => {
      return await invoiceService.getList(date).then((res: InvoiceList) => {
        return res;
      });
    },
    [invoiceService]
  );
  const getOverviewData = useCallback(
    async (startDate: string, endDate: string) => {
      return await invoiceService
        .getOverviewData(startDate, endDate)
        .then((res: OverviewList) => {
          return res;
        });
    },
    [invoiceService]
  );
  const downloadOverviewData = useCallback(
    async (startDate: string, endDate: string, gameIds: any) => {
      return await invoiceService
        .downloadOverviewData(startDate, endDate, gameIds)
        .then((res: any) => res);
    },
    [invoiceService]
  );
  const getBonus = useCallback(
    async (gameId: number, month: string) =>
      await invoiceService
        .getBonus(gameId, month)
        .then((res: BonusList) => {
          return res;
        })
        .catch((e: any) => e),
    [invoiceService]
  );
  const countBonus = useCallback(
    async (gameId: number, month: string) => {
      return await invoiceService
        .getBonus(gameId, month)
        .then(
          (res: BonusList) =>
            res?.data?.balances?.length && res.data.balances.length
        )
        .catch((e: any) => {
          return e.toJSON();
        });
    },
    [invoiceService]
  );
  const getThirdPartyPrize = useCallback(
    async (gameId: number, month: string) => {
      return await invoiceService
        .getThirdPartyPrize(gameId, month)
        .then((res: any) => {
          return res;
        });
    },
    [invoiceService]
  );
  const addThirdPartyPrize = useCallback(
    (data: any) => {
      return invoiceService.addThirdPartyPrize(data).then((res: any) => res);
    },
    [invoiceService]
  );
  const editThirdPartyPrize = useCallback(
    async (data: any) => {
      return invoiceService.editThirdPartyPrize(data).then((res: any) => res);
    },
    [invoiceService]
  );
  const deleteThirdPartyPrize = useCallback(
    async (data: any) => {
      return invoiceService.deleteThirdPartyPrize(data).then((res: any) => res);
    },
    [invoiceService]
  );
  const addBonus = useCallback(
    (data: any) => {
      return invoiceService.addBonus(data).then((data: any) => data);
    },
    [invoiceService]
  );

  const modifiedBonus = useCallback(
    (data: any) => invoiceService.modifiedBonus(data).then((res: any) => res),
    [invoiceService]
  );

  const deleteBonus = useCallback(
    (data: any) =>
      invoiceService
        .deleteBonus(data)
        .then((res: any) => res)
        .catch((e: any) => e.toJSON()),
    [invoiceService]
  );

  const searchList = useCallback(
    async (type: string, name: string) => {
      return await invoiceService
        .searchList(type, name)
        .then((res: any) => res);
    },
    [invoiceService]
  );
  const testAddBonus = useCallback(
    async (data: any) => {
      return invoiceService.testAddBonus(data).then((res: any) => res);
    },
    [invoiceService]
  );

  const testModifiedBonus = useCallback(
    (data: any) =>
      invoiceService.testModifiedBonus(data).then((res: any) => res),
    [invoiceService]
  );

  const testDeleteBonus = useCallback(
    (data: any) => invoiceService.testDeleteBonus(data).then((res: any) => res),
    [invoiceService]
  );

  const confirmInvoice = useCallback(
    async (userId: number, date: string) => {
      return invoiceService
        .confirmInvoice(userId, date)
        .then((res: any) => res);
    },
    [invoiceService]
  );

  const addRateInfo = useCallback(
    async (data: any) => {
      return await invoiceService.addRateInfo(data).then((res: any) => res);
    },
    [invoiceService]
  );
  const editRateInfo = useCallback(
    async (data: any) => {
      return await invoiceService.editRateInfo(data).then((res: any) => res);
    },
    [invoiceService]
  );
  const totalConfirm = useCallback(
    (date: string) => invoiceService.totalConfirm(date).then((res: any) => res),
    [invoiceService]
  );
  const getRateInfo = useCallback(
    async (date: string) =>
      await invoiceService.getRateInfo(date).then((res: RateInfo) => res),
    [invoiceService]
  );
  const addRemittance = useCallback(
    async (data: any) => {
      return await invoiceService.addRemittance(data).then((res: any) => res);
    },
    [invoiceService]
  );

  const downloadInvoice = useCallback(
    async (studioId: number, date: string) => {
      return await invoiceService
        .downloadInvoice(studioId, date)
        .then((res: any) => res);
    },
    [invoiceService]
  );
  const downloadDetail = useCallback(
    async (studioId: number, gameId: number, date: string) => {
      return await invoiceService
        .downloadDetail(studioId, gameId, date)
        .then((res: any) => res);
    },
    [invoiceService]
  );

  const downloadRemittance = useCallback(
    async (studioId: number, date: string) => {
      return await invoiceService
        .downloadRemittance(studioId, date)
        .then((res: any) => res);
    },
    [invoiceService]
  );
  const context = useMemo(
    () => ({
      getList,
      addBonus,
      getBonus,
      getThirdPartyPrize,
      modifiedBonus,
      deleteBonus,
      countBonus,
      searchList,
      testAddBonus,
      testModifiedBonus,
      testDeleteBonus,
      confirmInvoice,
      getStudioInfo,
      editStudioInfo,
      addThirdPartyPrize,
      setInvoiceList,
      invoiceList,
      editThirdPartyPrize,
      deleteThirdPartyPrize,
      addRateInfo,
      editRateInfo,
      totalConfirm,
      getRateInfo,
      addRemittance,
      downloadInvoice,
      downloadDetail,
      downloadRemittance,
      getOverviewData,
      downloadOverviewData,
    }),
    [
      addBonus,
      getBonus,
      getList,
      getThirdPartyPrize,
      modifiedBonus,
      deleteBonus,
      countBonus,
      searchList,
      testAddBonus,
      testModifiedBonus,
      testDeleteBonus,
      confirmInvoice,
      getStudioInfo,
      editStudioInfo,
      addThirdPartyPrize,
      invoiceList,
      editThirdPartyPrize,
      deleteThirdPartyPrize,
      addRateInfo,
      editRateInfo,
      totalConfirm,
      getRateInfo,
      addRemittance,
      downloadInvoice,
      downloadDetail,
      downloadRemittance,
      getOverviewData,
      downloadOverviewData,
    ]
  );
  return (
    <InvoiceContext.Provider value={context}>
      {children}
    </InvoiceContext.Provider>
  );
}

export default InvoiceContext;
export const fetchToken = () => contextRef.current;
export const useInvoice = () => useContext(InvoiceContext);
