import React, { useEffect, useState } from "react";
import styles from "./PlayTime.module.scss";
import icon from "../../Img/alertModal/icon.png";
import playTimeTotalData from "../../service/playTimeData";
const PlayTime = ({ setD0PlayTime, playTimeData }) => {
  const [totalInstalls, setTotalInstalls] = useState([]);
  const [totalPlayD0, setTotalPlayD0] = useState("");
  const [totalPlayD1, setTotalPlayD1] = useState("");
  const [totalPlayD2, setTotalPlayD2] = useState("");
  const [totalPlayD3, setTotalPlayD3] = useState("");
  const [totalPlayD4, setTotalPlayD4] = useState("");
  const [totalPlayD5, setTotalPlayD5] = useState("");
  const [totalPlayD6, setTotalPlayD6] = useState("");

  useEffect(() => {
    let install = [];
    playTimeData?.map((data) => {
      return install?.push(Number(data?.installs));
    });
    if (install?.length > 0) {
      setTotalInstalls(() => install?.reduce((a, b) => a + b));
    } else if (install?.length === 0) {
      setTotalInstalls("-");
    }
  }, [playTimeData]);
  //D0
  useEffect(() => {
    let playTime = [];
    playTimeData?.map((data) => {
      return playTime?.push(
        Number(data?.daily_usage_0) * Number(data?.installs)
      );
    });

    if (playTime.length === 0) {
      setTotalPlayD0("-");
      setD0PlayTime(0);
    } else {
      setTotalPlayD0(playTimeTotalData(playTimeData, playTime, 0));
      setD0PlayTime(totalPlayD0);
    }
  }, [playTimeData, setD0PlayTime, totalInstalls, totalPlayD0]);
  //D1
  useEffect(() => {
    let playTime = [];
    playTimeData?.map((data) => {
      return playTime?.push(
        Number(data?.daily_usage_1) * Number(data?.installs)
      );
    });

    if (playTime.length === 0) {
      setTotalPlayD1("-");
    } else {
      setTotalPlayD1(playTimeTotalData(playTimeData, playTime, 1));
    }
  }, [playTimeData, totalInstalls, totalPlayD1]);

  //D2
  useEffect(() => {
    let playTime = [];
    playTimeData?.map((data) => {
      return playTime?.push(
        Number(data?.daily_usage_2) * Number(data?.installs)
      );
    });

    if (playTime.length === 0) {
      setTotalPlayD2("-");
    } else {
      setTotalPlayD2(playTimeTotalData(playTimeData, playTime, 2));
    }
  }, [playTimeData, totalInstalls]);
  //D3
  useEffect(() => {
    let playTime = [];
    playTimeData?.map((data) => {
      return playTime?.push(
        Number(data?.daily_usage_3) * Number(data?.installs)
      );
    });

    if (playTime.length === 0) {
      setTotalPlayD3("-");
    } else {
      setTotalPlayD3(playTimeTotalData(playTimeData, playTime, 3));
    }
  }, [playTimeData, totalInstalls]);
  //D4
  useEffect(() => {
    let playTime = [];
    playTimeData?.map((data) => {
      return playTime?.push(
        Number(data?.daily_usage_4) * Number(data?.installs)
      );
    });

    if (playTime.length === 0) {
      setTotalPlayD4("-");
    } else {
      setTotalPlayD4(playTimeTotalData(playTimeData, playTime, 4));
    }
  }, [playTimeData, totalInstalls]);
  //D5
  useEffect(() => {
    let playTime = [];
    playTimeData?.map((data) => {
      return playTime?.push(
        Number(data?.daily_usage_5) * Number(data?.installs)
      );
    });

    if (playTime.length === 0) {
      setTotalPlayD5("-");
    } else {
      setTotalPlayD5(playTimeTotalData(playTimeData, playTime, 5));
    }
  }, [playTimeData, totalInstalls]);
  //D6
  useEffect(() => {
    let playTime = [];
    playTimeData?.map((data) => {
      return playTime?.push(
        Number(data?.daily_usage_6) * Number(data?.installs)
      );
    });

    if (playTime.length === 0) {
      setTotalPlayD6("-");
    } else {
      setTotalPlayD6(playTimeTotalData(playTimeData, playTime, 6));
    }
  }, [playTimeData, totalInstalls]);
  return (
    <div className={styles.container}>
      <div className={styles.title}>Play Time</div>
      <div className={styles.dataHeader}>
        <div></div>
        <div>Installs</div>
        <div>Day 0</div>
        <div>Day 1</div>
        <div>Day 2</div>
        <div>Day 3</div>
        <div>Day 4</div>
        <div>Day 5</div>
        <div>Day 6</div>
      </div>
      <div className={styles.maxDataTable}>
        {playTimeData?.length === 0 ? (
          <div className={styles.playTimeMessage}>
            <img src={icon} alt="" />
            <div>
              It may take a while to receive gaming data from the server. Please
              wait.
            </div>
          </div>
        ) : (
          <>
            {playTimeData?.map((d, idx) => {
              return (
                <div className={styles.dataBox} key={idx}>
                  <div>{d?.day}</div>
                  <div>{Math.floor(d?.installs)}</div>

                  <div className={styles.data}>
                    {Number(d?.daily_usage_0).toFixed(0)}s
                  </div>
                  <div className={styles.data}>
                    {Number(d?.daily_usage_1).toFixed(0)}s
                  </div>
                  <div className={styles.data}>
                    {Number(d?.daily_usage_2).toFixed(0)}s
                  </div>
                  <div className={styles.data}>
                    {Number(d?.daily_usage_3).toFixed(0)}s
                  </div>
                  <div className={styles.data}>
                    {Number(d?.daily_usage_4).toFixed(0)}s
                  </div>
                  <div className={styles.data}>
                    {Number(d?.daily_usage_5).toFixed(0)}s
                  </div>
                  <div className={styles.data}>
                    {Number(d?.daily_usage_6).toFixed(0)}s
                  </div>
                </div>
              );
            })}
            <div className={styles.totalData}>
              <div>Average</div>
              <div>{totalInstalls}</div>
              <div className={styles.data}>{totalPlayD0}</div>
              <div className={styles.data}>{totalPlayD1}</div>
              <div className={styles.data}>{totalPlayD2}</div>
              <div className={styles.data}>{totalPlayD3}</div>
              <div className={styles.data}>{totalPlayD4}</div>
              <div className={styles.data}>{totalPlayD5}</div>
              <div className={styles.data}>{totalPlayD6}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PlayTime;
