import React, { useState } from "react";
import styles from "./TerminateModal.module.scss";
import defaultImg from "../../Img/GameStatus/defaultImg.png";
import moment from "moment";
import { useGameStatus } from "../../Context/NewContext/GameStatusContext";
export default function TerminateModal({ setIsTerminate, game }) {
  const { terminateGame } = useGameStatus();
  const [bundleId, setBundleId] = useState("");
  const addDefaultImg = (e) => {
    e.target.src = defaultImg;
  };
  const onTerminateClick = () => {
    const terminateData = {
      bundleId,
      terminate: moment().format("YYYY-MM-DD"),
    };
    return terminateGame(terminateData).then((res) => {
      if (res.status === 201) {
        window.location.reload();
      }
    });
  };
  return (
    <div className={styles.container}>
      <section className={styles.contents}>
        <h2 className={styles.terminate_title}>Terminate Game</h2>
        <div className={styles.game_info}>
          <img
            className={styles.game_icon}
            src={game.gameIconUrl}
            alt=""
            onError={addDefaultImg}
          />
          <span className={styles.game_name_studio_name}>
            <p>{game.name}</p>
            <p>{game.studio.name}</p>
          </span>
        </div>
        <p className={styles.alert_text}>
          Are you sure to terminate this game?
        </p>
        <p className={styles.alert_text_second}>
          <span className={styles.game_name}>{game.name}</span> data will be
          deleted from Dashboard. You cannot undo this action.
        </p>
        <div className={styles.bundle_id_input}>
          <p className={styles.bundle_id_title}>
            Bundle ID<span>*</span>
          </p>
          <input type="text" onChange={(e) => setBundleId(e.target.value)} />
        </div>
        <div className={styles.buttons}>
          <button onClick={() => setIsTerminate(false)}>
            <p>Cancel</p>
          </button>
          <button onClick={onTerminateClick}>
            <p>Terminate</p>
          </button>
        </div>
      </section>
    </div>
  );
}
