import React, { useMemo } from "react";
import { ResponsiveLine } from "@nivo/line";

import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";

import styles from "./Chart.module.scss";
import { useChart } from "../../Context/ChartContext";
export default function Chart() {
  const { chartData } = useChart();
  const [filterData, setFilterData] = useState([]);
  const [accumulatorData, setAccumulatorData] = useState([]);
  const [labelList, setLabelList] = useState([]);
  const [hoverData, setHoverData] = useState({});
  const [stackCount, setStackCount] = useState(0);
  const [showDropDown, setShowDropDown] = useState(false);
  const [isToggleClick, setIsToggleClick] = useState(false);
  let dailyTickData = [0, 10, 20, 30, 40];
  let dailyMax = 40;
  let accTickData = [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];
  let accMax = 500;
  const [hiddenIds, setHiddenIds] = useState([]);
  let colorList = ["#FFAE35", "#2493FF", "#61E8A7", "#A766FC"];
  const data = useMemo(
    () => filterData.filter((item) => !hiddenIds.includes(item.id)),
    [hiddenIds, filterData]
  );
  const accData = useMemo(
    () => accumulatorData.filter((item) => !hiddenIds.includes(item.id)),
    [hiddenIds, accumulatorData]
  );
  //daily
  useEffect(() => {
    let dataList = [];
    let label = [];
    let idx = 0;
    const groupByName = chartData.reduce((acc, obj, idx) => {
      const { competition, x, y, date } = obj;
      let dataLength = chartData.filter(
        (item) => item.competition === competition
      );

      acc[competition] = acc[competition] ?? [];
      acc[competition].push({
        x: Number(x / dataLength[dataLength.length - 1].x),
        y,
        date,
      });

      return acc;
    }, {});

    for (const item in groupByName) {
      dataList.push({
        id: item,
        color: colorList[idx],
        data: groupByName[item],
      });
      label.push({ id: item, label: item, color: colorList[idx] });
      idx++;
    }

    setLabelList(
      label.sort((a, b) => {
        if (a.id.split(" ")[0] > b.id.split(" ")[0]) {
          return 1;
        } else if (a.id.split(" ")[0] < b.id.split(" ")[0]) {
          return -1;
        } else {
          if (
            a.id.split(" ")[1].toUpperCase() > b.id.split(" ")[1].toUpperCase()
          ) {
            return 1;
          } else if (
            a.id.split(" ")[1].toUpperCase() < b.id.split(" ")[1].toUpperCase()
          ) {
            return -1;
          }
          return 0;
        }
      })
    );

    setFilterData(dataList);
  }, [chartData]);

  //accumulator
  useEffect(() => {
    let dataList = [];
    let label = [];
    let idx = 0;
    const groupByName = chartData.reduce((acc, obj, idx) => {
      const { competition, x, y, date } = obj;
      let sliceData = chartData.slice(0, idx);
      let totalCount = sliceData
        .filter((item) => item.competition === competition)
        .map((item) => item.y)
        .reduce((acc, cur) => acc + cur, y);
      let dataLength = chartData.filter(
        (item) => item.competition === competition
      );

      acc[competition] = acc[competition] ?? [];
      acc[competition].push({
        x: (x / dataLength[dataLength.length - 1].x).toFixed(6),
        y: totalCount,
        count: y,
        date,
      });

      return acc;
    }, {});

    for (const item in groupByName) {
      dataList.push({
        id: item,
        color: colorList[idx],
        data: groupByName[item],
      });
      label.push({ id: item, label: item, color: colorList[idx] });
      idx++;
    }

    setLabelList(
      label.sort((a, b) => {
        if (a.id.split(" ")[0] > b.id.split(" ")[0]) {
          return 1;
        } else if (a.id.split(" ")[0] < b.id.split(" ")[0]) {
          return -1;
        } else {
          if (
            a.id.split(" ")[1].toUpperCase() > b.id.split(" ")[1].toUpperCase()
          ) {
            return 1;
          } else if (
            a.id.split(" ")[1].toUpperCase() < b.id.split(" ")[1].toUpperCase()
          ) {
            return -1;
          }
          return 0;
        }
      })
    );

    setAccumulatorData(dataList);
  }, [chartData]);

  useEffect(() => {
    let filterCompetition = filterData
      .filter((item) => item.id === hoverData.serieId)
      .map((item) => item.data.filter((list) => list.x <= hoverData.data.x))
      .flat(Infinity);

    if (filterCompetition.length !== 0) {
      setStackCount(
        filterCompetition?.map((i) => i?.y).reduce((acc, cur) => acc + cur)
      );
    }
  }, [filterData, hoverData]);

  const isDropDownMenuClick = () => {
    setShowDropDown(!showDropDown);
  };
  const onToggleClick = (type) => {
    if (type === "Daily") {
      setIsToggleClick(false);
    } else {
      setIsToggleClick(true);
    }
  };
  return (
    <section>
      <div className={styles.chartContainer}>
        <h1>Number of submissions</h1>
        <span>
          <button
            data={!isToggleClick ? "dailyActive" : "dailyInactive"}
            onClick={() => onToggleClick("Daily")}
          >
            Daily
          </button>
          <button
            data={isToggleClick ? "totalActive" : "totalInactive"}
            onClick={() => onToggleClick("Total")}
          >
            Total
          </button>
        </span>
        {showDropDown && (
          <ul className={styles.dropBox}>
            <p>Filters</p>
            {labelList.map((item) => {
              return (
                <section>
                  <div>
                    <input type="checkbox" id={item} />
                    <label htmlFor={item}>{item}</label>
                  </div>
                </section>
              );
            })}
          </ul>
        )}
        <ResponsiveLine
          defs={[
            {
              colors: [
                {
                  color: "inherit",
                  offset: 0,
                },
                {
                  color: "inherit",
                  offset: 100,
                  opacity: 0,
                },
              ],
              id: "gradientA",
              type: "linearGradient",
            },
          ]}
          fill={[
            {
              id: "gradientA",
              match: "*",
            },
          ]}
          enableArea
          enableSlices={false}
          animate
          data={!isToggleClick ? data : accData}
          margin={{ top: 102, right: 40, bottom: 40, left: 50 }}
          xScale={{ type: "linear", min: 0, max: 1 }}
          yScale={{
            type: "linear",
            stacked: false,
            min: 0,
            max: !isToggleClick ? dailyMax : accMax,
          }}
          curve="monotoneX"
          axisTop={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: "0",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickValues: !isToggleClick ? dailyTickData : accTickData,
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: "0",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          enableGridY={true}
          lineWidth={1.5}
          pointSize={0}
          pointBorderWidth={2}
          pointBorderColor="white"
          useMesh={true}
          gridXValues={[0, 7, 14, 21, 28, 35, 40]}
          gridYValues={!isToggleClick ? dailyTickData : accTickData}
          colors={(e) => e.color}
          tooltip={(item) => {
            setHoverData(item.point);
            return (
              <div className={styles.tooltipContainer}>
                <p>{!isToggleClick ? item.point.data.y : stackCount}</p>
                <p>
                  <span>{!isToggleClick ? "Total" : "Submissions"}</span>
                  <span>
                    {!isToggleClick ? stackCount : item.point.data.count}
                  </span>
                </p>
                <p>{moment(item.point.data.date).format("DD MMMM YYYY")}</p>
              </div>
            );
          }}
          legends={[
            {
              data: labelList.map((item) => {
                return {
                  color: hiddenIds.includes(item.id)
                    ? "rgba(1, 1, 1, .1)"
                    : item.color,
                  id: item.id,
                  label: item.id,
                };
              }),
              anchor: "top-right",
              direction: "row",
              justify: false,
              //   translateX: 330,
              translateY: -65,
              itemsSpacing: 2,
              itemDirection: "left-to-right",
              itemWidth: 85,
              itemHeight: 12,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              onClick: (datum) => {
                setHiddenIds((prev) =>
                  prev.includes(String(datum.id))
                    ? prev.filter((item) => item !== datum.id)
                    : [...prev, String(datum.id)]
                );
              },
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        ></ResponsiveLine>
      </div>
    </section>
  );
}

React.memo(Chart);
