import React, { useEffect, useState } from "react";
import styles from "./AddGameInvoiceItem.module.scss";
import AddInvoiceItem from "../AddInvoiceItem/AddInvoiceItem";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { deleteCookie } from "../../db/cookie";
import { ReactComponent as PlusIcon } from "../../Img/Invoice/plus.svg";
import InvoiceAddEditLoading from "../../common/InvoiceAddEditLoading/InvoiceAddEditLoading";
import { useKeyEscClose } from "../../common/escClose/escClose";
import CheckIcon from "../../common/CheckIcon/CheckIcon";
import Cookies from "js-cookie";
export default function AddGameInvoiceItem({
  item,
  setIsAddInvoiceItemClick,
  date,
}) {
  const { addBonus, getBonus } = useInvoice();
  const [bonusSumPrize, setBonusSumPrize] = useState(0);
  const [isAddClick, setIsAddClick] = useState(false);
  const [isGameIcon, setIsGameIcon] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState([
    { id: 1, type: 1, item: "", prize: 0 },
  ]);

  const [addGameBonus, setAddGameBonus] = useState([]);

  useEffect(() => {
    return getBonus(item?.gameId, date).then((res) => {
      if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      }
      if (item.isBonus) {
        setInvoiceItem(() =>
          res.data.balances.map((item) => ({
            id: item.id,
            type: item.type === "DEDUCT_BONUS" ? 1 : 2,
            item: item.description,
            prize: item.profit,
          }))
        );
      }
    });
  }, [getBonus, item, date]);

  useEffect(() => {
    setBonusSumPrize(() =>
      invoiceItem.reduce((acc, cur) => acc + cur.prize, 0)
    );
  }, [invoiceItem]);

  const onAddClick = () => {
    setInvoiceItem([
      ...invoiceItem,
      { id: invoiceItem.length + 1, type: 1, item: "", prize: 0 },
    ]);
  };
  useEffect(() => {
    setAddGameBonus(() =>
      invoiceItem.map((data) => ({
        month: date,
        description: data.item,
        type: data.type === 1 ? "DEDUCT_BONUS" : "NON_DEDUCT_BONUS",
        profit: data.prize,
        gameStage: item.stage,
        gameId: item.gameId,
        userId: item.userId,
      }))
    );
  }, [invoiceItem, item.gameId, item.stage, item.userId, date]);
  const onTotalAddClick = async () => {
    setIsAddClick(true);
    return addBonus({ bonus: addGameBonus, month: date }).then((res) => {
      if (res.status === 201) {
        window.location.reload();
      } else if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        setIsAddClick(false);
        alert(res.data.message);
      }
    });
  };
  useKeyEscClose(() => {
    setIsAddInvoiceItemClick(false);
  });
  useEffect(() => {
    CheckIcon(item?.gameIconUrl).then((res) => setIsGameIcon(res));
  }, [item?.gameIconUrl]);
  return (
    <section className={styles.container}>
      <section className={styles.contents}>
        <h3>Add Invoice Item</h3>
        <section className={styles.game}>
          {isGameIcon ? (
            <img
              className={styles.game_img}
              src={item?.gameIconUrl}
              alt="icon"
            />
          ) : (
            <div className={styles.game_img} />
          )}
          <p>{item?.gameName}</p>
        </section>
        <section className={styles.invoice_item}>
          <p>Invoice Item</p>
          <ul className={styles.invoice_item_list}>
            {invoiceItem?.map((list) => {
              return (
                <AddInvoiceItem
                  list={list}
                  item={item}
                  key={list.id}
                  invoiceItem={invoiceItem}
                  setInvoiceItem={setInvoiceItem}
                />
              );
            })}
          </ul>
          <button
            className={styles.invoice_item_add_button}
            onClick={onAddClick}
          >
            <PlusIcon className={styles.plus_icon} />
            <p>Add Item</p>
          </button>
          <section className={styles.total_bonus_prize}>
            <div>
              <p>Total Amount</p>
              <p>
                {bonusSumPrize.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </div>
          </section>
        </section>
        <section className={styles.buttons}>
          <button
            onClick={() =>
              isAddClick ? undefined : setIsAddInvoiceItemClick(false)
            }
          >
            <p>Cancel</p>
          </button>
          <button onClick={isAddClick ? undefined : onTotalAddClick}>
            {isAddClick ? <InvoiceAddEditLoading /> : <p>Add</p>}
          </button>
        </section>
      </section>
    </section>
  );
}
