import React, { useEffect, useState } from "react";

import AddDetailOther from "../AddDetailOther/AddDetailOther";
import styles from "./AddDetails.module.scss";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { deleteCookie } from "../../db/cookie";
import { useKeyEscClose } from "../../common/escClose/escClose";
import { ReactComponent as PlusIcon } from "../../Img/Invoice/plus.svg";
import InvoiceAddEditLoading from "../../common/InvoiceAddEditLoading/InvoiceAddEditLoading";
import CheckIcon from "../../common/CheckIcon/CheckIcon";
import Cookies from "js-cookie";
export default function AddDetails({ item, setIsAddDetailsClick, date }) {
  const { addThirdPartyPrize } = useInvoice();
  const [isAddClick, setIsAddClick] = useState(false);
  const [thirdPartyList, setThirdPartyList] = useState([]);

  const [detailsItem, setDetailsItem] = useState([
    { id: 1, description: "", prize: 0 },
  ]);

  const onAddClick = () => {
    setDetailsItem([
      ...detailsItem,
      { id: detailsItem.length + 1, type: 1, item: "", prize: 0 },
    ]);
  };

  useEffect(() => {
    setThirdPartyList(() => {
      return detailsItem.map((data) => ({
        month: date,
        description: data.description,
        type: "DETAIL_OTHER",
        profit: Number(data.prize),
        userId: item?.userId,
        gameStage: item?.stage,
        gameId: item?.gameId,
      }));
    });
  }, [item, date, detailsItem]);
  const onAddThirdPartyPrize = () => {
    setIsAddClick(true);
    addThirdPartyPrize({ detailOthers: thirdPartyList, month: date }).then(
      (res) => {
        if (res.status === 201) {
          window.location.reload();
        } else if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else {
          setIsAddClick(false);
          alert(res.data.message);
        }
      }
    );
  };
  useKeyEscClose(() => {
    setIsAddDetailsClick(false);
  });
  return (
    <article className={styles.container}>
      <section className={styles.contents}>
        <h2>Add Details</h2>
        <section className={styles.game_info}>
          {CheckIcon(item.gameIconUrl) ? (
            <img
              className={styles.game_icon}
              src={item?.gameIconUrl}
              alt="icon"
            />
          ) : (
            <div className={styles.game_icon} />
          )}
          <p>{item.gameName}</p>
        </section>

        <section className={styles.insert_other}>
          <h3>Insert Other</h3>
          <ul className={styles.insert_other_list}>
            {detailsItem.map((list) => (
              <AddDetailOther
                detailsItem={list}
                item={item}
                setDetailsItem={setDetailsItem}
                list={detailsItem}
              />
            ))}
          </ul>
          <button
            className={styles.detail_item_add_button}
            onClick={onAddClick}
          >
            <PlusIcon className={styles.plus} />
            <p>Add Item</p>
          </button>
        </section>
        <section className={styles.buttons}>
          <button
            onClick={() =>
              isAddClick ? undefined : setIsAddDetailsClick(false)
            }
          >
            <p>Cancel</p>
          </button>
          <button onClick={isAddClick ? undefined : onAddThirdPartyPrize}>
            <p>{isAddClick ? <InvoiceAddEditLoading /> : "Add"}</p>
          </button>
        </section>
      </section>
    </article>
  );
}
