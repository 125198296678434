import { useState } from "react";
import styles from "./GameTotalData.module.css";
import { ReactComponent as Arrow } from "../../Img/publishingDetail/arrow.svg";
import classNames from "classnames";
import aosIcon from "../../Img/publishingDetail/aosIcon.png";
import appleIcon from "../../Img/publishingDetail/appleIcon.png";
import IosAdnetwork from "../../common/IosAdnetwork/IosAdnetwork";
import AosAdnetwork from "../../common/AosAdnetwork/AosAdnetwork";
import genNum from "../../data/generateNumber";
export default function GameTotalData({
  onClick,
  data,
  isSelect,
  adData,
  isClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  data: any;
  isSelect: (data: any) => any;
  adData: any;
  isClick: boolean;
}) {
  const [viewIosData, setViewIosData] = useState<boolean>(false);
  const [viewAosData, setViewAosData] = useState<boolean>(false);

  const onIosClick = () => {
    setViewIosData(!viewIosData);
    return () => setViewIosData(false);
  };
  const onAosClick = () => {
    setViewAosData(!viewAosData);
    return () => setViewAosData(false);
  };

  return (
    <>
      <tr onClick={() => onClick(data)}>
        <td className={styles.data}>
          <Arrow
            className={
              isSelect(data) && isClick ? styles.rotateArrow : styles.arrow
            }
          />
          <img src={data.gameIcon} alt="" className={styles.gameIcon} />
          <div>{data?.appName}</div>
        </td>
        <td className={styles.data}>
          {adData?.totalSpend?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }) ?? "$0.00"}
        </td>
        <td className={styles.data}>
          {adData?.totalRevenue?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }) ?? "$0.00"}
        </td>
        <td className={styles.data}>
          {adData?.totalProfit?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }) ?? "$0.00"}
        </td>
        <td className={styles.data}>{genNum(adData?.totalInstalls) ?? "0"}</td>
      </tr>
      {isSelect(data) && isClick && (
        <>
          <tr onClick={onIosClick}>
            <td className={classNames(styles.data, styles.platformSection)}>
              <Arrow
                className={!viewIosData ? styles.arrow : styles.rotateArrow}
              />
              <img src={appleIcon} alt="" className={styles.platform} />
              <div className={styles.platformName}>iOS</div>
            </td>
            <td className={styles.data}>
              {adData["IOS"]?.totalIosSpend?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "$0.00"}
            </td>
            <td className={styles.data}>
              {adData["IOS"]?.totalIosRevenue?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "$0.00"}
            </td>
            <td className={styles.data}>
              {adData["IOS"]?.totalIosProfit?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "$0.00"}
            </td>
            <td className={styles.data}>
              {genNum(adData["IOS"]?.totalIosInstalls) ?? "0"}
            </td>
          </tr>
          {viewIosData && <IosAdnetwork adNetworkData={adData["IOS"]} />}

          <tr onClick={onAosClick}>
            <td className={classNames(styles.data, styles.platformSection)}>
              <Arrow
                className={!viewAosData ? styles.arrow : styles.rotateArrow}
              />
              <img src={aosIcon} alt="" className={styles.platform} />
              <div className={styles.platformName}>Android</div>
            </td>
            <td className={styles.data}>
              {adData["ANDROID"]?.totalAndSpend?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "$0.00"}
            </td>
            <td className={styles.data}>
              {adData["ANDROID"]?.totalAndRevenue?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "$0.00"}
            </td>
            <td className={styles.data}>
              {adData["ANDROID"]?.totalAndProfit?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "$0.00"}
            </td>
            <td className={styles.data}>
              {genNum(adData["ANDROID"]?.totalAndInstalls) ?? "0"}
            </td>
          </tr>
          {viewAosData && <AosAdnetwork adNetworkData={adData["ANDROID"]} />}
        </>
      )}
    </>
  );
}
