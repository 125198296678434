import React, { useState, useEffect, useRef } from "react";
import AddApp from "../../components/AddApp/AddApp";
import styles from "./AppInfo.module.scss";
import iosIcon from "../../Img/appInfo/ios.png";
import androidIcon from "../../Img/appInfo/android.png";
import arrow from "../../Img/appInfo/arrow.png";

import EnterAppInfo from "../../components/EnterAppInfo/EnterAppInfo";
import AppInfoList from "../../components/AppInfoList/AppInfoList";
import { ReactComponent as PlusIcon } from "../../Img/appInfo/plus.svg";
import { ReactComponent as SearchIcon } from "../../Img/appInfo/search.svg";
import { useAppInfo } from "../../Context/AppInfoContext";
import AmazonModal from "../../components/AmazonModal/AmazonModal";
export default function AppInfo({ appInfoService, gameInfoService }) {
  const { selectList, dataList, setSelectList } = useAppInfo();
  const listRef = useRef();
  const inputRef = useRef();

  const [inputActive, setInputActive] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [isAddAppBtn, setIsAddAppBtn] = useState(false);
  const [isAddAppAmazonBtn, setIsAddAppAmazonBtn] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const onClick = () => {
    setIsClick((prev) => !prev);
  };
  const onAddAppClick = () => {
    setIsAddAppBtn((prev) => !prev);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (inputRef?.current?.contains(e.target)) {
        setInputActive(true);
      } else {
        setInputActive(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [inputActive]);
  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (!listRef?.current?.contains(e.target)) {
        setIsClick(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [isClick]);

  return (
    <>
      {isAddAppAmazonBtn && (
        <AmazonModal
          selectList={selectList}
          setIsAddAppAmazonBtn={setIsAddAppAmazonBtn}
        />
      )}
      <div className={styles.container}>
        {isAddAppBtn ? (
          <AddApp
            gameInfoService={gameInfoService}
            setIsAddAppBtn={setIsAddAppBtn}
          />
        ) : (
          <div>
            <h2>App Info</h2>
            <div className={styles.appInfoContainer}>
              <div className={styles.topBox}>
                <div className={styles.selectBox} onClick={onClick}>
                  <div className={styles.infoContainer}>
                    <img src={selectList?.game?.gameIconUrl} alt="" />
                    <div className={styles.appInfoBox}>
                      <p>{selectList?.game?.name}</p>
                      {selectList?.platform === "IOS" ? (
                        <div>
                          <img src={iosIcon} alt="ios" />
                          <p>App Store</p>
                        </div>
                      ) : selectList?.platform === "ANDROID" ? (
                        <div>
                          <img src={androidIcon} alt="android" />
                          <p>Android</p>
                        </div>
                      ) : (
                        <div>
                          <img src={androidIcon} alt="Amazon" />
                          <p>Amazon</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <img
                    src={arrow}
                    alt="arrow"
                    className={styles.arrow}
                    data={isClick ? "true" : "false"}
                  />
                </div>
                <button className={styles.addAppBtn} onClick={onAddAppClick}>
                  <PlusIcon className={styles.plus_icon} />
                  <p>Add App</p>
                </button>
                <button
                  className={styles.addAmazonBtn}
                  onClick={() => setIsAddAppAmazonBtn(true)}
                >
                  <PlusIcon className={styles.plus_icon} />
                  <p>Add Amazon</p>
                </button>
              </div>

              {selectList?.length !== 0 && (
                <EnterAppInfo
                  data={selectList}
                  gameInfoService={gameInfoService}
                  appInfoService={appInfoService}
                />
              )}

              {isClick && (
                <section className={styles.listContainer} ref={listRef}>
                  <div>
                    <div
                      ref={inputRef}
                      className={styles.search_area}
                      data-content={inputActive ? "active" : ""}
                    >
                      <SearchIcon className={styles.search_icon} />
                      <input
                        type="text"
                        onChange={onChange}
                        placeholder="Search"
                        value={searchValue}
                      />
                    </div>
                    <ul className={styles.dropdown_list}>
                      {dataList
                        ?.filter((list) =>
                          searchValue.length > 0
                            ? list?.game?.name
                                ?.toLowerCase()
                                .includes(searchValue.toLowerCase())
                            : list
                        )
                        .map((list, idx) => {
                          return (
                            <AppInfoList
                              data={list}
                              key={idx}
                              setSelectList={setSelectList}
                              setIsClick={setIsClick}
                            />
                          );
                        })}
                    </ul>
                  </div>
                </section>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
