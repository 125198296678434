import * as cookie from "../db/cookie.js";
import moment from "moment";
import Cookies from "js-cookie";

export default class AdminAuthService {
  http: any;
  constructor(http: string) {
    this.http = http;
  }
  async login(email: string, password: string) {
    const expires = moment().add("7", "day").toDate();
    let data = await this.http
      .axios("/admin/login", {
        method: "POST",
        data: JSON.stringify({ email, password }),
      })
      .then((res: UserInfo) => res)
      .catch((e: any) => e.message);
    cookie.setCookie("token", data.data.token, {
      expires,
      secure: true,
      sameSite: "none",
    });
    return data;
  }
  async newLogin(email: string, password: string) {
    let data = await this.http
      .axios("/test/admin/adminUser/logIn", {
        method: "POST",
        maxBodyLength: Infinity,
        data: {
          email,
          password,
          isAdmin: true,
        },
        withCredentials: true,
      })
      .then((res: InvoiceUserInfo) => res)
      .catch((e: any) => e.response);

    return data;
  }
  async findAllMember() {
    let data = await this.http
      .axios("/auth/memberlist", {
        method: "GET",
      })
      .then((res: any) => res)
      .catch(console.error);
    return data;
  }
  async modifingUserInfo(id: number, password: string, profileImg: string) {
    const token = cookie.getCookie("token");
    return await this.http
      .axios("/admin/userinfo", {
        method: "PUT",
        data: JSON.stringify({ id, password, profileImg }),
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res: ResponseData) => res)
      .catch(console.error);
  }
  async me() {
    const token = cookie.getCookie("token");
    if (token) {
      return await this.http
        .axios("/admin/me", {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res: UserInfo) => res);
    }
    return;
  }
  async logout() {
    Cookies.remove("token");
    Cookies.remove("Authentication");
    localStorage.removeItem("invoiceUserInfo");
    return;
  }
  async isPublishing(userId: number, appId: string) {
    return await this.http
      .axios("/auth/publishing", {
        method: "PUT",
        data: JSON.stringify({ userId: userId, appId: appId }),
      })
      .then((res: any) => res.data);
  }
}
