import React, { useEffect, useState } from "react";
import moment from "moment";
import styles from "./CompareSubmission.module.scss";
import arrow from "../../Img/chart/arrow.png";
import { useGameList } from "../../Context/GameListContext";
export default function CompareSubmission() {
  const { progressAdsList } = useGameList();
  const [todayCount, setToDayCount] = useState<number>(0);
  const [yesterDayCount, setyesterDayCount] = useState<number>(0);
  useEffect(() => {
    let today = progressAdsList.filter((list) => {
      let today = moment().format("YYYY-MM-DD");
      let createdAt = moment(list?.createdAt).format("YYYY-MM-DD");
      return moment(today).isSame(createdAt);
    });
    let yesterData = progressAdsList.filter((list) => {
      const yesterDay = moment().subtract(1, "day").format("YYYY-MM-DD");
      const createdAt = moment(list?.createdAt).format("YYYY-MM-DD");
      return moment(yesterDay).isSame(createdAt);
    });

    setToDayCount(today.length);
    setyesterDayCount(yesterData.length);
  }, [progressAdsList]);
  return (
    <section className={styles.container}>
      <h2>Today’s Submissions</h2>
      <span>
        <p>{todayCount}</p>
        {todayCount - yesterDayCount < 0 && (
          <p>
            <span data-content="minus">{`${todayCount - yesterDayCount}`}</span>
            <img src={arrow} alt="" data-content="minus" />
          </p>
        )}
        {todayCount - yesterDayCount > 0 && (
          <p>
            <span>+ {`${todayCount - yesterDayCount}`}</span>
            <img src={arrow} alt="" data-content="plus" />
          </p>
        )}
        {todayCount - yesterDayCount === 0 && <p>0 -</p>}
      </span>
      <p>Compared to Yesterday</p>
    </section>
  );
}
