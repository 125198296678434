import { useState } from "react";
import { useAppInfo } from "../../Context/AppInfoContext";
import styles from "./AppInfoData.module.scss";
export default function Adnetwork({
  onChange,
  adNetworkData,
  appInfo,
  platform,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  adNetworkData: {
    id: number;
    adColonyMZId: string;
    vungleMZId: string;
    inmobiMZId: string;
    ironSourceMZId: string;
    mintegralMZId: string;
    unityMZId: string;
    metaMZId: string;
    metaUAId: string;
    tapjoyMZId: string;
    fyberMZId: string;
    googleMZId: string;
    hyprmxMZId: string;
    bundleId: string;
    storeId: string;
    smaatoEntityId: string;
    oguryAssetKey: string;
  };
  appInfo: RequestAppInfoData;
  platform: string;
}) {
  const { updateGoogleMZId } = useAppInfo();
  const {
    id,
    adColonyMZId,
    vungleMZId,
    inmobiMZId,
    ironSourceMZId,
    mintegralMZId,
    unityMZId,
    metaMZId,
    metaUAId,
    tapjoyMZId,
    fyberMZId,
    googleMZId,
    hyprmxMZId,
    bundleId,
    storeId,
    smaatoEntityId,
    oguryAssetKey,
  } = adNetworkData;
  const [isLoading, setIsLoading] = useState(false);

  const onGetGoogleMZId = async () => {
    setIsLoading(true);
    await updateGoogleMZId(id)
      .then((res) => res)
      .catch((e) =>
        alert(`The appId "${id}" with Admob Id "${googleMZId}" already exist (${e})
        `)
      );
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      {platform === "IOS" && (
        <div>
          <h3>Store Id</h3>
          <input
            type="text"
            onChange={onChange}
            name="storeId"
            placeholder={
              appInfo.appInfos.map((item: AppInfos) => item.storeId)[0] ===
              undefined
                ? storeId
                : appInfo.appInfos.map((item: AppInfos) => item.storeId)[0]
            }
            value={
              appInfo.appInfos.map((item: AppInfos) => item.storeId)[0] ===
              undefined
                ? storeId ?? ""
                : appInfo.appInfos.map((item: AppInfos) => item.storeId)[0]
            }
          />
        </div>
      )}
      {platform === "ANDROID" && (
        <div>
          <h3>Store Id</h3>
          <input
            type="text"
            onChange={onChange}
            name="androidStoreId"
            placeholder={
              appInfo.appInfos.map((item: AppInfos) => item.bundleId)[0] ===
              undefined
                ? bundleId
                : appInfo.appInfos.map((item: AppInfos) => item.bundleId)[0]
            }
            value={
              appInfo.appInfos.map((item: AppInfos) => item.bundleId)[0] ===
              undefined
                ? bundleId ?? ""
                : appInfo.appInfos.map((item: AppInfos) => item.bundleId)[0]
            }
          />
        </div>
      )}
      <div>
        <h3>Adcolony Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="adcolony"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.adColonyMZId)[0] ===
            undefined
              ? adColonyMZId
              : appInfo.appInfos.map((item: AppInfos) => item.adColonyMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.adColonyMZId)[0] ===
            undefined
              ? adColonyMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.adColonyMZId)[0]
          }
        />
      </div>
      <div>
        <h3>Ogury Asset key</h3>
        <input
          type="text"
          onChange={onChange}
          name="oguryAssetKey"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.oguryAssetKey)[0] ===
            undefined
              ? oguryAssetKey
              : appInfo.appInfos.map((item: AppInfos) => item.oguryAssetKey)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.oguryAssetKey)[0] ===
            undefined
              ? oguryAssetKey ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.oguryAssetKey)[0]
          }
        />
      </div>
      <div>
        <h3>SmaatoEntity Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="smaatoEntity"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.smaatoEntityId)[0] ===
            undefined
              ? smaatoEntityId
              : appInfo.appInfos.map((item: AppInfos) => item.smaatoEntityId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.smaatoEntityId)[0] ===
            undefined
              ? smaatoEntityId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.smaatoEntityId)[0]
          }
        />
      </div>
      <div>
        <div>
          <h3>Admob Id</h3>
        </div>
        <div data-content="generate">
          <input
            type="text"
            onChange={onChange}
            name="googleMZ"
            placeholder={
              appInfo.appInfos.map((item: AppInfos) => item.googleMZId)[0] ===
              undefined
                ? googleMZId
                : appInfo.appInfos.map((item: AppInfos) => item.googleMZId)[0]
            }
            value={
              appInfo.appInfos.find((item: AppInfos) => item.googleMZId) ===
              undefined
                ? googleMZId ?? ""
                : appInfo.appInfos.map((item: AppInfos) => item.googleMZId)[0]
            }
          />
          <button onClick={onGetGoogleMZId}>
            {isLoading ? "Generating" : "Generate"}
          </button>
        </div>
      </div>
      <div>
        <h3>Inmobi Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="inmobi"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.inmobiMZId)[0] ===
            undefined
              ? inmobiMZId
              : appInfo.appInfos.map((item: AppInfos) => item.inmobiMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.inmobiMZId)[0] ===
            undefined
              ? inmobiMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.inmobiMZId)[0]
          }
        />
      </div>
      <div>
        <h3>IronSource MZ Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="ironSourceMZ"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.ironSourceMZId)[0] ===
            undefined
              ? ironSourceMZId
              : appInfo.appInfos.map((item: AppInfos) => item.ironSourceMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.ironSourceMZId)[0] ===
            undefined
              ? ironSourceMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.ironSourceMZId)[0]
          }
        />
      </div>
      <div>
        <h3>Mintegral Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="mintegral"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.mintegralMZId)[0] ===
            undefined
              ? mintegralMZId
              : appInfo.appInfos.map((item: AppInfos) => item.mintegralMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.mintegralMZId)[0] ===
            undefined
              ? mintegralMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.mintegralMZId)[0]
          }
        />
      </div>

      <div>
        <h3>Meta MZ Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="metaMZ"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.metaMZId)[0] ===
            undefined
              ? metaMZId
              : appInfo.appInfos.map((item: AppInfos) => item.metaMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.metaMZId)[0] ===
            undefined
              ? metaMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.metaMZId)[0]
          }
        />
      </div>
      <div>
        <h3>Meta UA Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="metaUA"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.metaUAId)[0] ===
            undefined
              ? metaUAId
              : appInfo.appInfos.map((item: AppInfos) => item.metaUAId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.metaUAId)[0] ===
            undefined
              ? metaUAId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.metaUAId)[0]
          }
        />
      </div>

      <div>
        <h3>Fyber Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="fyber"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.fyberMZId)[0] ===
            undefined
              ? fyberMZId
              : appInfo.appInfos.map((item: AppInfos) => item.fyberMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.fyberMZId)[0] ===
            undefined
              ? fyberMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.fyberMZId)[0]
          }
        />
      </div>

      <div>
        <h3>Hyprmx Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="hyprmax"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.hyprmxMZId)[0] ===
            undefined
              ? hyprmxMZId
              : appInfo.appInfos.map((item: AppInfos) => item.hyprmxMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.hyprmxMZId)[0] ===
            undefined
              ? hyprmxMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.hyprmxMZId)[0]
          }
        />
      </div>
      <div>
        <h3>Tapjoy Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="tapjoy"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.tapjoyMZId)[0] ===
            undefined
              ? tapjoyMZId
              : appInfo.appInfos.map((item: AppInfos) => item.tapjoyMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.tapjoyMZId)[0] ===
            undefined
              ? tapjoyMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.tapjoyMZId)[0]
          }
        />
      </div>
      <div>
        <h3>Unity Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="unityMZ"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.unityMZId)[0] ===
            undefined
              ? unityMZId
              : appInfo.appInfos.map((item: AppInfos) => item.unityMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.unityMZId)[0] ===
            undefined
              ? unityMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.unityMZId)[0]
          }
        />
      </div>
      <div>
        <h3>Vungle Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="vungle"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.vungleMZId)[0] ===
            undefined
              ? vungleMZId
              : appInfo.appInfos.map((item: AppInfos) => item.vungleMZId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.vungleMZId)[0] ===
            undefined
              ? vungleMZId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.vungleMZId)[0]
          }
        />
      </div>
    </div>
  );
}
