import React, { useState } from "react";
import { useAppInfo } from "../../Context/AppInfoContext";
import styles from "./AppInfoData.module.scss";
export default function Monetization({
  onChange,
  mzData,
  appInfo,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  mzData: MZData;
  appInfo: RequestAppInfoData;
}) {
  const {
    id,
    playOnApiKey,
    advertyKey,
    maxBannerAdUnitId,
    maxRewardedAdUnitId,
    maxInterstitialAdUnitId,
    apsInterstitialId,
    apsRewardedId,
    apsBannerId,
    apsAppId,
  } = mzData;

  const { updateMaxAdUnitId, getAppInfo } = useAppInfo();
  const [isRVLoading, setRVIsLoading] = useState(false);
  const [isISLoading, setISIsLoading] = useState(false);
  const [isBNLoading, setBNIsLoading] = useState(false);
  const onAdTypeClick = async (type: string) => {
    type === "RV" && setRVIsLoading(true);
    type === "IS" && setISIsLoading(true);
    type === "BN" && setBNIsLoading(true);
    await updateMaxAdUnitId({ appId: id, adType: type })
      .then(() => getAppInfo())
      .catch(() =>
        alert(`The appId "${id}" with adType "${type}" already exist`)
      );
    type === "RV" && setRVIsLoading(false);
    type === "IS" && setISIsLoading(false);
    type === "BN" && setBNIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div>
        <h3>AdvertyKey</h3>
        <input
          type="text"
          onChange={onChange}
          name="advertyKey"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.advertyKey)[0] ===
            undefined
              ? advertyKey
              : appInfo.appInfos.map((item: AppInfos) => item.advertyKey)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.advertyKey)[0] ===
            undefined
              ? advertyKey ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.advertyKey)[0]
          }
        />
      </div>
      <div>
        <h3>PlayOnApiKey</h3>
        <input
          type="text"
          onChange={onChange}
          name="playOnApiKey"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.playOnApiKey)[0] ===
            undefined
              ? playOnApiKey
              : appInfo.appInfos.map((item: AppInfos) => item.playOnApiKey)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.playOnApiKey)[0] ===
            undefined
              ? playOnApiKey ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.playOnApiKey)[0]
          }
        />
      </div>
      <div>
        <h3>Max Interstitial Id</h3>
        <div data-content="generate">
          <input
            type="text"
            onChange={onChange}
            name="maxInterstitial"
            placeholder={
              appInfo.appInfos.map(
                (item: AppInfos) => item.maxInterstitialAdUnitId
              )[0] === undefined
                ? maxInterstitialAdUnitId
                : appInfo.appInfos.map(
                    (item: AppInfos) => item.maxInterstitialAdUnitId
                  )[0]
            }
            value={
              appInfo.appInfos.map(
                (item: AppInfos) => item.maxInterstitialAdUnitId
              )[0] === undefined
                ? maxInterstitialAdUnitId ?? ""
                : appInfo.appInfos.map(
                    (item: AppInfos) => item.maxInterstitialAdUnitId
                  )[0]
            }
          />
          <button onClick={() => onAdTypeClick("IS")}>
            {isISLoading ? "Generating" : "Generate"}
          </button>
        </div>
      </div>
      <div>
        <h3>Max Reward Id</h3>
        <div>
          <div>
            <div data-content="generate">
              <input
                type="text"
                onChange={onChange}
                name="maxReward"
                placeholder={
                  appInfo.appInfos.map(
                    (item: AppInfos) => item.maxRewardedAdUnitId
                  )[0] === undefined
                    ? maxRewardedAdUnitId
                    : appInfo.appInfos.map(
                        (item: AppInfos) => item.maxRewardedAdUnitId
                      )[0]
                }
                value={
                  appInfo.appInfos.map(
                    (item: AppInfos) => item.maxRewardedAdUnitId
                  )[0] === undefined
                    ? maxRewardedAdUnitId ?? ""
                    : appInfo.appInfos.map(
                        (item: AppInfos) => item.maxRewardedAdUnitId
                      )[0]
                }
              />
              <button onClick={() => onAdTypeClick("RV")}>
                {isRVLoading ? "Generating" : "Generate"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3>Max Banner Id</h3>
        <div>
          <div data-content="generate">
            <input
              type="text"
              onChange={onChange}
              name="maxBanner"
              placeholder={
                appInfo.appInfos.map(
                  (item: AppInfos) => item.maxBannerAdUnitId
                )[0] === undefined
                  ? maxBannerAdUnitId
                  : appInfo.appInfos.map(
                      (item: AppInfos) => item.maxBannerAdUnitId
                    )[0]
              }
              value={
                appInfo.appInfos.map(
                  (item: AppInfos) => item.maxBannerAdUnitId
                )[0] === undefined
                  ? maxBannerAdUnitId ?? ""
                  : appInfo.appInfos.map(
                      (item: AppInfos) => item.maxBannerAdUnitId
                    )[0]
              }
            />
            <button onClick={() => onAdTypeClick("BN")}>
              {isBNLoading ? "Generating" : "Generate"}
            </button>
          </div>
        </div>
      </div>
      <div>
        <h3>APS app Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="aps"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.apsAppId)[0] ===
            undefined
              ? apsAppId
              : appInfo.appInfos.map((item: AppInfos) => item.apsAppId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.apsAppId)[0] ===
            undefined
              ? apsAppId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.apsAppId)[0]
          }
        />
      </div>
      <div>
        <h3>APS interstitial Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="apsInterstitial"
          placeholder={
            appInfo.appInfos.map(
              (item: AppInfos) => item.apsInterstitialId
            )[0] === undefined
              ? apsInterstitialId
              : appInfo.appInfos.map(
                  (item: AppInfos) => item.apsInterstitialId
                )[0]
          }
          value={
            appInfo.appInfos.map(
              (item: AppInfos) => item.apsInterstitialId
            )[0] === undefined
              ? apsInterstitialId ?? ""
              : appInfo.appInfos.map(
                  (item: AppInfos) => item.apsInterstitialId
                )[0]
          }
        />
      </div>
      <div>
        <h3>APS Reward Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="apsReward"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.apsRewardedId)[0] ===
            undefined
              ? apsRewardedId
              : appInfo.appInfos.map((item: AppInfos) => item.apsRewardedId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.apsRewardedId)[0] ===
            undefined
              ? apsRewardedId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.apsRewardedId)[0]
          }
        />
      </div>

      <div>
        <h3>APS Banner Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="apsBanner"
          placeholder={
            appInfo.appInfos.map((item: AppInfos) => item.apsBannerId)[0] ===
            undefined
              ? apsBannerId
              : appInfo.appInfos.map((item: AppInfos) => item.apsBannerId)[0]
          }
          value={
            appInfo.appInfos.map((item: AppInfos) => item.apsBannerId)[0] ===
            undefined
              ? apsBannerId ?? ""
              : appInfo.appInfos.map((item: AppInfos) => item.apsBannerId)[0]
          }
        />
      </div>
    </div>
  );
}
React.memo(Monetization);
