import moment from "moment";
import React, { useState, useRef } from "react";
import InvoiceConfirmLoading from "../../common/InvoiceConfirmLoading/InvoiceConfirmLoading";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { ReactComponent as CheckIcon } from "../../Img/Invoice/check_black.svg";
import { ReactComponent as NoticeIcon } from "../../Img/Invoice/notice.svg";

import fileIcon from "../../Img/Invoice/file.png";
import styles from "./AddRateInfo.module.scss";
import Cookies from "js-cookie";
export default function AddRateInfo({ date, setIsAddRateInfoClick }) {
  const { addRateInfo } = useInvoice();
  const [googleAdsExchange, setGoogleAdsExchange] = useState(0);
  const [googleIapExchange, setGoogleIapExchange] = useState(0);
  const [isFile, setIsFile] = useState(false);
  const [isAddClick, setIsAddClick] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("No file chosen");
  const formData = new FormData();
  const onChange = (e) => {
    const { id, value, files } = e.target;
    switch (id) {
      case "google_ads_input":
        setGoogleAdsExchange(value);

        return;
      case "google_iap_input":
        setGoogleIapExchange(value);

        return;
      case "apple_iap_file":
        formData.append("file", files[0]);

        setIsFile(formData.has("file"));
        setFileName(files[0].name);
        return;
      default:
        setGoogleAdsExchange(0);
    }
  };
  const onAddRateClick = () => {
    setIsAddClick(true);
    const blob = new Blob([fileInputRef?.current?.files[0]], {
      type: fileInputRef?.current?.files[0]?.type,
    });
    formData.append("file", blob, fileInputRef.current?.files[0]?.name);
    formData.append("month", date);
    formData.append("googleUARate", googleAdsExchange);
    formData.append("googleIapRate", googleIapExchange);
    addRateInfo(formData).then((res) => {
      if (res.status === 201) {
        formData.delete("file");
        formData.delete("month");
        formData.delete("googleUARate");
        formData.delete("googleIapRate");
        window.location.reload();
      } else if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        setIsAddClick(false);
        alert(res.data.message);
        formData.delete("file");
        formData.delete("month");
        formData.delete("googleUARate");
        formData.delete("googleIapRate");
      }
    });
  };
  return (
    <section className={styles.container}>
      <section className={styles.rate_info_contents}>
        <h3>Insert Exchange Rate</h3>
        <section>
          <h2>{moment(date).format("MMMM, YYYY")}</h2>
          <div className={styles.alert_text}>
            <NoticeIcon />
            <p>
              Please provide the exchange rate for{" "}
              {moment().subtract(2, "month").format("MMMM")}.
            </p>
          </div>
          <section className={styles.rate_insert_section}>
            <section className={styles.google_ads}>
              <p>
                <p>Google Ads(UA)</p>
                <p>*</p>
              </p>
              <label>
                <p>1USD =</p>
                <input
                  type="text"
                  id="google_ads_input"
                  onChange={onChange}
                  value={googleAdsExchange}
                  placeholder="0.00"
                />
                <p>KRW</p>
              </label>
            </section>
            <section className={styles.google_iap}>
              <p>
                <p>Google IAP</p> <p>*</p>
              </p>
              <label>
                <p>1USD =</p>
                <input
                  type="text"
                  id="google_iap_input"
                  onChange={onChange}
                  value={googleIapExchange}
                  placeholder="0.00"
                />
                <p>KRW</p>
              </label>
            </section>
            <section className={styles.apple_iap}>
              <p>
                <p>Apple IAP</p> <p>*</p>
              </p>
              <div className={styles.apple_iap_file_upload}>
                <p data-content={isFile && "active"}>
                  {isFile && <img src={fileIcon} alt="icon" />}
                  {fileName}
                </p>
                {isFile && <CheckIcon />}
                <label>
                  Upload File
                  <input
                    type="file"
                    accept=".csv"
                    id="apple_iap_file"
                    onChange={onChange}
                    ref={fileInputRef}
                  />
                </label>
              </div>
            </section>
          </section>
          <section className={styles.buttons}>
            <button onClick={() => setIsAddRateInfoClick(false)}>Cancel</button>
            <button onClick={isAddClick ? undefined : onAddRateClick}>
              {isAddClick ? <InvoiceConfirmLoading /> : "Apply"}
            </button>
          </section>
        </section>
      </section>
    </section>
  );
}
