import React, { useEffect, useState } from "react";
import styles from "./ModifiedGameInvoiceItem.module.scss";
import addIcon from "../../Img/Invoice/addIcon.png";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import ModifiedInvoiceItem from "../ModifiedInvoiceItem/ModifiedInvoiceItem";
import InvoiceAddEditLoading from "../../common/InvoiceAddEditLoading/InvoiceAddEditLoading";
import { useKeyEscClose } from "../../common/escClose/escClose";
import Cookies from "js-cookie";
export default function ModifiedGameInvoiceItem({
  item,
  setIsEditInvoiceItemClick,
  date,
}) {
  const { getBonus, modifiedBonus, testModifiedBonus } = useInvoice();
  const [bonusSumPrize, setBonusSumPrize] = useState(0);
  const [isDeleteClick, setIsDeleteClick] = useState(false);
  const [isEditClick, setIsEditClick] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState([
    { id: 1, type: 1, item: "", prize: 0 },
  ]);

  const [ModifiedGameBonus, setModifiedGameBonus] = useState([]);
  useEffect(() => {
    if (item?.isBonus) {
      return getBonus(item?.gameId, date).then((res) => {
        if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else if (res.status === 404) {
          alert(res.data.message);
        } else {
          setInvoiceItem(() =>
            res.data.balances.map((item) => ({
              id: item.id,
              type: item.type === "DEDUCT_BONUS" ? 1 : 2,
              item: item.description,
              prize: item.profit,
            }))
          );
        }
      });
    }
  }, [getBonus, item?.gameId, date, item?.isBonus]);

  useEffect(() => {
    setBonusSumPrize(() =>
      invoiceItem.reduce((acc, cur) => acc + cur.prize, 0)
    );
  }, [invoiceItem]);

  const onAddClick = () => {
    setInvoiceItem([
      ...invoiceItem,
      {
        id: invoiceItem.length + 1,
        type: 1,
        item: "",
        prize: 0,
        addTrue: true,
      },
    ]);
  };
  useEffect(() => {
    setModifiedGameBonus(() =>
      invoiceItem.map((data) =>
        data.addTrue
          ? {
              month: date,
              description: data.item,
              type: data.type === 1 ? "DEDUCT_BONUS" : "NON_DEDUCT_BONUS",
              profit: data.prize,
              gameStage: item.stage,
              gameId: item.gameId,
              userId: item.userId,
            }
          : {
              id: data.id,
              month: date,
              description: data.item,
              type: data.type === 1 ? "DEDUCT_BONUS" : "NON_DEDUCT_BONUS",
              profit: data.prize,
              gameStage: item.stage,
              gameId: item.gameId,
              userId: item.userId,
            }
      )
    );
  }, [invoiceItem, item.gameId, item.stage, item.userId, date]);

  const onTotalModifiedClick = async () => {
    setIsEditClick(true);
    if (ModifiedGameBonus.length) {
      if (item.stage === "TEST") {
        testModifiedBonus({ bonus: ModifiedGameBonus, month: date }).then(
          (res) => {
            if (res.status === 201) {
              window.location.reload();
            } else if (res.status === 401) {
              Cookies.remove("Authentication");
              window.location.reload();
            } else {
              setIsEditClick(false);
              alert(res.data.message);
            }
          }
        );
      } else {
        return modifiedBonus({ bonus: ModifiedGameBonus, month: date }).then(
          (res) => {
            if (res.status === 201) {
              window.location.reload();
            } else if (res.status === 401) {
              Cookies.remove("Authentication");
              window.location.reload();
            } else {
              setIsEditClick(false);
              alert(res.data.message);
            }
          }
        );
      }
    } else {
      setIsEditClick(false);
      window.location.reload();
    }
  };
  useKeyEscClose(() => {
    setIsEditInvoiceItemClick(false);
  });

  return (
    <section className={styles.container}>
      <section className={styles.contents}>
        <h3>Edit Invoice Item</h3>
        <section className={styles.game}>
          <div className={styles.game_img} />
          <p>{item?.gameName}</p>
        </section>
        <section className={styles.invoice_item}>
          <p>Invoice Item</p>
          <ul className={styles.invoice_item_list}>
            {invoiceItem?.map((list) => {
              return (
                <ModifiedInvoiceItem
                  list={list}
                  item={item}
                  key={list.id}
                  invoiceItem={invoiceItem}
                  setInvoiceItem={setInvoiceItem}
                  date={date}
                  setIsDeleteClick={setIsDeleteClick}
                  isDeleteClick={isDeleteClick}
                />
              );
            })}
          </ul>
          <button
            className={styles.invoice_item_add_button}
            onClick={onAddClick}
          >
            <img src={addIcon} alt="icon" />
            <p>Add Item</p>
          </button>
          <section className={styles.total_bonus_prize}>
            <div>
              <p>Total Amount</p>
              <p>
                {bonusSumPrize.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </div>
          </section>
        </section>
        <section className={styles.buttons}>
          <button
            onClick={() =>
              isEditClick || isDeleteClick
                ? undefined
                : setIsEditInvoiceItemClick(false)
            }
          >
            Close
          </button>
          <button
            onClick={
              isEditClick || isDeleteClick ? undefined : onTotalModifiedClick
            }
          >
            {isEditClick || isDeleteClick ? <InvoiceAddEditLoading /> : "Edit"}
          </button>
        </section>
      </section>
    </section>
  );
}
