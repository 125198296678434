import { getCookie } from "../db/cookie";

export default class GameStatusService implements GameStatusServiceList {
  http: any;
  constructor(http: any) {
    this.http = http;
  }
  async getList() {
    return await this.http
      .axios("/test/admin/game/gameStatusList", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: GameStatusList) => res)
      .catch((e: any) => e.response);
  }
  async getSearchGameList(gameName: string) {
    return await this.http
      .axios(`/test/admin/game/gameStatusList?gameName=${gameName}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: GameStatusList) => res)
      .catch((e: any) => e.response);
  }
  async getSearchStudioList(studioName: string) {
    return await this.http
      .axios(`/test/admin/game/gameStatusList?studioName=${studioName}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: GameStatusList) => res)
      .catch((e: any) => e.response);
  }
  async updateGameStatus(data: any) {
    return await this.http
      .axios(`/test/admin/game/update/gameStatus`, {
        method: "POST",
        data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async terminateGame(data: TerminateData) {
    return await this.http
      .axios(`/test/admin/game/terminate`, {
        method: "POST",
        data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async downloadMaxCohort(data: any) {
    return await this.http
      .axios(
        `/test/admin/max/cohortCSV?bundleId=${data.bundleId}&startDate=${
          data.startDate
        }&endDate=${data.endDate}${
          data.country.length ? `&country=${data.country}` : ""
        }${data.platform.length ? `&platform=${data.platform}` : ""}`,
        {
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: DataBlob) => res)
      .catch((e: any) => e.response);
  }
  async downloadCostMZ(data: DownAdnetwork) {
    return await this.http
      .axios(
        `/test/admin/game/adNetwork/mz/csv?bundleId=${data.bundleId}&startDate=${data.startDate}&endDate=${data.endDate}`,
        {
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: DataBlob) => res)
      .catch((e: any) => e.response);
  }
  async downloadCostUA(data: DownAdnetwork) {
    return await this.http
      .axios(
        `/test/admin/game/adNetwork/ua/csv?bundleId=${data.bundleId}&startDate=${data.startDate}&endDate=${data.endDate}`,
        {
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: DataBlob) => res)
      .catch((e: any) => e.response);
  }
}
