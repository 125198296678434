import React, { useState, useRef, useEffect } from "react";
import styles from "./InvoiceGameList.module.scss";
import axios from "axios";

import { ReactComponent as CheckIcon } from "../../Img/Invoice/check.svg";
import { ReactComponent as PlusIcon } from "../../Img/Invoice/plus.svg";
import { ReactComponent as ExpandIcon } from "../../Img/Invoice/expand.svg";
import { ReactComponent as MoreIcon } from "../../Img/Invoice/more.svg";
import InvoiceGameDetail from "../InvoiceGameDetail/InvoiceGameDetail";
import AddGameInvoiceItem from "../AddGameInvoiceItem/AddGameInvoiceItem";
import InvoiceGameMorePopup from "../InvoiceGameMorePopup/InvoiceGameMorePopup";

import ModifiedGameInvoiceItem from "../ModifiedGameInvoiceItem/ModifiedGameInvoiceItem";

import PreviewDetail from "../PreviewDetail/PreviewDetail";
import AddDetails from "../AddDetails/AddDetails";
import { useAuth } from "../../Context/AuthContext";
import EditDetails from "../EditDetails/EditDetails";

export default function InvoiceGameList({
  item,
  date,
  list,
  isRateInfo,
  status,
}) {
  const { invoiceUser } = useAuth();

  const editButtonRef = useRef(null);
  const [isGameIcon, setIsGameIcon] = useState(false);
  const [isDetailClick, setIsDetailClick] = useState(false);
  const [isAddInvoiceItemClick, setIsAddInvoiceItemClick] = useState(false);
  const [isMoreClick, setIsMoreClick] = useState(false);
  const [isEditInvoiceItemClick, setIsEditInvoiceItemClick] = useState(false);
  const [isPreviewDetailClick, setIsPreviewClick] = useState(false);
  const [isAddDetailsClick, setIsAddDetailsClick] = useState(false);
  const [isEditDetailsClick, setIsEditDetailsClick] = useState(false);

  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (!editButtonRef?.current?.contains(e.target)) {
        setIsMoreClick(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  useEffect(() => {
    axios
      .get(item?.gameIconUrl)
      .then((res) =>
        res.status === 200 ? setIsGameIcon(true) : setIsGameIcon(false)
      )
      .catch((e) => console.error(e.response.status));
  }, []);

  return (
    <>
      <li className={styles.container}>
        <div className={styles.click_area}>
          <span className={styles.name}>
            <ExpandIcon
              onClick={() => setIsDetailClick((prev) => !prev)}
              alt="icon"
              data-content={isDetailClick && "active"}
              className={styles.expand_icon}
            />
            {isGameIcon ? (
              <img
                className={styles.gameIcon}
                src={item?.gameIconUrl}
                alt="icon"
              />
            ) : (
              <div className={styles.gameIcon} />
            )}
            <p>{item?.gameName}</p>
          </span>
          <span
            className={styles.stage}
            data-content={item?.stage === "TEST" ? "ETC" : item?.stage}
          >
            <p>{item?.stage === "TEST" ? "ETC" : item?.stage}</p>
          </span>
          <span></span>
          <span className={styles.amount}>
            <p>
              {item?.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </span>
          <span></span>
          <span></span>
        </div>
        {invoiceUser.role === "SUPER" ? (
          !item.isDetail &&
          status !== "INSUFFICIENT" &&
          status !== "PENDING" &&
          status !== "PAID" ? (
            <button
              className={styles.invoice_item_add}
              onClick={() =>
                isRateInfo ? setIsAddDetailsClick(true) : undefined
              }
              data-content={!isRateInfo && "invalid"}
            >
              <PlusIcon className={styles.plus} />
              <p>Add Details</p>
            </button>
          ) : item.isDetail ? (
            <div className={styles.details_added_item}>
              <CheckIcon />
              <p>Details Added</p>
            </div>
          ) : (
            <div className={styles.details_invalid}>
              <PlusIcon className={styles.plus_invalid} />
              <p>Add Details</p>
            </div>
          )
        ) : !item.isBonus &&
          status !== "INSUFFICIENT" &&
          status !== "PENDING" &&
          status !== "PAID" ? (
          <button
            className={styles.invoice_item_add}
            onClick={() => setIsAddInvoiceItemClick(true)}
          >
            <PlusIcon className={styles.plus} />
            <p>Invoice Item</p>
          </button>
        ) : item.isBonus ? (
          <div className={styles.invoice_added_item}>
            <CheckIcon />
            <p>Invoice Item</p>
          </div>
        ) : (
          <div className={styles.invalid_invoice_item}>
            <PlusIcon className={styles.plus_invalid} />
            <p>Invoice Item</p>
          </div>
        )}

        <button
          className={styles.more}
          onClick={() => setIsMoreClick((prev) => !prev)}
          ref={editButtonRef}
        >
          <MoreIcon />
        </button>
        {isMoreClick && (
          <InvoiceGameMorePopup
            setIsMoreClick={setIsMoreClick}
            setIsEditInvoiceItemClick={setIsEditInvoiceItemClick}
            setIsPreviewClick={setIsPreviewClick}
            setIsEditDetailsClick={setIsEditDetailsClick}
            setIsAddInvoiceItemClick={setIsAddInvoiceItemClick}
            isRateInfo={isRateInfo}
            item={item}
            list={list}
          />
        )}
      </li>
      {isDetailClick && <InvoiceGameDetail item={item} date={date} />}
      {isAddInvoiceItemClick && (
        <AddGameInvoiceItem
          item={item}
          setIsAddInvoiceItemClick={setIsAddInvoiceItemClick}
          date={date}
        />
      )}
      {isEditInvoiceItemClick && (
        <ModifiedGameInvoiceItem
          item={item}
          setIsEditInvoiceItemClick={setIsEditInvoiceItemClick}
          date={date}
        />
      )}
      {isPreviewDetailClick && (
        <PreviewDetail
          item={item}
          setIsPreviewClick={setIsPreviewClick}
          date={date}
          list={list}
        />
      )}
      {isAddDetailsClick && (
        <AddDetails
          item={item}
          setIsAddDetailsClick={setIsAddDetailsClick}
          date={date}
        />
      )}
      {isEditDetailsClick && (
        <EditDetails
          item={item}
          setIsEditDetailsClick={setIsEditDetailsClick}
          date={date}
        />
      )}
    </>
  );
}
