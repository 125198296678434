import React, { useState, useRef, useEffect } from "react";
import styles from "./EditDetailOther.module.scss";
import { ReactComponent as DollarIcon } from "../../Img/Invoice/dollar.svg";
import { ReactComponent as DeleteIcon } from "../../Img/Invoice/close.svg";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";

import Cookies from "js-cookie";
export default function EditDetailOther({
  setDetailsItem,
  list,
  date,
  item,
  isDeleteClick,
  setIsDeleteClick,
  isAddClick,
}) {
  const prizeRef = useRef(null);
  const { deleteThirdPartyPrize } = useInvoice();
  const [isPrizeClick, setIsPrizeClick] = useState(false);
  const deleteClick = () => {
    setIsDeleteClick(true);
    if (!Object.keys(list).includes("addTrue")) {
      deleteThirdPartyPrize(
        JSON.stringify({
          id: Number(list.id),
          month: date,
          userId: Number(item.userId),
          gameId: Number(item.gameId),
        })
      ).then((res) => {
        if (res.status === 400) {
          alert(res.data.message);
          setIsDeleteClick(false);
        } else if (res.status === 401) {
          Cookies.remove("Authentication");
        } else {
          setIsDeleteClick(false);
          setDetailsItem((prev) => {
            return prev.filter((data) => data.id !== list?.id);
          });
        }
      });
    } else {
      setIsDeleteClick(false);
      setDetailsItem((prev) => {
        return prev.filter((data) => data.id !== list?.id);
      });
    }
  };

  const onChange = (e) => {
    const { value, id } = e.target;
    switch (id) {
      case "detail_description":
        setDetailsItem((prev) =>
          prev?.map((data) =>
            data.id === list.id ? { ...data, description: value } : data
          )
        );
        return;
      case "detail_prize":
        setDetailsItem((prev) =>
          prev?.map((data) =>
            data.id === list.id ? { ...data, prize: value } : data
          )
        );
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    let clickInputHandler = (e) => {
      if (prizeRef?.current?.contains(e.target)) {
        setIsPrizeClick(true);
      } else {
        setIsPrizeClick(false);
      }
    };
    document.addEventListener("mousedown", clickInputHandler);
    return () => document.removeEventListener("mousedown", clickInputHandler);
  }, []);

  return (
    <li className={styles.container}>
      <input
        type="text"
        id="detail_description"
        className={styles.desciption}
        onChange={onChange}
        value={list?.description}
      />
      <label data-content={isPrizeClick ? "active" : "inactive"} ref={prizeRef}>
        <DollarIcon />
        <input
          type="text"
          id="detail_prize"
          className={styles.prize}
          onChange={onChange}
          value={list?.prize}
        />
      </label>
      <DeleteIcon
        className={styles.delete_button}
        onClick={isDeleteClick || isAddClick ? undefined : deleteClick}
      />
    </li>
  );
}

//
