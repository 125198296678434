import React, { SetStateAction, useState } from "react";
import styles from "./AddApp.module.scss";
import { useAppInfo } from "../../Context/AppInfoContext";
export default function AddApp({
  gameInfoService,
  setIsAddAppBtn,
}: {
  gameInfoService: {
    createGameInfo: (gameInfo: any) => Promise<any>;
  };
  setIsAddAppBtn: React.Dispatch<SetStateAction<boolean>>;
}) {
  const { createAppInfo } = useAppInfo();
  const [gameInfo, setGameInfo] = useState({
    name: "",
    ownerId: undefined,
    unityProjectId: "",
    metaClientToken: "",
    gameIconUrl: "",
    status: "",
  });
  const [appInfo, setAppInfo] = useState({
    gameId: 0,
    platform: "",
    bundleId: "",
    storeId: "",
    metaUAId: "",
  });
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setGameInfo({ ...gameInfo, name: value });
        break;
      case "bundleId":
        setAppInfo({ ...appInfo, bundleId: value });
        break;
      case "ownerId":
        setGameInfo({
          ...gameInfo,
          ownerId: undefined,
        });
        break;
      case "unityProjectId":
        setGameInfo({ ...gameInfo, unityProjectId: value });
        break;
      case "metaClientToken":
        setGameInfo({ ...gameInfo, metaClientToken: value });
        break;
      case "status":
        setGameInfo({ ...gameInfo, status: value });
        break;
      case "appId":
        setAppInfo({ ...appInfo, metaUAId: value });
        break;
      default:
        return;
    }
  };
  const onSubmit = async () => {
    if (gameInfo.name.length === 0) {
      alert("게임 이름 넣어주세요");
      return;
    } else if (appInfo.metaUAId.length === 0) {
      alert("앱 아이디 넣어주세요");
      return;
    } else if (appInfo.bundleId.length === 0) {
      alert("bundleId 넣어 주세요");
      return;
    } else {
      gameInfoService
        .createGameInfo(gameInfo)
        .then(async (res) => {
          await createAppInfo({
            ...appInfo,
            gameId: res.data.id,
            platform: "ANDROID",
            storeId: appInfo.bundleId,
          });
          await createAppInfo({
            ...appInfo,
            gameId: res.data.id,
            platform: "IOS",
          });
          // await appInfoService.createAppInfo({
          //   ...appInfo,
          //   gameId: res.data.id,
          //   platform: "AMAZON",
          // });
        })
        .then(() => {
          window.location.href = "/appInfo";
        })
        .catch((e) => alert("게임이 리스트에 있는지 확인해주세요"));
    }
  };
  const onCancel = () => {
    setIsAddAppBtn(false);
  };
  return (
    <div className={styles.container}>
      <h1>App Info</h1>
      <div className={styles.input_container}>
        <div>
          <h3>
            Name <span>*</span>
          </h3>
          <input type="text" name="name" onChange={onChange} />
        </div>
        <div>
          <h3>
            Meta App Id <span>*</span>
          </h3>
          <input type="text" name="appId" onChange={onChange} />
        </div>
        <div>
          <h3>
            Bundle Id <span>*</span>
          </h3>
          <input type="text" name="bundleId" onChange={onChange} />
        </div>
        {/* <div>
        <h3>Owner Id</h3>
        <input type="text" name="ownerId" onChange={onChange} />
      </div> */}
        <div>
          <h3>Unity Project Id</h3>
          <input type="text" name="unityProjectId" onChange={onChange} />
        </div>
        <div>
          <h3>Meta Client Token</h3>
          <input type="text" name="metaClientToken" onChange={onChange} />
        </div>
        <div>
          <h3>Status</h3>
          <input type="text" name="status" onChange={onChange} />
        </div>
        <section className={styles.btnBox}>
          <button onClick={onCancel}>
            <p>Cancel</p>
          </button>
          <button onClick={onSubmit}>
            <p>Submit</p>
          </button>
        </section>
      </div>
    </div>
  );
}
