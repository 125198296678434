import moment from "moment";
import React, { useState } from "react";
import InvoiceCalander from "../../components/InvoiceCalander/InvoiceCalander";
import InvoiceData from "../../components/InvoiceData/InvoiceData";
import { monthCheck } from "../../data/monthCheck";
import styles from "./Invoice.module.scss";
export default function Invoice() {
  const [isCalanderClick, setIsCalanderClick] = useState(false);

  const [totalList, setTotalList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState<string>(moment().format("MMM"));
  const [prevDate, setPrevDate] = useState();
  const monthList = moment.monthsShort();
  const checkMonth: any = monthCheck.find((months: any) => months[month]);

  return (
    <div className={styles.container}>
      <div className={styles.top_section}>
        <h2>Invoices</h2>
        <span className={styles.select_calander}>
          <span className={styles.show_month}>
            <p>show</p>
            <p>
              {month} , {year}
            </p>
          </span>
          <button
            onClick={() => setIsCalanderClick((prev) => !prev)}
            data-content={isCalanderClick && "active"}
          >
            Select
          </button>
          {isCalanderClick && (
            <InvoiceCalander
              setIsCalanderClick={setIsCalanderClick}
              monthList={monthList}
              setYear={setYear}
              setMonth={setMonth}
              month={month}
              year={year}
              prevDate={prevDate}
              setPrevDate={setPrevDate}
            />
          )}
        </span>
      </div>
      <InvoiceData
        date={moment(`${year}-${checkMonth[month]}`).format("YYYY-MM")}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        totalList={totalList}
        setTotalList={setTotalList}
      />
    </div>
  );
}
