import { memo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./NewSideBar.module.scss";

import { ReactComponent as GamesIcon } from "../../Img/SideBar/games.svg";
import { ReactComponent as CostIcon } from "../../Img/SideBar/cost.svg";
import { ReactComponent as ArrowIcon } from "../../Img/SideBar/arrow.svg";
import { ReactComponent as RocketIcon } from "../../Img/Invoice/rocket.svg";
import { ReactComponent as HomeIcon } from "../../Img/SideBar/home.svg";
import logo from "../../Img/SideBar/logo.png";
import logoutIcon from "../../Img/SideBar/logoutIcon.png";
import { useAuth } from "../../Context/AuthContext";
const NewSideBar = memo(() => {
  const location = useLocation();
  const { logout, user } = useAuth();
  const [isTestClick, setIsTestClick] = useState<boolean>(false);

  const [isPublishingClick, setIsPublishingClick] = useState<boolean>(false);
  const [isFinanceClick, setIsFinanceClick] = useState<boolean>(false);

  const onLogout = () => {
    logout();
  };

  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.menuList}>
        <div>
          <div
            className={styles.home}
            data-content={location.pathname === "/" ? "active" : "inactive"}
          >
            <Link to="/">
              <HomeIcon className={styles.home_icon} />
              <p className={styles.homeText}>Home</p>
            </Link>
          </div>

          <div className={styles.tests}>
            <div
              onClick={() => setIsTestClick((prev) => !prev)}
              data-content={
                location.pathname.split("/").includes("progress") ||
                location.pathname.split("/").includes("complete") ||
                location.pathname.split("/").includes("adUnit")
                  ? "active"
                  : "inactive"
              }
            >
              <div>
                <GamesIcon className={styles.game_icon} />
                <p>Tests</p>
              </div>
              <ArrowIcon
                className={styles.arrow}
                data-content={isTestClick && "active"}
              />
            </div>
            {isTestClick && (
              <>
                {/* <Link
                  to="/test/adUnit"
                  data-content={
                    location.pathname.split("/").includes("adUnit") && "active"
                  }
                >
                  <div>ID Requests</div>
                </Link> */}
                <Link
                  to="/test/progress"
                  data-content={
                    location.pathname.split("/").includes("progress") &&
                    "active"
                  }
                >
                  <div>In progress</div>
                </Link>
                <Link
                  to="/test/complete"
                  data-content={
                    location.pathname.split("/").includes("complete") &&
                    "active"
                  }
                >
                  <div>Complete</div>
                </Link>
              </>
            )}
          </div>

          <div>
            <div className={styles.publishing}>
              <div
                onClick={() => setIsPublishingClick((prev) => !prev)}
                data-content={
                  location.pathname.split("/").includes("publishing") ||
                  location.pathname.split("/").includes("appInfo") ||
                  location.pathname.split("/").includes("status")
                    ? "active"
                    : "inactive"
                }
                data-invalid={user && user?.data?.id === 9 && "invalid"}
              >
                <div>
                  <RocketIcon className={styles.rocket_icon} />
                  <p>Publishing</p>
                </div>
                <ArrowIcon
                  className={styles.arrow}
                  data-content={isPublishingClick && "active"}
                />
              </div>
              {isPublishingClick && (
                <>
                  <Link
                    to={
                      user && user?.data?.id !== 9
                        ? "/publishing/overview"
                        : "/"
                    }
                    data-content={
                      location.pathname.split("/").includes("publishing") &&
                      "active"
                    }
                    data-invalid={user && user?.data?.id === 9 && "invalid"}
                  >
                    <div>Overview</div>
                  </Link>
                  <Link
                    to={
                      user && user?.data?.id !== 9 ? "/pulishing/appInfo" : "/"
                    }
                    data-content={
                      location.pathname.split("/").includes("appInfo") &&
                      "active"
                    }
                    data-invalid={user && user?.data?.id === 9 && "invalid"}
                  >
                    <div>App Info</div>
                  </Link>
                  <Link
                    to={
                      user && user?.data?.id !== 9 ? "/pulishing/status" : "/"
                    }
                    data-content={
                      location.pathname.split("/").includes("status") &&
                      "active"
                    }
                    data-invalid={user && user?.data?.id === 9 && "invalid"}
                  >
                    <div>Game Status</div>
                  </Link>
                </>
              )}
            </div>
            <div className={styles.finance}>
              <div
                onClick={() => setIsFinanceClick((prev) => !prev)}
                data-content={
                  location.pathname.includes("/finance/overview") ||
                  location.pathname.split("/").includes("invoice") ||
                  location.pathname.split("/").includes("legacy")
                    ? "active"
                    : "inactive"
                }
                data-invalid={user && user?.data?.id === 9 && "invalid"}
              >
                <div>
                  <CostIcon className={styles.cost_icon} />
                  <span>
                    <p>Finance</p>
                    <p>Beta</p>
                  </span>
                </div>
                <ArrowIcon
                  className={styles.arrow}
                  data-content={isFinanceClick && "active"}
                />
              </div>
              {isFinanceClick && (
                <>
                  <Link
                    to={
                      user && user?.data?.id !== 9 ? "/finance/overview" : "/"
                    }
                    data-content={
                      location.pathname.includes("/finance/overview") &&
                      "active"
                    }
                    data-invalid={user && user?.data?.id === 9 && "invalid"}
                  >
                    <div>Overview</div>
                  </Link>
                  <Link
                    to={user && user?.data?.id !== 9 ? "/finance/invoice" : "/"}
                    data-content={
                      location.pathname.split("/").includes("invoice") &&
                      "active"
                    }
                    data-invalid={user && user?.data?.id === 9 && "invalid"}
                  >
                    <div>Invoices</div>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>

        <li className={styles.menu_logout} onClick={onLogout}>
          <img src={logoutIcon} alt="" className={styles.logoutIcon} />
          <div>Log out</div>
        </li>
      </div>
    </div>
  );
});

export default NewSideBar;
