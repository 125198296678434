import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import Dropzone from "react-dropzone";
import styles from "./ProfileEdit.module.css";
import pencil from "../../Img/profileEdit/img_edit.png";
import defaultImg from "../../Img/profileEdit/default_profile.png";
const ProfileEdit = ({ user, modifingInfo }) => {
  const [profileImg, setProfileImg] = useState(user?.data?.profileImg);
  const [updatePassword, setUpdadtePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  useEffect(() => {
    actionImgCompress(profileImg);
  }, [profileImg]);
  const actionImgCompress = async (fileSrc) => {
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(fileSrc[0], options);
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        const base64Data = reader.result;
        setProfileImg(base64Data);
      };
    } catch (err) {
      console.log(err);
    }
  };
  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;
    switch (name) {
      case "password":
        setUpdadtePassword(value);
        return;
      case "confirmPassword":
        setConfirmPassword(value);
        return;

      default:
        return;
    }
  };
  const onClick = async () => {
    try {
      if (!updatePassword) {
        alert("Please enter your password");
      } else if (updatePassword !== confirmPassword) {
        alert("Please check your password");
      } else {
        modifingInfo(user?.data?.id, confirmPassword, profileImg)
          .then(() =>
            setTimeout(() => {
              window.location.reload();
            }, 500)
          )
          .catch(console.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.dropZoneContainer}>
        <Dropzone onDrop={(acceptedFiles) => setProfileImg(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section className={styles.dragAndDropArea}>
              <div {...getRootProps()} className={styles.dragAndDropBox}>
                <input {...getInputProps()} />
                <img
                  src={profileImg ? profileImg : user?.data?.profileImg}
                  alt=""
                  className={styles.profileImg}
                />
                <div className={styles.pencil}>
                  <img src={pencil} alt="pencil" />
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        <div className={styles.name}>{user?.data?.name}</div>
      </div>
      <div className={styles.passwordContainer}>
        <div className={styles.studioName}>{user?.data?.studioName}</div>

        <div className={styles.passwordInputArea}>
          <div>
            <div>New Password</div>
            <input
              placeholder="Password"
              type="password"
              id="password"
              name="password"
              onChange={onChange}
            />
          </div>
          <div>
            <div>New Password Confirmation</div>
            <input
              placeholder="Password Confirmation"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              onChange={onChange}
            />
          </div>
        </div>

        <button className={styles.editBtn} onClick={onClick}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default ProfileEdit;
