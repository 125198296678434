import styles from "./AppInfoList.module.scss";
import iosIcon from "../../Img/appInfo/ios.png";
import androidIcon from "../../Img/appInfo/android.png";

import { useAppInfo } from "../../Context/AppInfoContext";
import { setCookie } from "../../db/cookie";
export default function AppInfoList({
  data,
  setIsClick,
}: {
  data: AppInfoData;
  setIsClick: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setSelectList } = useAppInfo();

  const onClick = (info: AppInfoData) => {
    setCookie("id", info.id);
    setSelectList((prev: AppInfoData) => {
      return (prev = info);
    });
    setIsClick((prev: boolean) => (prev = false));
  };

  return (
    <li className={styles.container} onClick={() => onClick(data)}>
      <div className={styles.infoContainer}>
        <img src={data?.game?.gameIconUrl} alt="" />
        <div className={styles.appInfoBox}>
          <p>{data?.game?.name}</p>
          {data?.platform === "IOS" ? (
            <div>
              <img src={iosIcon} alt="ios" />
              <p>App Store</p>
            </div>
          ) : data?.platform === "ANDROID" ? (
            <div>
              <img src={androidIcon} alt="android" />
              <p>Android</p>
            </div>
          ) : (
            <div>
              <img src={androidIcon} alt="amazon" />
              <p>Amazon</p>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}
