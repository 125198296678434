import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./IPM.module.scss";
const IPM = ({ adsetData }) => {
  const [impression, setImpression] = useState("");
  const [installs, setInstalls] = useState("");
  const [IPM, setIPM] = useState(0);
  useEffect(() => {
    setInstalls("");
    setImpression("");
    adsetData?.insights?.data?.map((data) => {
      setImpression(data?.impressions);
      return setInstalls(
        data?.actions?.find(
          (action) => action?.action_type === "omni_app_install"
        )?.value
      );
    });
  });
  useEffect(() => {
    setIPM(0);
    setIPM(((Number(installs) / Number(impression)) * 1000).toFixed(2));
  }, [impression, installs]);

  return (
    <div className={styles.container}>
      <div>IPM</div>
      <div>{isNaN(IPM) ? "-" : IPM}</div>
    </div>
  );
};

export default IPM;
