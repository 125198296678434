import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { useAppInfo } from "../../Context/AppInfoContext";
import { getCookie, setCookie } from "../../db/cookie";
import Adnetwork from "../AppInfoData/Adnetwork";
import Game from "../AppInfoData/Game";
import Monetization from "../AppInfoData/Monetization";
import styles from "./EnterAppInfo.module.scss";
export default function EnterAppInfo({ data, gameInfoService }) {
  const { selectList } = useAppInfo();
  const menuName = getCookie("menu");
  const [isGame, setIsGame] = useState(true);
  const [isAdNetwork, setIsNetwork] = useState(false);
  const [isMZData, setIsMZData] = useState(false);

  useEffect(() => {
    switch (menuName) {
      case "Game":
        setIsGame(true);
        setIsNetwork(false);
        setIsMZData(false);
        return;

      case "Network":
        setIsGame(false);
        setIsNetwork(true);
        setIsMZData(false);
        return;
      case "Monetization":
        setIsGame(false);
        setIsNetwork(false);
        setIsMZData(true);
        return;
      default:
        return;
    }
  }, [menuName]);
  const {
    id,
    platform,
    adColonyMZId,
    vungleMZId,
    inmobiMZId,
    ironSourceMZId,
    mintegralMZId,
    unityMZId,
    metaMZId,
    metaUAId,
    tapjoyMZId,
    fyberMZId,
    googleMZId,
    hyprmxMZId,
    apsAppId,
    playOnApiKey,
    advertyKey,
    maxBannerAdUnitId,
    maxRewardedAdUnitId,
    maxInterstitialAdUnitId,
    apsInterstitialId,
    apsRewardedId,
    apsBannerId,
    gameId,
    bundleId,
    storeId,
    smaatoEntityId,
    oguryAssetKey,
  } = selectList;
  const { gameKey, ownerId, unityProjectId, metaClientToken, name, status } =
    selectList?.game;

  const [appInfo, setAppInfo] = useState({
    id: gameId,
    name,
    ownerId: ownerId ?? undefined,
    gameKey: gameKey ?? undefined,
    unityProjectId: unityProjectId ?? undefined,
    metaClientToken: metaClientToken ?? undefined,
    status: status ?? undefined,
    appInfos: [
      {
        id,
        platform: platform,
        adColonyMZId: adColonyMZId ?? undefined,
        vungleMZId: vungleMZId ?? undefined,
        inmobiMZId: inmobiMZId ?? undefined,
        ironSourceMZId: ironSourceMZId ?? undefined,
        mintegralMZId: mintegralMZId ?? undefined,
        unityMZId: unityMZId ?? undefined,
        metaMZId: metaMZId ?? undefined,
        metaUAId: metaUAId ?? undefined,
        tapjoyMZId: tapjoyMZId ?? undefined,
        fyberMZId: fyberMZId ?? undefined,
        googleMZId: googleMZId ?? undefined,
        hyprmxMZId: hyprmxMZId ?? undefined,
        playOnApiKey: playOnApiKey ?? undefined,
        advertyKey: advertyKey ?? undefined,
        maxBannerAdUnitId: maxBannerAdUnitId ?? undefined,
        maxRewardedAdUnitId: maxRewardedAdUnitId ?? undefined,
        maxInterstitialAdUnitId: maxInterstitialAdUnitId ?? undefined,
        apsAppId: apsAppId ?? undefined,
        apsInterstitialId: apsInterstitialId ?? undefined,
        apsRewardedId: apsRewardedId ?? undefined,
        apsBannerId: apsBannerId ?? undefined,
        gameId,
        bundleId: bundleId ?? undefined,
        storeId: storeId ?? undefined,
        smaatoEntityId: smaatoEntityId ?? undefined,
        oguryAssetKey: oguryAssetKey ?? undefined,
      },
    ],
  });
  useEffect(() => {
    setAppInfo({
      id: gameId,
      name,
      ownerId: ownerId ?? undefined,
      gameKey: gameKey ?? undefined,
      unityProjectId: unityProjectId ?? undefined,
      metaClientToken: metaClientToken ?? undefined,
      status: status ?? undefined,
      appInfos: [
        {
          id,
          platform: platform,
          adColonyMZId: adColonyMZId ?? undefined,
          vungleMZId: vungleMZId ?? undefined,
          inmobiMZId: inmobiMZId ?? undefined,
          ironSourceMZId: ironSourceMZId ?? undefined,
          mintegralMZId: mintegralMZId ?? undefined,
          unityMZId: unityMZId ?? undefined,
          metaMZId: metaMZId ?? undefined,
          metaUAId: metaUAId ?? undefined,
          tapjoyMZId: tapjoyMZId ?? undefined,
          fyberMZId: fyberMZId ?? undefined,
          googleMZId: googleMZId ?? undefined,
          hyprmxMZId: hyprmxMZId ?? undefined,
          playOnApiKey: playOnApiKey ?? undefined,
          advertyKey: advertyKey ?? undefined,
          maxBannerAdUnitId: maxBannerAdUnitId ?? undefined,
          maxRewardedAdUnitId: maxRewardedAdUnitId ?? undefined,
          maxInterstitialAdUnitId: maxInterstitialAdUnitId ?? undefined,
          apsAppId: apsAppId ?? undefined,
          apsInterstitialId: apsInterstitialId ?? undefined,
          apsRewardedId: apsRewardedId ?? undefined,
          apsBannerId: apsBannerId ?? undefined,
          gameId,
          bundleId: bundleId ?? undefined,
          storeId: storeId ?? undefined,
          smaatoEntityId: smaatoEntityId ?? undefined,
          oguryAssetKey: oguryAssetKey ?? undefined,
        },
      ],
    });
  }, [
    adColonyMZId,
    advertyKey,
    apsAppId,
    apsBannerId,
    apsInterstitialId,
    apsRewardedId,
    bundleId,
    data,
    fyberMZId,
    gameId,
    gameKey,
    googleMZId,
    hyprmxMZId,
    id,
    inmobiMZId,
    ironSourceMZId,
    maxBannerAdUnitId,
    maxInterstitialAdUnitId,
    maxRewardedAdUnitId,
    metaClientToken,
    metaMZId,
    metaUAId,
    mintegralMZId,
    name,
    ownerId,
    platform,
    playOnApiKey,
    status,
    storeId,
    tapjoyMZId,
    unityMZId,
    unityProjectId,
    vungleMZId,
    smaatoEntityId,
    oguryAssetKey,
  ]);
  const onClick = (e) => {
    e.preventDefault();
    const { id } = e.target;
    switch (id) {
      case "Game":
        setCookie("menu", id);
        setIsGame(true);
        setIsNetwork(false);
        setIsMZData(false);
        return;

      case "Network":
        setCookie("menu", id);
        setIsGame(false);
        setIsNetwork(true);
        setIsMZData(false);
        return;
      case "Monetization":
        setCookie("menu", id);
        setIsGame(false);
        setIsNetwork(false);
        setIsMZData(true);
        return;
      default:
        return;
    }
  };

  const onChange = useCallback(
    (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      switch (name) {
        case "ownerId":
          setAppInfo({
            ...appInfo,
            ownerId: Number(value),
          });
          break;
        case "gameKey":
          setAppInfo({
            ...appInfo,
            gameKey: value,
          });
          break;

        case "unityProjectId":
          setAppInfo({
            ...appInfo,
            unityProjectId: value,
          });
          break;
        case "metaClientToken":
          setAppInfo({
            ...appInfo,
            metaClientToken: value,
          });
          break;
        case "status":
          setAppInfo({
            ...appInfo,
            status: value,
          });
          break;
        case "adcolony":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              adColonyMZId: value,
            })),
          });
          break;
        case "oguryAssetKey":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              oguryAssetKey: value,
            })),
          });
          break;
        case "smaatoEntity":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              smaatoEntityId: value,
            })),
          });
          break;
        case "vungle":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              vungleMZId: value,
            })),
          });
          break;
        case "inmobi":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              inmobiMZId: value,
            })),
          });

          break;
        case "mintegral":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              mintegralMZId: value,
            })),
          });

          break;
        case "unityMZ":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              unityMZId: value,
            })),
          });
          break;
        case "metaMZ":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              metaMZId: value,
            })),
          });

          break;
        case "metaUA":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              metaUAId: value,
            })),
          });

          break;
        case "tapjoy":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              tapjoyMZId: value,
            })),
          });

          break;
        case "fyber":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              fyberMZId: value,
            })),
          });

          break;
        case "googleMZ":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              googleMZId: value,
            })),
          });

          break;
        case "hyprmax":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              hyprmxMZId: value,
            })),
          });

          break;
        case "ironSourceMZ":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              ironSourceMZId: value,
            })),
          });

          break;
        case "playOnApiKey":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              playOnApiKey: value,
            })),
          });
          break;
        case "advertyKey":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              advertyKey: value,
            })),
          });

          break;
        case "maxReward":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              maxRewardedAdUnitId: value,
            })),
          });

          break;
        case "maxBanner":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              maxBannerAdUnitId: value,
            })),
          });

          break;
        case "maxInterstitial":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              maxInterstitialAdUnitId: value,
            })),
          });

          break;
        case "aps":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              apsAppId: value,
            })),
          });

          break;
        case "apsInterstitial":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              apsInterstitialId: value,
            })),
          });

          break;
        case "apsReward":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              apsRewardedId: value,
            })),
          });

          break;
        case "apsBanner":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              apsBannerId: value,
            })),
          });

          break;
        case "storeId":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              storeId: value,
            })),
          });

          break;
        case "androidStoreId":
          setAppInfo({
            ...appInfo,
            appInfos: appInfo.appInfos.map((item) => ({
              ...item,
              bundleId: value,
            })),
          });

          break;
        default:
          return;
      }
    },
    [appInfo]
  );
  const onSubmit = async () => {
    return await gameInfoService
      .updateTotalInfo(appInfo)
      .then((res) =>
        res.status === 201 ? window.location.reload() : console.error("fail")
      );
  };

  return (
    <>
      <div className={styles.container}>
        <ul className={styles.menuBar}>
          <li
            onClick={(e) => onClick(e)}
            id="Game"
            data={isGame ? "active" : undefined}
          >
            Game
          </li>

          <li
            onClick={(e) => onClick(e)}
            id="Network"
            data={isAdNetwork ? "active" : undefined}
          >
            Ad Network
          </li>

          <li
            onClick={(e) => onClick(e)}
            id="Monetization"
            data={isMZData ? "active" : undefined}
          >
            Monetization Data
          </li>
        </ul>
        {isGame && (
          <Game
            onChange={onChange}
            gameData={{
              ownerId,
              gameKey,
              unityProjectId,
              metaClientToken,
              status,
            }}
            appInfo={appInfo}
          />
        )}
        {isAdNetwork && (
          <Adnetwork
            onChange={onChange}
            adNetworkData={{
              id,
              adColonyMZId,
              vungleMZId,
              inmobiMZId,
              ironSourceMZId,
              mintegralMZId,
              unityMZId,
              metaMZId,
              metaUAId,
              tapjoyMZId,
              fyberMZId,
              googleMZId,
              hyprmxMZId,
              storeId,
              smaatoEntityId,
              bundleId,
              oguryAssetKey,
            }}
            appInfo={appInfo}
            platform={platform}
          />
        )}
        {isMZData && (
          <Monetization
            onChange={onChange}
            mzData={{
              id,
              playOnApiKey,
              advertyKey,
              maxBannerAdUnitId,
              maxRewardedAdUnitId,
              maxInterstitialAdUnitId,
              apsAppId,
              apsInterstitialId,
              apsRewardedId,
              apsBannerId,
            }}
            appInfo={appInfo}
          />
        )}
      </div>
      <button onClick={onSubmit} className={styles.submitButton}>
        Submit
      </button>
    </>
  );
}
