import { useEffect, useState } from "react";
import moment from "moment";
import { subDays } from "date-fns";
import Status from "../../components/Finance/Status";
import PubCalender from "../../components/PubCalendar/PubCalendar";
import PubSummary from "../../components/PubSummary/PubSummary";
import styles from "./PublishingDetail.module.scss";
import { useAdnetworkData } from "../../Context/Adnetwork";

export default function PublishingDetail() {
  const { getAdsList, getAdsnetworkData } = useAdnetworkData();
  const [select, setSelect] = useState<{ id: number; select: string }>({
    id: 1,
    select: "7 days",
  });
  const [clickCalendar, setClickCalendar] = useState(false);
  const [adsList, setAdsList] = useState<Array<GameData>>([]);

  const [adNetworkData, setAdNetworkData] =
    useState<void | Array<AdnetworkData>>([]);
  const dayList: Array<{ id: number; select: string }> = [
    { id: 1, select: "7 days" },
    { id: 2, select: "30 days" },
    { id: 3, select: "All Time" },
    { id: 4, select: "Custom" },
  ];

  useEffect(() => {
    getAdsList().then((data: Array<GameData>) => {
      return setAdsList(data);
    });
  }, [getAdsList]);

  const [state, setState] = useState<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>({
    startDate: subDays(new Date(moment().format("YYYY-MM-DD")), 10),
    endDate: subDays(new Date(moment().format("YYYY-MM-DD")), 4),
    key: "selection",
  });
  const [thirtyState, setThirtyState] = useState<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>({
    startDate: subDays(new Date(moment().format("YYYY-MM-DD")), 34),
    endDate: subDays(new Date(moment().format("YYYY-MM-DD")), 4),
    key: "selection",
  });
  const [allTimeState, setAllTimeSteate] = useState<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>({
    startDate: subDays(new Date(moment("2022-10-01").format("YYYY-MM-DD")), 0),
    endDate: subDays(new Date(moment().format("YYYY-MM-DD")), 4),
    key: "selection",
  });

  const [selectRange, setSelectRange] = useState<
    Array<{
      startDate: Date;
      endDate: Date;
      key: string;
    }>
  >([
    {
      startDate: subDays(new Date(moment().format("YYYY-MM-DD")), 10),
      endDate: subDays(new Date(moment().format("YYYY-MM-DD")), 4),
      key: "selection",
    },
  ]);

  useEffect(() => {
    (async () => {
      setAdNetworkData([]);
      if (adsList?.length !== 0) {
        if (select.id === 1) {
          await getAdsnetworkData(
            moment(state.startDate).format("YYYY-MM-DD"),
            moment(state.endDate).format("YYYY-MM-DD")
          ).then((res: void | Array<AdnetworkData>) => setAdNetworkData(res));
        } else if (select.id === 2) {
          await getAdsnetworkData(
            moment(thirtyState.startDate).format("YYYY-MM-DD"),
            moment(thirtyState.endDate).format("YYYY-MM-DD")
          )
            .then((res: void | Array<AdnetworkData>) => setAdNetworkData(res))
            .catch(console.error);
        } else if (select.id === 3) {
          await getAdsnetworkData(
            moment(allTimeState.startDate).format("YYYY-MM-DD"),
            moment(allTimeState.endDate).format("YYYY-MM-DD")
          )
            .then((res: void | Array<AdnetworkData>) => setAdNetworkData(res))
            .catch(console.error);
        }
      }
    })();
  }, [adsList, select.id]);

  const rangeDateUpdate = async () => {
    setAdNetworkData([]);

    setClickCalendar(false);
    if (adsList?.length !== 0) {
      await getAdsnetworkData(
        moment(selectRange[0].startDate).format("YYYY-MM-DD"),
        moment(selectRange[0].endDate).format("YYYY-MM-DD")
      )
        .then((res: void | Array<AdnetworkData>) => setAdNetworkData(res))
        .catch(console.error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.firstSectionBox}>
        <PubSummary adNetworkData={adNetworkData} />
        <PubCalender
          select={select}
          setSelect={setSelect}
          thirtyState={thirtyState}
          setThirtyState={setThirtyState}
          clickCalendar={clickCalendar}
          setClickCalendar={setClickCalendar}
          dayList={dayList}
          state={state}
          setState={setState}
          selectRange={selectRange}
          setSelectRange={setSelectRange}
          rangeDateUpdate={rangeDateUpdate}
          allTimeState={allTimeState}
          setAllTimeSteate={setAllTimeSteate}
        />
      </div>
      <Status adNetworkData={adNetworkData} adsList={adsList} />
    </div>
  );
}
