export default class GameInfoService {
  http: any;
  constructor(http: any) {
    this.http = http;
  }
  async createGameInfo(gameData: any) {
    return await this.http
      .axios("/", {
        method: "POST",
        data: JSON.stringify({ ...gameData, gameIconUrl: "icon" }),
      })
      .then((res: ResponseData) => res)
      .catch((e: any) => {
        throw e;
      });
  }
  async getAppInfo() {
    return await this.http
      .axios("/", {
        method: "GET",
      })
      .then((res: any) => res.data);
  }
  async updateTotalInfo(appInfo: any) {
    return await this.http
      .axios("/update", {
        method: "POST",
        data: JSON.stringify(appInfo),
      })
      .then((res: any) => {
        return res;
      });
  }
}
