import { ChangeEvent, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import CircularProgress from "@mui/material/CircularProgress";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";

import { ReactComponent as CheckIcon } from "../../Img/FinaceOverview/check.svg";
import styles from "./FinanceOverviewDataList.module.scss";
import FinanceOverviewGameList from "../FinanceOverviewGameList/FinanceOverviewGameList";
import FinanceOverviewListSkeleton from "../FinanceOverviewListSkeleton/FinanceOverviewListSkeleton";
import moment from "moment";
import Cookies from "js-cookie";

export default function FinanceOverviewDataList({
  list,
  isLoading,
  selectDate,
}: {
  list: Array<FinanceOverviewGameData>;
  isLoading: boolean;
  selectDate: { startDate: Date; endDate: Date; key: string };
}) {
  const { downloadOverviewData } = useInvoice();
  const [isCsvDownload, setIsCsvDownload] = useState<boolean>(false);
  const [checkItem, setCheckItem] = useState<number[]>([]);
  const [dataCsv, setDataCsv] = useState<string>("");

  const csvLinkRef = useRef<any>(null);
  const onDownClick = async () => {
    setIsCsvDownload(true);
    await downloadOverviewData(
      moment(selectDate.startDate).format("YYYY-MM-DD"),
      moment(selectDate.endDate).format("YYYY-MM-DD"),
      checkItem.toString()
    ).then((res) => {
      if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else if (res.status === 400) {
        alert(res.data.message);
      } else {
        return setDataCsv(res.data);
      }
    });
    setIsCsvDownload(false);
    csvLinkRef.current.link.click();
  };
  const onTotalCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const idArray: number[] = [];
      list.forEach((el: FinanceOverviewGameData) =>
        idArray.push(el.everyDayGameId)
      );
      setCheckItem(idArray);
    } else {
      setCheckItem([]);
    }
  };
  const onSingleCheck = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const { checked } = e.target;
    if (checked) {
      setCheckItem((prev) => [...prev, id]);
    } else {
      setCheckItem(checkItem.filter((el) => el !== id));
    }
  };

  return (
    <section className={styles.container}>
      <section className={styles.top_section}>
        <span className={styles.text_area}>
          <h2>Launched Games</h2>
          <p>
            Real-time data is provided for reference purposes only. Accurate
            data will be reflected in monthly statements.
          </p>
        </span>
        <button
          className={styles.csv_down_btn}
          data-content={checkItem.length ? "active" : "inactive"}
          onClick={
            isLoading || isCsvDownload || !checkItem.length
              ? undefined
              : onDownClick
          }
        >
          <p>
            {isCsvDownload ? (
              <CircularProgress size="20px" color="inherit" />
            ) : (
              "Export Data"
            )}
          </p>
        </button>
        <CSVLink
          data={dataCsv}
          filename={`${moment(selectDate.startDate).format(
            "YYYY-MM-DD"
          )}~${moment(selectDate.endDate).format("YYYY-MM-DD")}`}
          ref={csvLinkRef}
          className={styles.hidden}
        ></CSVLink>
      </section>
      <section className={styles.data_container}>
        <ul className={styles.data_header}>
          <li>
            <input
              className={styles.total_click_input}
              type="checkbox"
              id="total_checkbox"
              onChange={onTotalCheck}
              checked={
                checkItem?.length === list?.length && list?.length !== 0
                  ? true
                  : false
              }
            />
            <label className={styles.check_box} htmlFor="total_checkbox">
              <CheckIcon className={styles.icon} />
            </label>
          </li>
          <li>
            <p>Game</p>
          </li>
          <li>
            <p>Ad Revenue</p>
          </li>
          <li>
            <p>IAP Revenue</p>
          </li>
          <li>
            <p>Spend</p>
          </li>
          <li>
            <p>Profit</p>
          </li>
          <li>
            <p>Installs</p>
          </li>
        </ul>
        <ul className={styles.game_list}>
          {isLoading ? (
            <FinanceOverviewListSkeleton />
          ) : (
            list.map((game: FinanceOverviewGameData) => (
              <FinanceOverviewGameList
                game={game}
                checkItem={checkItem}
                onSingleCheck={onSingleCheck}
              />
            ))
          )}
        </ul>
      </section>
    </section>
  );
}
