import React, { useState, useEffect, useRef } from "react";
import styles from "./TypeFilter.module.scss";
import dropDownIcon from "../../Img/studioFilter/dropdownIcon.png";
const TypeFilter = (props) => {
  const typeRef = useRef();
  const { selectType, setSelectType } = props;
  const [showDropBox, setDropBox] = useState(false);
  const types = [
    { id: "1", type: "All" },
    { id: "2", type: "Normal" },
    { id: "3", type: "MondayOFF Party" },
  ];
  const onClickType = (type) => {
    setSelectType(type);
    setDropBox((prev) => !prev);
  };
  const activeDropBox = () => {
    setDropBox((prev) => !prev);
  };
  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (!typeRef?.current?.contains(e.target)) {
        setDropBox(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [showDropBox]);
  return (
    <div className={styles.container}>
      <div
        className={styles.selcectBox}
        id="box"
        onClick={activeDropBox}
        ref={typeRef}
      >
        <div
          className={styles.selectTypeText}
          data-content={selectType}
          id="selectType"
        >
          {selectType}
        </div>
        <img
          src={dropDownIcon}
          alt=""
          className={styles.arrow}
          id="icon"
          data={showDropBox ? "active" : ""}
        />
      </div>
      {showDropBox && (
        <div className={styles.dropBox}>
          {types?.map((t) => (
            <div
              className={styles.type}
              key={t.id}
              onClick={() => onClickType(t.type)}
              data-content={t.type}
            >
              {t.type}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TypeFilter;
