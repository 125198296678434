import React, { useEffect, useState } from "react";
import styles from "./PreviewDetail.module.scss";
import closeIcon from "../../Img/Invoice/close.png";
import logo from "../../Img/Invoice/logo.png";
import { ReactComponent as DashLine } from "../../Img/Invoice/dashLine.svg";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { deleteCookie } from "../../db/cookie";
import moment from "moment";
import { useKeyEscClose } from "../../common/escClose/escClose";
export default function PreviewDetail({ item, setIsPreviewClick, date, list }) {
  const { getBonus } = useInvoice();
  const [sumBonus, setSumBonus] = useState(0);
  const [activityDate, setActivityDate] = useState({
    year: "",
    shortMonth: "",
    pullMonth: "",
  });
  useEffect(() => {
    getBonus(item.gameId, date).then((res) => {
      if (res.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else if (res.status === 400) {
        setSumBonus(0);
      } else {
        setSumBonus(
          res?.data?.balances?.reduce((acc, cur) => acc + cur.profit, 0)
        );
      }
    });
  }, [date, getBonus, item.gameId]);
  useEffect(() => {
    let activityYear = moment(date).subtract(2, "month").format("YYYY");
    let activityShortMonth = moment(date).subtract(2, "month").format("MMM");
    let activityMonth = moment(date).subtract(2, "month").format("MMMM");
    setActivityDate({
      year: activityYear,
      shortMonth: activityShortMonth,
      pullMonth: activityMonth,
    });
  }, [date]);
  useKeyEscClose(() => {
    setIsPreviewClick(false);
  });
  return (
    <article className={styles.container}>
      <section className={styles.contents}>
        <section className={styles.top_close}>
          <p>Preview Monthly Statement</p>
          <div onClick={() => setIsPreviewClick(false)}>
            <img src={closeIcon} alt="icon" />
          </div>
        </section>
        <section className={styles.invoice_contents}>
          <header className={styles.header}>
            <h2>Monthly Statement</h2>
            <img src={logo} alt="logo" />
          </header>
          <section className={styles.company_name}>
            <p>MondayOFF Inc.</p>
          </section>
          <section className={styles.studio_info}>
            <div className={styles.studio_info_item}>
              <p>Game</p>
              <p>{item.gameName}</p>
            </div>
            <div className={styles.studio_info_item}>
              <p>Studio</p>
              <p>{list.studioName}</p>
            </div>
            <div className={styles.studio_info_issue_date}>
              <span>
                <p>Issue Date</p>
                <p>{moment(list.issueDate).format("DD MMM YYYY")}</p>
              </span>
              <p>
                {activityDate.shortMonth}. {activityDate.year} Activity
              </p>
            </div>
          </section>
          <table className={styles.month_net_revenue}>
            <thead>
              <tr>
                <th>Description</th>
                <th>total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{activityDate.pullMonth} Ad Revenue</td>
                <td>
                  {(
                    item.monthlyGrossRevenue -
                    (item.googleIap + item.appleIap)
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>{activityDate.pullMonth} IAP Revenue</td>
                <td>
                  {(item.googleIap + item.appleIap).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>Android IAP Revenue</td>
                <td>
                  {item.googleIap.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>iOS IAP Revenue</td>
                <td>
                  {item.appleIap.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>{activityDate.pullMonth} Spend</td>
                <td>
                  -{" "}
                  {item.monthlySpend.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>3rd Party Cost</td>
                <td>
                  -{" "}
                  {item.thirdPartCosts.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              {item.detailOthers?.length ? (
                item.detailOthers.map((data) => (
                  <tr>
                    <td>{data?.description}</td>
                    <td>
                      {data.profit.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
              <tr>
                <td>{activityDate.pullMonth} Net Revenue</td>
                <td>
                  {item.monthlyNetRevenue.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
          <DashLine className={styles.line} />
          <table className={styles.total_net_revenue}>
            <tbody>
              <tr>
                <td>{activityDate.pullMonth} Net Revenue</td>
                <td>
                  {item.monthlyNetRevenue.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>Carryover Amount</td>
                <td>
                  {item.carryOverAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>Total Net Revenue</td>
                <td>
                  {item.totalNetRevenue.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
          <DashLine className={styles.line} />
          <table className={styles.payable_revenue_share}>
            <tbody>
              <tr>
                <td>Revenue Share</td>
                <td>
                  {item.revenueShare.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>Recoupable Payments</td>
                <td>
                  {item.paidBonus.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>Payable Revenue Share</td>
                <td>
                  {item.payableRevenueShare.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
          <DashLine className={styles.line} />
          <section className={styles.total_detail}>
            <table className={styles.total_detail_prize}>
              <tbody>
                {item.bonuses.map((i) => (
                  <tr>
                    <td>{i.description}</td>
                    <td>
                      {i.profit.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td>Total</td>
                  <td>
                    {item.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </section>
    </article>
  );
}
