import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./AdData.module.scss";
import videoPlayIcon from "../../Img/adsData/videoPlay.png";
import dropDownIcon from "../../Img/adsData/drop_down.png";
import AdsListAnimation from "../../common/DataAnimation/DataAnimation";
const AdData = ({
  adData,
  adsetData,
  adsService,
  setITR_Tap,
  ITR_Tap,
  gameName,
  setAverageCpi,
  appId,
  setITR,
  ITR,
}) => {
  const [videoList, setVideoList] = useState([]);
  const [videoSource, setVideoSource] = useState();
  const [status, setStatus] = useState([]);
  const [adsList, setAdsList] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  useEffect(() => {
    if (adData) {
      let videoId = adData?.map((data) => data?.creative?.video_id);
      if (videoId.length) {
        adsService.getAdVideo(videoId).then((data) => setVideoList(data));
      }
    }
  }, [adData]);

  useEffect(() => {
    adsService.getAllList(appId).then((data) => setAdsList(data.data));
  }, []);

  useEffect(() => {
    setITR(
      adsList
        ?.filter((data) => {
          return data?.gameName?.includes(gameName);
        })
        ?.map((data) => {
          let itrSearch = data?.gameName?.split("_");
          return itrSearch[itrSearch.length - 1];
        })
        .reverse()
    );
  }, [adsList, gameName]);
  const onClick = (id) => {
    let video = videoList?.find((video) => id === video.id);
    setVideoSource(video);
  };

  const isSelect = (itr) => {
    return ITR_Tap === itr;
  };
  const onDropDownClick = () => {
    setShowDropDown(!showDropDown);
  };
  const onItrSelect = (e) => {
    setShowDropDown(false);
    return setITR_Tap(e.target.innerText);
  };
  useEffect(() => {
    let list = [];
    adData?.map((d) => {
      switch (d?.effective_status) {
        case "CAMPAIGN_PAUSED":
          return list.push({ name: d?.name, status: "Paused" });
        case "PENDING_REVIEW":
          return list.push({ name: d?.name, status: "Review" });
        case "IN_PROCESS":
          return list.push({ name: d?.name, status: "Active" });
        case "ACTIVE":
          return list.push({ name: d?.name, status: "Active" });
        default:
          return list.push({ name: d?.name, status: d?.effective_status });
      }
    });
    setStatus(list);
  }, [adData]);
  return (
    <div className={styles.container}>
      <>
        {adData?.length === 0 ? (
          <AdsListAnimation />
        ) : (
          <>
            <div className={styles.tapContainer}>
              <div className={styles.dataTitle}>Facebook Campaign</div>
              <div className={styles.itrDropBox} onClick={onDropDownClick}>
                <div className={styles.itrText}>{ITR_Tap}</div>
                <img src={dropDownIcon} alt="" className={styles.dropDown} />
              </div>

              {showDropDown && (
                <ul className={styles.itrList}>
                  {ITR?.map((data, idx) => (
                    <li
                      key={idx}
                      className={classNames(
                        isSelect(data) ? styles.activeITR : styles.inactiveITR
                      )}
                      onClick={(e) => onItrSelect(e)}
                    >
                      {data}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <ul className={styles.menuCategory}>
              <li>Name</li>
              <li>Video</li>
              <li>Status</li>
              <li>Impressions</li>
              <li>Installs</li>
              <li>CPI</li>
              <li>Clicks</li>
              <li>CPC</li>
              <li>CTR</li>
            </ul>

            <div className={styles.adsDataContainer}>
              {adData?.map((data, idx) => (
                <div key={idx} className={styles.dataList}>
                  <div className={styles.adsName}>{data?.name}</div>
                  <div
                    className={styles.videoPlayBtn}
                    onClick={() => onClick(data.creative.video_id)}
                  >
                    <a
                      href={videoSource?.source}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={videoPlayIcon}
                        alt=""
                        className={styles.playIcon}
                      />
                    </a>
                  </div>

                  <div className={styles.status}>
                    {status.length !== 0 &&
                      status?.find((i) => i?.name === data?.name)?.status}
                  </div>
                  <div className={styles.dataText}>
                    {data?.insights
                      ? data?.insights?.data[0]?.impressions
                      : "-"}
                  </div>

                  <div className={classNames(styles.dataText, "installs")}>
                    {data?.insights?.data[0]?.actions?.find(
                      (action) => action.action_type === "omni_app_install"
                    ) !== undefined
                      ? data?.insights?.data[0]?.actions?.find(
                          (action) => action.action_type === "omni_app_install"
                        )?.value
                      : "-"}
                  </div>
                  <div className={classNames(styles.dataText, "cpi")}>
                    {data?.insights?.data[0]?.cost_per_action_type?.find(
                      (cost) => cost.action_type === "omni_app_install"
                    ) !== undefined
                      ? `$ ${Number(
                          data?.insights?.data[0]?.cost_per_action_type?.find(
                            (cost) => cost.action_type === "omni_app_install"
                          )?.value
                        ).toFixed(2)}`
                      : "-"}
                  </div>
                  <div className={styles.dataText}>
                    {data?.insights ? data?.insights?.data[0]?.clicks : "-"}
                  </div>
                  <div className={styles.dataText}>
                    {data?.insights?.data[0]?.cpc
                      ? `$ ${Number(data?.insights?.data[0]?.cpc).toFixed(2)}`
                      : "-"}
                  </div>

                  <div className={styles.dataText}>
                    {data?.insights?.data[0]?.ctr
                      ? `${Number(data?.insights?.data[0]?.ctr).toFixed(2)}%`
                      : "-"}
                  </div>
                </div>
              ))}

              <>
                <div key={Math.random()} className={styles.adsetList}>
                  <div className={styles.adsetAdsName}>{adsetData?.name}</div>
                  <div />
                  <div key={Math.random()} className={styles.adsetStatus}></div>
                  {adsetData?.insights?.data?.map((data) => (
                    <>
                      <div key={Math.random()} className={styles.AdsetDataText}>
                        {data ? data?.impressions : "-"}
                      </div>

                      <div key={Math.random()} className={styles.AdsetDataText}>
                        {data?.actions
                          ? data?.actions?.map((action) => {
                              switch (action.action_type) {
                                case "omni_app_install":
                                  return action.value;
                                default:
                              }
                            })
                          : "-"}
                      </div>
                      <div key={Math.random()} className={styles.AdsetDataText}>
                        {data?.cost_per_action_type &&
                          // eslint-disable-next-line array-callback-return
                          data?.cost_per_action_type?.map((cost) => {
                            switch (cost.action_type) {
                              case "omni_app_install":
                                setAverageCpi(Number(cost.value).toFixed(2));
                                return `$ ${Number(cost.value).toFixed(2)}`;
                              default:
                            }
                          })}
                      </div>
                      <div key={Math.random()} className={styles.AdsetDataText}>
                        {data ? data?.clicks : "-"}
                      </div>
                      <div key={Math.random()} className={styles.AdsetDataText}>
                        {data?.cpc && `$ ${Number(data?.cpc).toFixed(2)}`}
                        {!data?.cpc && `-`}
                      </div>
                      <div key={Math.random()} className={styles.AdsetDataText}>
                        {data?.ctr ? `${Number(data?.ctr).toFixed(2)}%` : "-"}
                      </div>
                    </>
                  ))}
                </div>
              </>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default AdData;
