import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Progress.module.scss";
import Filter from "../Filter/Filter";
import AdsListAnimation from "../../common/AdsListAnimation/AdsListAmination";
import emptyBox from "../../Img/adsData/emptybox.png";
import TypeFilter from "../TypeFilter/TypeFilter";
import { useGameList } from "../../Context/GameListContext";
const Progress = () => {
  const {
    adsData,
    progressAdsList,
    progressTypeList,
    progressStudioList,
    limitError,
    isLoading,
  } = useGameList();
  const [searchValue, setSearchValue] = useState("");

  const searchTypeList = [
    {
      id: 1,
      type: "Game",
    },
    {
      id: 2,
      type: "Studio",
    },
  ];
  const [searchType, setSearchType] = useState({
    id: 1,
    type: "Game",
  });
  const [selectType, setSelectType] = useState("All");

  const gameStatus = (status) => {
    switch (status?.effective_status) {
      case "ACTIVE":
        return (
          <div className={styles.activeStatus}>
            <div>Active</div>
          </div>
        );
      case "PAUSED":
        return (
          <div className={styles.completeStatus}>
            <div>Complete</div>
          </div>
        );
      default:
        return (
          <div className={styles.status}>
            <div>Inactive</div>
          </div>
        );
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <Filter
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          studioList={progressStudioList}
          searchType={searchType}
          searchTypeList={searchTypeList}
          setSearchType={setSearchType}
          adsList={progressAdsList}
        />
        <TypeFilter
          typeList={progressTypeList}
          selectType={selectType}
          setSelectType={setSelectType}
        />
      </div>

      <div className={styles.dataBox}>
        <ul className={styles.items}>
          <li>Game</li>
          <li>CPI</li>
          <li>Studio</li>
          <li>Status</li>
          <li>type</li>
          <li>Upload</li>
          <li>End Date</li>
        </ul>
        {isLoading ? (
          <AdsListAnimation />
        ) : limitError.status === 500 ? (
          <div className={styles.error}>
            <img src={emptyBox} alt="img" />
            <p>{limitError.message}</p>
          </div>
        ) : (
          <div className={styles.dataScroll}>
            {progressAdsList
              ?.filter((data) => {
                if (searchType.id === 1) {
                  if (searchValue === "") {
                    return data;
                  } else if (
                    data?.gameName
                      .toLowerCase()
                      .replace(/(\s*)/g, "")
                      .includes(
                        searchValue.replace(/(\s*)/g, "").toLowerCase()
                      ) ||
                    data?.gameName
                      .toUpperCase()
                      .replace(/(\s*)/g, "")
                      .includes(searchValue.replace(/(\s*)/g, "").toUpperCase())
                  ) {
                    return data;
                  }
                } else {
                  if (searchValue === "All Studios") {
                    return data;
                  } else if (
                    data?.studioName
                      .toLowerCase()
                      .replace(/(\s*)/g, "")
                      .includes(
                        searchValue.replace(/(\s*)/g, "").toLowerCase()
                      ) ||
                    data?.studioName
                      .toUpperCase()
                      .replace(/(\s*)/g, "")
                      .includes(searchValue.replace(/(\s*)/g, "").toUpperCase())
                  ) {
                    return data;
                  }
                }
              })
              .filter((data) => {
                if (selectType === "All") {
                  return data;
                } else if (
                  data?.type
                    .toLowerCase()
                    .replace(/(\s*)/g, "")
                    .includes(selectType.replace(/(\s*)/g, "").toLowerCase()) ||
                  data?.type
                    .toUpperCase()
                    .replace(/(\s*)/g, "")
                    .includes(selectType.replace(/(\s*)/g, "").toUpperCase())
                ) {
                  return data;
                }
              })
              .map((data) => {
                let ads = adsData?.filter((ad) =>
                  ad?.name.split("_").includes(data?.studioName)
                );

                let status = ads?.find((ad) =>
                  ad?.name?.includes(data?.gameName)
                );
                let CPI;
                status?.adsets?.data?.find((data) =>
                  data.insights?.data.find(
                    (d) =>
                      (CPI = d?.cost_per_action_type?.find(
                        (action) => action?.action_type === "omni_app_install"
                      ))
                  )
                );

                let gameName = data?.gameName;

                return (
                  <Link
                    key={data?.id}
                    to={`/test/progress/${data.id}`}
                    state={{ data: data }}
                  >
                    <ul className={styles.dataList}>
                      <li className={styles.game}>
                        <img
                          src={data?.gameIcon}
                          alt=""
                          className={styles.gameIcon}
                        />
                        <div className={styles.gameName}>{gameName}</div>
                      </li>
                      <li className={styles.CPI}>
                        {!CPI?.value
                          ? "$0.00"
                          : Number(CPI?.value).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                      </li>
                      <li className={styles.studioName}>{data?.studioName}</li>
                      <li>{gameStatus(status)}</li>
                      <li className={styles.type} data-content={data?.type}>
                        {data?.type}
                      </li>
                      <li className={styles.createdAt}>
                        {moment(data?.createdAt).format("YYYY. MM. DD")}
                      </li>
                      <li className={styles.createdAt}>
                        {moment(data.endTime).format("YYYY. MM. DD")}
                      </li>
                    </ul>
                  </Link>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Progress);

/*
?.map((d) => {
                    let prize =
                      d?.cost_per_action_type &&
                      d?.cost_per_action_type?.find(
                        (action) => action?.action_type === "omni_app_install"
                      )?.value;

                    return !isNaN(prize)
                      ? prize.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : "$0.00";
                  })

*/
