import React from "react";
import styles from "./Modal.module.css";
import icon from "../../Img/alertModal/icon.png";
const Modal = ({ setShowModal, gameName, onClick, onChange }) => {
  const cancelClick = () => {
    setShowModal(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <img src={icon} alt="" className={styles.icon} />
        <div className={styles.title}>Publishing</div>
        <div className={styles.alertMessage}>{gameName}</div>
        <div className={styles.inputBox}>
          <div className={styles.text}>*Input Store Id*</div>
          <div>
            <span>ID: </span>
            <input
              type="text"
              onChange={onChange}
              className={styles.storeIdInput}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.closeBtn} onClick={onClick}>
            Ok
          </button>
          <button className={styles.cancelBtn} onClick={cancelClick}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Modal);
