import { ChangeEvent, useState } from "react";
import { useEffect } from "react";
import { ReactComponent as CheckSvgIcon } from "../../Img/FinaceOverview/check.svg";
import { ReactComponent as ExpandIcon } from "../../Img/FinaceOverview/expand.svg";
import FinanceOverviewGameDetail from "../FinanceOverviewGameDetail/FinanceOverviewGameDetail";

import styles from "./FinanceOverviewGameList.module.scss";
import CheckIcon from "../../common/CheckIcon/CheckIcon";
export default function FinanceOverviewGameList({
  game,
  checkItem,
  onSingleCheck,
}: {
  game: FinanceOverviewGameData;
  checkItem: number[];
  onSingleCheck: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
}) {
  const [isExpandClick, setIsExpandClick] = useState(false);
  const [isGameIcon, setIsGameIcon] = useState(false);
  useEffect(() => {
    CheckIcon(game.gameIconUrl).then((res) => setIsGameIcon(res));
  }, [game.gameIconUrl]);
  return (
    <>
      <li className={styles.container}>
        <span className={styles.check}>
          <input
            className={styles.game_click_input}
            type="checkbox"
            id={`${game.gameName}_check`}
            onChange={(e) => onSingleCheck(e, game.everyDayGameId)}
            checked={checkItem.includes(game.everyDayGameId) ? true : false}
          />
          <label
            className={styles.check_box}
            htmlFor={`${game.gameName}_check`}
          >
            <CheckSvgIcon className={styles.icon} />
          </label>
        </span>
        <span className={styles.name}>
          <ExpandIcon
            className={styles.icon}
            onClick={() => setIsExpandClick((prev) => !prev)}
            data-content={isExpandClick && "active"}
          />
          {isGameIcon ? (
            <img
              className={styles.game_img}
              src={game.gameIconUrl}
              alt="icon"
            />
          ) : (
            <div className={styles.game_img} />
          )}
          <p>{game.gameName}</p>
        </span>
        <p className={styles.game_total_revenue}>
          {game.totalRevenue.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_iap}>
          {game.totalIAP.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_spend}>
          {game.totalSpend.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_profit}>
          {game.totalProfit.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.game_total_install}>
          {game.totalInstalls.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </li>
      {isExpandClick && (
        <ul className={styles.game_detail}>
          <FinanceOverviewGameDetail android={game.ANDROID} ios={game.IOS} />
        </ul>
      )}
    </>
  );
}
