import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
} from "react";

const MaxContext = createContext(
  {} as {
    getNewMaxData: (id: number) => Promise<void>;
    getMaxData: (id: string) => Promise<void>;
  }
);
const contextRef = createRef();
export function MaxProvider({
  maxService,
  children,
}: {
  maxService: any;
  children: any;
}) {
  const getNewMaxData = useCallback(
    (id: number) => {
      return maxService.getNewMaxData(id).then((res: any) => {
        return res.data;
      });
    },
    [maxService]
  );
  const getMaxData = useCallback(
    (id: string) => {
      return maxService.getMaxData(id).then((res: any) => {
        return res;
      });
    },
    [maxService]
  );
  const context = useMemo(
    () => ({ getNewMaxData, getMaxData }),
    [getNewMaxData, getMaxData]
  );

  return <MaxContext.Provider value={context}>{children}</MaxContext.Provider>;
}

export default MaxProvider;
export const fetchToken = () => contextRef.current;
export const useMaxData = () => useContext(MaxContext);
