import React from "react";
import styles from "./AppInfoData.module.scss";
export default function Game({
  onChange,
  gameData,
  appInfo,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  gameData: GameData;
  appInfo: RequestAppInfoData;
}) {
  const { ownerId, gameKey, unityProjectId, metaClientToken, status } =
    gameData;

  return (
    <div className={styles.container}>
      <div>
        <h3>Game Key</h3>
        <input
          type="text"
          onChange={onChange}
          name="gameKey"
          placeholder={
            appInfo.gameKey === undefined ? ownerId : appInfo.gameKey.toString()
          }
          value={
            appInfo.gameKey === undefined
              ? gameKey ?? ""
              : appInfo.gameKey.toString()
          }
        />
      </div>
      <div>
        <h3>Owner Id</h3>
        <input
          type="text"
          onChange={onChange}
          name="ownerId"
          placeholder={
            appInfo.ownerId === undefined ? ownerId : appInfo.ownerId.toString()
          }
          value={
            appInfo.ownerId === undefined
              ? ownerId ?? ""
              : appInfo.ownerId.toString()
          }
        />
      </div>
      <div>
        <h3>unityProjectId</h3>
        <input
          type="text"
          onChange={onChange}
          name="unityProjectId"
          placeholder={
            appInfo?.unityProjectId === undefined
              ? unityProjectId
              : appInfo?.unityProjectId
          }
          value={
            appInfo?.unityProjectId === undefined
              ? unityProjectId ?? ""
              : appInfo?.unityProjectId
          }
        />
      </div>
      <div>
        <h3>metaClientToken</h3>
        <input
          type="text"
          onChange={onChange}
          name="metaClientToken"
          placeholder={
            appInfo.metaClientToken === undefined
              ? metaClientToken
              : appInfo.metaClientToken
          }
          value={
            appInfo.metaClientToken === undefined
              ? metaClientToken ?? ""
              : appInfo.metaClientToken
          }
        />
      </div>
      <div>
        <h3>Status</h3>
        <input
          type="text"
          onChange={onChange}
          name="status"
          placeholder={appInfo.status === undefined ? status : appInfo.status}
          value={appInfo.status === undefined ? status ?? "" : appInfo.status}
        />
      </div>
    </div>
  );
}
