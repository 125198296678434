import React from "react";
import styles from "./InvoiceTopMore.module.scss";
import { ReactComponent as MorePlusIcon } from "../../Img/Invoice/more_plus.svg";
import { ReactComponent as EditIcon } from "../../Img/Invoice/edit.svg";
function InvoiceTopMore({
  setIsAddInvoiceClick,
  setIsMoreClick,
  moreRef,
  setIsEditRateInfoClick,
  isAddRateInfo,
}) {
  const onAddInvoiceClick = () => {
    setIsAddInvoiceClick(true);
    setIsMoreClick(false);
  };
  const onEditRateClick = () => {
    setIsEditRateInfoClick(true);
    setIsMoreClick(false);
  };
  return (
    <section className={styles.container} ref={moreRef}>
      <button onClick={onAddInvoiceClick}>
        <MorePlusIcon className={styles.add_invoice_icon} />
        <p>Add Invoice</p>
      </button>
      {isAddRateInfo && (
        <button onClick={onEditRateClick}>
          <EditIcon className={styles.rate_edit_icon} />
          <p>Edit Exchange Rate </p>
        </button>
      )}
    </section>
  );
}

export default InvoiceTopMore;
