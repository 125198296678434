import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./Login.module.scss";
import * as cookie from "../../db/cookie.js";
import logo from "../../Img/Login/logo.png";
import Crypto from "crypto-js";
const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [saveId, setSaveId] = useState(true);

  var emailRule =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식
  const expires = moment().add("7", "day").toDate();
  const onSubmit = (event) => {
    event.preventDefault();

    if (!emailRule.test(email)) {
      alert("Your email check please");
    } else if (password.length < 5) {
      alert("Your password check please");
    } else {
      if (saveId) {
        let cipherPassword = Crypto.AES.encrypt(
          password,
          "admin_passowrd"
        ).toString();

        cookie.setCookie("email", email, { expires });
        cookie.setCookie("password", cipherPassword, {
          expires,
          secure: true,
          sameSite: "none",
        });
        cookie.setCookie("saveId", saveId, { expires });
      } else if (saveId === false) {
        cookie.deleteCookie("email");
        cookie.deleteCookie("password");
        cookie.deleteCookie("saveId");
      }

      onLogin(email, password);
    }
  };
  useEffect(() => {
    if (cookie.getCookie("email") && cookie.getCookie("password")) {
      let bytes = Crypto.AES.decrypt(
        cookie.getCookie("password"),
        "admin_passowrd"
      );
      let originalText = bytes.toString(Crypto.enc.Utf8);
      setEmail(cookie.getCookie("email"));
      setPassword(originalText);
    }
  }, []);

  const onChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    switch (name) {
      case "email":
        return setEmail(value);
      case "password":
        return setPassword(value);
      case "saveId":
        return setSaveId(checked);
      default:
    }
  };
  return (
    <div className={styles.container}>
      <form className={styles.box} onSubmit={onSubmit}>
        <div>
          <img src={logo} alt="" className={styles.logo} />
        </div>

        <div className={styles.emailBox}>
          <div className={styles.inputName}>Email</div>
          <input
            name="email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={onChange}
            required
            className={styles.email}
          />
        </div>
        <div className={styles.passwordBox}>
          <div className={styles.inputName}>Password</div>
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={onChange}
            className={styles.password}
            autoComplete="on"
          />
        </div>
        <div className={styles.saveEmail}>
          <input
            id="remember"
            type="checkbox"
            name="saveId"
            checked={saveId}
            onChange={onChange}
          />
          <label htmlFor="remember" className={styles.custom}>
            <span></span>
            <div>Remember me</div>
          </label>
        </div>
        <button type="submit" className={styles.loginBtn}>
          Sign In
        </button>
      </form>
    </div>
  );
};

export default Login;
