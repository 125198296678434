import React, {
  createContext,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const ChartContext = createContext({} as ChartContextProps);
const contextRef = createRef();
export function ChartProvider({
  adsService,
  children,
}: {
  adsService: AdServiceList;
  children: React.ReactNode;
}) {
  const [chartData, setChartData] = useState([] as Array<ChartData>);
  const list = async () => {
    return await adsService.getChartData().then((res: Array<ChartData>) => {
      return setChartData(res);
    });
  };
  useEffect(() => {
    list();
  }, []);
  const context = useMemo(
    () => ({
      chartData,
    }),
    [chartData]
  );
  return (
    <ChartContext.Provider value={context}>{children}</ChartContext.Provider>
  );
}

export default ChartContext;
export const fetchToken = () => contextRef.current;
export const useChart = () => useContext(ChartContext);
