import React from "react";
import styles from "./IosAdnetwork.module.css";
import iconList from "../../data/adNetworkIcon";

const IosAdnetwork = ({ adNetworkData }: { adNetworkData: IOSAdnetwork }) => {
  return (
    <>
      {adNetworkData.networks.map((item) => (
        <tr className={styles.DataBox}>
          <td className={styles.adsName}>
            <img
              src={
                iconList.find((list) => list.name === item.network)?.icon ??
                iconList.find((list) => list.name === "None")?.icon
              }
              alt=""
              className={styles.firstAdsflatform}
            />

            <div>{item?.network}</div>
          </td>
          <td>
            {(item?.spend).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </td>
          <td>
            {(item?.revenue).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </td>
          <td>
            {item?.profit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </td>
          <td></td>
        </tr>
      ))}
    </>
  );
};

export default React.memo(IosAdnetwork);
