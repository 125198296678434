import React, { useRef, useState, useEffect } from "react";
import styles from "./AddInvoiceItem.module.scss";

import { ReactComponent as ExpandIcon } from "../../Img/Invoice/expand.svg";
import { ReactComponent as CloseIcon } from "../../Img/Invoice/close.svg";
import { ReactComponent as CheckIcon } from "../../Img/Invoice/check_black.svg";
import { ReactComponent as DollarIcon } from "../../Img/Invoice/dollar.svg";
export default function AddInvoiceItem({ list, setInvoiceItem }) {
  const [typeClick, setTypeClick] = useState(false);
  const [isPrizeClick, setIsPrizeClick] = useState(false);
  const itemRef = useRef(null);
  const typeRef = useRef(null);
  const typeList = [
    {
      id: 1,
      type: "Deductible",
    },
    {
      id: 2,
      type: "Non-Deductible",
    },
  ];
  const prizeRef = useRef(null);
  const onClick = (id) => {
    setInvoiceItem((prev) => {
      return prev.map((data) =>
        data.id === list.id ? { ...data, type: id } : data
      );
    });
  };

  const deleteClick = (list) => {
    setInvoiceItem((prev) => {
      return prev.filter((data) => data.id !== list?.id);
    });
  };
  const isTypeSelect = (id) => {
    return list.type === id;
  };
  const onChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "item":
        setInvoiceItem((prev) => {
          return prev.map((data) =>
            data.id === list.id ? { ...data, item: value } : data
          );
        });
        return;
      case "prize":
        setInvoiceItem((prev) => {
          return prev.map((data) =>
            data.id === list.id ? { ...data, prize: Number(value) } : data
          );
        });
        return;
      default:
        return;
    }
  };
  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (typeRef?.current && !typeRef?.current?.contains(e.target)) {
        setTypeClick((prev) => !prev);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [setTypeClick]);

  useEffect(() => {
    let handleInputClick = (e) => {
      if (prizeRef?.current?.contains(e.target)) {
        setIsPrizeClick(true);
      } else {
        setIsPrizeClick(false);
      }
    };
    document.addEventListener("mousedown", handleInputClick);
    return () => document.removeEventListener("mousedown", handleInputClick);
  }, [setTypeClick]);
  const onTypeClick = () => {
    setTypeClick((prev) => !prev);
  };

  return (
    <li className={styles.container}>
      <div className={styles.type_select} onClick={onTypeClick}>
        <p>{typeList?.find((item) => item.id === list.type)?.type}</p>
        <ExpandIcon
          className={styles.expand_icon}
          data-content={typeClick && "active"}
        />
        {typeClick && (
          <ul className={styles.invoice_type_list} ref={typeRef}>
            {typeList?.map((list) => {
              return (
                <>
                  <li
                    data-content={isTypeSelect(list.id) && "active"}
                    onClick={() => onClick(list?.id)}
                    key={list.id}
                  >
                    <p>{list.type}</p>
                    {isTypeSelect(list.id) && <CheckIcon />}
                  </li>
                </>
              );
            })}
          </ul>
        )}
      </div>

      <input
        type="text"
        onChange={onChange}
        id="item"
        ref={itemRef}
        value={list?.item}
        className={styles.invoice_item}
      />

      <label
        className={styles.invoice_prize}
        ref={prizeRef}
        data-content={isPrizeClick ? "active" : "inactive"}
      >
        <DollarIcon />
        <input
          type="text"
          onChange={onChange}
          id="prize"
          placeholder="0"
          value={list?.prize}
        />
      </label>
      <CloseIcon
        className={styles.delete_button}
        onClick={() => deleteClick(list)}
      />
    </li>
  );
}
