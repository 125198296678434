import styles from "./Home.module.scss";
import Chart from "../../components/Chart/Chart";
import CompareSubmission from "../../components/CompareSubmission/CompareSubmission";
import { useAuth } from "../../Context/AuthContext";

export default function Home() {
  const { user } = useAuth();

  return (
    <main className={styles.container}>
      <h1>Hello, {user?.data?.name ?? "MondayOFF"}!👋🏻</h1>
      <CompareSubmission />
      <Chart />
    </main>
  );
}
