import React from "react";
import styles from "./StudioProfile.module.scss";
const StudioProfile = ({ studioData }) => {
  return (
    <div className={styles.container}>
      <img src={studioData?.profileImg} alt="" className={styles.profileImg} />
      <div className={styles.studioName}>{studioData?.studioName}</div>
      <ul className={styles.studioInfo}>
        <li className={styles.teamSizeBox}>
          <div className={styles.titleText}>Studio Size</div>
          <div className={styles.teamSize}>{studioData?.teamSize}</div>
        </li>
        <li className={styles.emailBox}>
          <div className={styles.titleText}>Email</div>
          <a
            href={`mailto:${studioData?.email}`}
            target="_blank"
            className={styles.email}
            rel="noreferrer"
          >
            {studioData?.email}
          </a>
        </li>
        <li className={styles.portfolioLinkBox}>
          <div className={styles.titleText}>Portfolio</div>
          <a
            href={studioData?.portfolioLink}
            target="_blank"
            className={styles.portfolioLink}
            rel="noreferrer"
          >
            {studioData?.portfolioLink}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default StudioProfile;
