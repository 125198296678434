import { useEffect, useRef, useState } from "react";
import styles from "./InvoiceData.module.scss";

import AddInvoiceModal from "../AddInvoiceModal/AddInvoiceModal";
import { ReactComponent as PlusIcon } from "../../Img/Invoice/plus.svg";
import { ReactComponent as MoreIcon } from "../../Img/Invoice/more.svg";
import { ReactComponent as CheckIcon } from "../../Img/Invoice/check.svg";

import emptyBox from "../../Img/Invoice/emptybox.png";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import InvoiceList from "../InvoiceList/InvoiceList";
import InvoiceListLoading from "../../common/InvoiceListLoading/InvoiceListLoading";

import AddRateInfo from "../AddRateInfo/AddRateInfo";
import InvoiceTopMore from "../InvoiceTopMore/InvoiceTopMore";

import EditRateInfo from "../EditRateInfo/EditRateInfo";
import { useAuth } from "../../Context/AuthContext";
import Cookies from "js-cookie";

export default function InvoiceData({
  date,
  isLoading,
  setIsLoading,
  setTotalList,
  totalList,
}) {
  const { invoiceUser } = useAuth();
  const { getList, setInvoiceList, invoiceList, getRateInfo } = useInvoice();

  const [dataUpdating, setDateUpdating] = useState(false);
  const [isMoreClick, setIsMoreClick] = useState(false);
  const [isAddInvoiceClick, setIsAddInvoiceClick] = useState(false);
  const [rateInfo, setRateInfo] = useState({});
  const [isAddRateInfo, setIsAddRateInfo] = useState(false);
  const [isAddRateInfoClick, setIsAddRateInfoClick] = useState(false);
  const [isEditRateInfoClick, setIsEditRateInfoClick] = useState(false);

  const moreRef = useRef(null);

  useEffect(() => {
    if (Cookies.get("Authentication")) {
      setIsLoading(true);
      return getList(date).then((data) => {
        if (data.status === 401) {
          Cookies.remove("Authentication");
          if (!Cookies.get("Authentication")) {
            window.location.reload();
          }
        } else if (data.status === 400) {
          alert(data.data.message);
        } else {
          setIsLoading(false);
          setInvoiceList(data?.data.list);
        }
      });
    }
  }, [date]);

  useEffect(() => {
    getRateInfo(date).then((res) => {
      if (res.status === 200) {
        setRateInfo(res.data);
      }
    });
  }, [date]);

  useEffect(() => {
    !rateInfo.googleUARate && !rateInfo.googleIAPRate && !rateInfo.appleIAPRate
      ? setIsAddRateInfo(false)
      : setIsAddRateInfo(true);
  }, [rateInfo]);

  useEffect(() => {
    setDateUpdating(true);

    const dataUpdate = async () => {
      let total = [];
      if (invoiceList.length) {
        let clData = await invoiceList
          .filter((item) => item.clGameCount)
          .sort((a, b) => {
            if (
              a.detailGames.reduce((acc, cur) => acc + cur.amount, 0) >
              b.detailGames.reduce((acc, cur) => acc + cur.amount, 0)
            ) {
              return -1;
            } else {
              return 0;
            }
          });

        let slData = await invoiceList
          .filter((item) => !item.clGameCount)
          .sort((a, b) => {
            if (
              a.detailGames.reduce((acc, cur) => acc + cur.amount, 0) >
              b.detailGames.reduce((acc, cur) => acc + cur.amount, 0)
            ) {
              return -1;
            } else {
              return 0;
            }
          });
        total.push(...clData, ...slData);
        total.sort((a, b) => {
          if (
            a.detailGames.reduce((acc, cur) => acc + cur.amount, 0) >
            b.detailGames.reduce((acc, cur) => acc + cur.amount, 0)
          ) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      setTotalList(total);
      setDateUpdating(false);
    };
    dataUpdate();
  }, [invoiceList]);

  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (!moreRef?.current?.contains(e.target)) {
        setIsMoreClick(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  return (
    <>
      <div className={styles.container}>
        {isAddInvoiceClick && (
          <AddInvoiceModal
            setIsAddInvoiceClick={setIsAddInvoiceClick}
            date={date}
          />
        )}
        {isAddRateInfoClick && (
          <AddRateInfo
            date={date}
            setIsAddRateInfoClick={setIsAddRateInfoClick}
          />
        )}
        {isEditRateInfoClick && (
          <EditRateInfo
            setIsEditRateInfoClick={setIsEditRateInfoClick}
            date={date}
            rateInfo={rateInfo}
          />
        )}
        <div className={styles.data_title}>
          <h2>Studio List</h2>
          <span className={styles.buttons}>
            {isMoreClick && (
              <InvoiceTopMore
                setIsAddInvoiceClick={setIsAddInvoiceClick}
                setIsMoreClick={setIsMoreClick}
                moreRef={moreRef}
                setIsEditRateInfoClick={setIsEditRateInfoClick}
                isAddRateInfo={isAddRateInfo}
              />
            )}
            {invoiceUser.role === "SUPER" ? (
              !isAddRateInfo ? (
                invoiceList.length ? (
                  <>
                    <button
                      onClick={() => setIsAddRateInfoClick(true)}
                      className={styles.rate_btn}
                    >
                      <PlusIcon className={styles.plus} />
                      <p>Exchange Rate</p>
                    </button>
                    <MoreIcon
                      className={styles.more}
                      onClick={() => setIsMoreClick((prev) => !prev)}
                    />
                  </>
                ) : (
                  <>
                    <button
                      className={styles.rate_invalid}
                      data-content="inactive"
                    >
                      <PlusIcon className={styles.invalid_plus} />
                      <p>Exchange Rate</p>
                    </button>
                    <MoreIcon
                      className={styles.more}
                      onClick={() => setIsMoreClick((prev) => !prev)}
                    />
                  </>
                )
              ) : (
                <>
                  <button className={styles.rate_added} data-content="active">
                    <CheckIcon className={styles.check} />
                    <p>Exchange Rate</p>
                  </button>
                  <MoreIcon
                    className={styles.more}
                    onClick={() => setIsMoreClick((prev) => !prev)}
                  />
                </>
              )
            ) : (
              <button
                onClick={() => setIsAddInvoiceClick(true)}
                className={styles.add_invoice_btn}
              >
                <PlusIcon className={styles.plus} />
                <p>Add Invoice</p>
              </button>
            )}
          </span>
        </div>

        <div className={styles.data_area}>
          <ul className={styles.data_header}>
            <li>Name</li>
            <li>Game Status</li>
            <li>Payment Infomation</li>
            <li>Amount</li>
            <li>Issue Date</li>
            <li>Invoice Status</li>
            <li>Action</li>
          </ul>
          <ul className={styles.data_list}>
            {isLoading ? (
              <InvoiceListLoading />
            ) : invoiceList.length ? (
              !dataUpdating &&
              totalList?.map((item, idx) => (
                <InvoiceList
                  key={Math.random(idx)}
                  list={item}
                  date={date}
                  rateInfo={rateInfo}
                  isAddRateInfo={isAddRateInfo}
                />
              ))
            ) : (
              <div className={styles.empty_box}>
                <img src={emptyBox} alt="" />
                <p>No invoice information has been found.</p>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
