import Cookies from "js-cookie";

export const setCookie = (cookieName, value, option) => {
  return Cookies.set(cookieName, value, { ...option });
};
export const getCookie = (cookieName) => {
  return Cookies.get(cookieName);
};
export const deleteCookie = (cookieName) => {
  return Cookies.remove(cookieName);
};
