export default function inputNumberFormat(prize) {
  let regexp = /^(\d{0,10}([.]\d{0,2})?)?$/;
  if (!regexp.test(prize)) {
    alert("소수점 둘째자리까지 입력가능합니다.");
    return;
  } else {
    return comma(uncomma(prize));
  }
}

function comma(str) {
  str = String(str);
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
}

function uncomma(str) {
  str = String(str);
  return str.replace(/[^\d]+/g, "");
}
