import React from "react";
import InvoiceForm from "../InvoiceForm/InvoiceForm";
import styles from "./PreviewInvoice.module.scss";
import closeIcon from "../../Img/Invoice/close.png";

export default function PreviewInvoice({
  setIsPreviewInvoiceClick,
  date,
  list,
}) {
  return (
    <section className={styles.container}>
      <section className={styles.contents}>
        <header>
          <p>Check Invoice</p>
          <div>
            <img
              src={closeIcon}
              alt="icon"
              onClick={() => setIsPreviewInvoiceClick(false)}
            />
          </div>
        </header>
        <InvoiceForm date={date} list={list} />
      </section>
    </section>
  );
}
