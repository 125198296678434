import axios from "axios";

export default class AdsService {
  http: any;
  tokenStorage: string;
  constructor(http: any, tokenStorage: string) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }
  async getAllList(appId: string) {
    let data = await this.http
      .axios(`/adslist/${appId}`, {
        method: "GET",
      })
      .then((res: ITRList) => {
        return res;
      })
      .catch(console.error);
    return data;
  }
  async getChartData() {
    return await axios("https://server.mondayoff.me/adslist/all").then(
      (res) => res?.data
    );
  }
  async getList() {
    let data = await this.http
      .axios(`/campaign`, {
        method: "GET",
      })
      .then((res: AdsList) => {
        return res;
      })
      .catch(console.error);

    return data;
  }
  async getProgressList() {
    let data = await this.http
      .axios(`/campaign/progress`, {
        method: "GET",
      })
      .then((res: AdsList) => res)
      .catch(console.error);

    return data;
  }
  async getCompleteList(page: number) {
    let data = await this.http
      .axios(`/campaign/complete?page=${page}`, {
        method: "GET",
      })
      .then((res: AdsList) => res)
      .catch(console.error);

    return data;
  }
  async getAds(appId: string, gameName: string) {
    return await this.http
      .axios(
        `/meta/getAdminAds?appId=${appId}&gameName=${gameName
          ?.replace(/#/g, "%23")
          .replace(/&/g, "26")}`,
        {
          method: "GET",
        }
      )
      .then((res: CampaignData) => {
        return res.data;
      })
      .catch(console.error);
  }
  async getAllAds() {
    return await this.http
      .axios(`/meta/adminads`, {
        method: "GET",
      })
      .then((data: any) => data)
      .catch((e: any) => e.response);
  }
  async getAdset(adsetId: string, appId: string) {
    return await this.http
      .axios(`/meta/adset?adset=${adsetId}&appId=${appId}`, { method: "GET" })
      .then((res: any) => res.data)
      .catch(console.error);
  }
  async getAdVideo(adVideoId: string) {
    return await this.http
      .axios(`/meta?video_id=${adVideoId}`, { method: "GET" })
      .then((res: any) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return null;
        }
      });
  }
  async deleteAd(appId: string, gameName: string) {
    return await this.http
      .axios(`/campaign/delete`, {
        method: "DELETE",
        data: { appId, gameName },
      })
      .then((res: any) => {
        if (res.status === 200) {
          return;
        } else {
          return null;
        }
      });
  }
  async getGoogleUrl(appId: string) {
    return await axios(
      `https://graph.facebook.com/v18.0/${appId}?fields=name,object_store_urls,photo_url,icon_url`
    ).then((res) => res.data);
  }
}
