import React, { useEffect, useRef, useState } from "react";
import styles from "./Filter.module.scss";
import glass from "../../Img/search/glassIcon.png";
import xIcon from "../../Img/search/xIcon.png";
const Filter = ({
  searchValue,
  setSearchValue,
  searchTypeList,
  searchType,
  setSearchType,
  studioList,
}) => {
  const searchInputRef = useRef();
  const dropDownRef = useRef();

  const [isStudioListDropDown, setIsStudioListDropDown] = useState(false);
  const [studios, setStudios] = useState([]);
  const [alphabetSort, setAlphabetSort] = useState([]);

  const onChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onDeleteClick = () => {
    let searchBoxText = document.getElementsByClassName(styles.searchBox);
    setSearchValue("");
    searchBoxText[0].value = "";
  };

  const onTypeClick = (item) => {
    setSearchType(item);
  };

  const isSelect = (item) => {
    return searchType?.id === item?.id;
  };

  useEffect(() => {
    if (searchType?.id === 2 && searchInputRef?.current?.value?.length === 0) {
      setIsStudioListDropDown(true);
      document.addEventListener("mousedown", clickOutSide);
      return () => {
        document.removeEventListener("mousedown", clickOutSide);
      };
    } else {
      setIsStudioListDropDown(false);
      document.addEventListener("mousedown", clickOutSide);
      return () => {
        document.removeEventListener("mousedown", clickOutSide);
      };
    }
  }, [searchType?.id, searchValue]);

  useEffect(() => {
    setStudios(
      studioList?.filter((data, index) => {
        return studioList?.indexOf(data) === index;
      })
    );
  }, [studioList]);

  useEffect(() => {
    let list = [];
    studios?.map((data) => list?.push(data[0] ?? ""));
    list = list?.filter((d, idx) => {
      return list?.indexOf(d.toUpperCase()) === idx;
    });
    setAlphabetSort(list?.map((a) => a?.toUpperCase())?.sort());
  }, [studios]);

  const onStudioClick = (name) => {
    setIsStudioListDropDown(false);
    let searchBoxText = document.getElementsByClassName(styles.searchBox);
    setSearchValue(name);
    searchBoxText[0].value = name;
  };
  const clickOutSide = (e) => {
    if (
      !dropDownRef?.current?.contains(e.target) &&
      !searchInputRef?.current?.contains(e.target)
    ) {
      setIsStudioListDropDown(false);
    } else if (
      searchType?.id === 2 &&
      searchInputRef?.current?.contains(e.target)
    ) {
      setIsStudioListDropDown(true);
    }
  };

  return (
    <div className={styles.container}>
      {isStudioListDropDown && (
        <ul className={styles.studioDropDown} ref={dropDownRef}>
          <li className={styles.typeTitle}>studio List</li>
          {alphabetSort?.map((d, key) => {
            let filter = studios?.filter(
              (data) => data[0]?.toUpperCase() === d?.toUpperCase()
            );

            return (
              <div key={key} className={styles.sortList}>
                <div className={styles.upperCase}>{d?.toUpperCase()}</div>
                {filter?.map((name, idx) => (
                  <div className={styles.studioNameBox}>
                    <div
                      className={styles.studioName}
                      onClick={() => onStudioClick(name)}
                      key={idx}
                    >
                      {name}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </ul>
      )}
      <div className={styles.searchArea}>
        <img className={styles.glassIcon} src={glass} alt="glass" />

        <div className={styles.inputBlock}>
          <input
            className={styles.searchBox}
            placeholder="search"
            onChange={onChange}
            ref={searchInputRef}
          />
        </div>
        {searchInputRef?.current?.value?.length > 0 && (
          <img
            className={styles.xIcon}
            src={xIcon}
            alt=""
            onClick={onDeleteClick}
          />
        )}
      </div>
      <div className={styles.searchSelectArea}>
        {searchTypeList?.map((item) => (
          <>
            <div
              className={
                isSelect(item) ? styles.activeType : styles.inactiveType
              }
              onClick={() => onTypeClick(item)}
              key={Math.random()}
            >
              {item?.type}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default React.memo(Filter);
