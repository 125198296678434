import React, { useEffect, useRef, useState } from "react";
import styles from "./ModifiedInvoiceItem.module.scss";

import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { ReactComponent as ExpandIcon } from "../../Img/Invoice/expand.svg";
import { ReactComponent as CloseIcon } from "../../Img/Invoice/close.svg";

import { ReactComponent as CheckIcon } from "../../Img/Invoice/check_black.svg";
import { deleteCookie } from "../../db/cookie";
import Cookies from "js-cookie";
export default function ModifiedInvoiceItem({
  list,
  item,
  setInvoiceItem,
  date,
  isDeleteClick,
  setIsDeleteClick,
}) {
  const { deleteBonus, testDeleteBonus } = useInvoice();
  const [typeClick, setTypeClick] = useState(false);
  const [isPrizeClick, setIsPrizeClick] = useState(false);
  const typeRef = useRef(null);
  const itemRef = useRef(null);
  const prizeRef = useRef(null);
  const typeList = [
    {
      id: 1,
      type: "Deductible",
    },
    {
      id: 2,
      type: "Non-Deductible",
    },
  ];

  const onClick = (id) => {
    setInvoiceItem((prev) => {
      return prev.map((data) =>
        data.id === list.id ? { ...data, type: id } : data
      );
    });
  };

  const deleteClick = (list) => {
    setIsDeleteClick(true);
    if (item.stage === "TEST") {
      item?.isBonus &&
        testDeleteBonus({
          id: list?.id,
          month: date,
          description: list?.item,
          type: list?.type === 1 ? "DEDUCT_BONUS" : "NON_DEDUCT_BONUS",
          gameId: item?.gameId,
          userId: item?.userId,
        }).then((res) => {
          if (res.status === 401) {
            Cookies.remove("Authentication");
            window.location.reload();
          } else {
            setIsDeleteClick(false);
            return;
          }
        });
    } else {
      item?.isBonus &&
        deleteBonus({
          id: list?.id,
          month: date,
          description: list?.item,
          type: list?.type === 1 ? "DEDUCT_BONUS" : "NON_DEDUCT_BONUS",
          gameId: item?.gameId,
          userId: item?.userId,
        }).then((res) => {
          if (res.status === 401) {
            Cookies.remove("Authentication");
            window.location.reload();
          } else {
            setIsDeleteClick(false);
            return;
          }
        });
    }

    setInvoiceItem((prev) => {
      return prev.filter((data) => data.id !== list?.id);
    });
  };

  const onChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "item":
        setInvoiceItem((prev) => {
          return prev.map((data) =>
            data.id === list.id ? { ...data, item: value } : data
          );
        });
        return;
      case "prize":
        setInvoiceItem((prev) => {
          return prev.map((data) =>
            data.id === list.id ? { ...data, prize: Number(value) } : data
          );
        });
        return;
      default:
        return;
    }
  };
  const isTypeSelect = (id) => {
    return list.type === id;
  };
  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (typeRef?.current && !typeRef?.current?.contains(e.target)) {
        setTypeClick((prev) => !prev);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [setTypeClick]);

  useEffect(() => {
    let handleInputClick = (e) => {
      if (prizeRef?.current?.contains(e.target)) {
        setIsPrizeClick(true);
      } else {
        setIsPrizeClick(false);
      }
    };
    document.addEventListener("mousedown", handleInputClick);
    return () => document.removeEventListener("mousedown", handleInputClick);
  }, [setTypeClick]);
  return (
    <li className={styles.container}>
      <div
        className={styles.type_select}
        onClick={() => setTypeClick((prev) => !prev)}
      >
        <p>{typeList?.find((item) => item.id === list.type)?.type}</p>
        <ExpandIcon
          className={styles.expand_icon}
          data-content={typeClick && "active"}
        />
        {typeClick && (
          <ul className={styles.invoice_type_list} ref={typeRef}>
            {typeList?.map((list) => {
              return (
                <>
                  <li
                    data-content={isTypeSelect(list.id) && "active"}
                    onClick={() => onClick(list?.id)}
                    key={list.id}
                  >
                    <p>{list.type}</p>
                    {isTypeSelect(list.id) && <CheckIcon />}
                  </li>
                </>
              );
            })}
          </ul>
        )}
      </div>

      <input
        className={styles.invoice_item}
        type="text"
        onChange={onChange}
        id="item"
        ref={itemRef}
        value={list?.item}
      />

      <label
        className={styles.invoice_prize}
        ref={prizeRef}
        data-content={isPrizeClick ? "active" : "inactive"}
      >
        <p>$</p>
        <input
          type="text"
          onChange={onChange}
          id="prize"
          placeholder="0"
          value={list?.prize}
        />
      </label>

      <CloseIcon
        className={styles.delete_button}
        onClick={() => (isDeleteClick ? undefined : deleteClick(list))}
      />
    </li>
  );
}
