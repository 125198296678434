import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import styles from "./InvoiceGameDetail.module.scss";
import { ReactComponent as InfoIcon } from "../../Img/Invoice/info.svg";

export default function InvoiceGameDetail({ item, date }) {
  const [activityMonth, setActivityMonth] = useState();
  const [activityYear, setActivityYear] = useState();
  const firstInfoRef = useRef(null);
  const firstBallonRef = useRef(null);
  const secondInfoRef = useRef(null);
  const secondBallonRef = useRef(null);
  useEffect(() => {
    let twoMonthAgoMonth = moment(date).subtract(2, "month").format("MMM");
    let twoMonthAgoYear = moment(date).subtract(2, "month").format("YYYY");
    setActivityMonth(twoMonthAgoMonth);
    setActivityYear(twoMonthAgoYear);
  }, [date]);

  useEffect(() => {
    firstInfoRef?.current.addEventListener("mouseover", () => {
      firstBallonRef.current.style.display = "block";
    });
    firstInfoRef?.current.addEventListener("mouseout", () => {
      firstBallonRef.current.style.display = "none";
    });
    item.carryOverBalances.length &&
      secondInfoRef?.current.addEventListener("mouseover", () => {
        secondBallonRef.current.style.display = "flex";
      });
    item.carryOverBalances.length &&
      secondInfoRef?.current.addEventListener("mouseout", () => {
        secondBallonRef.current.style.display = "none";
      });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.table_container}>
        <section className={styles.first_table}>
          <div className={styles.first_table_header}>
            <p colSpan="2">
              {activityMonth}, {activityYear} Activity*
            </p>
          </div>
          <div className={styles.first_table_body}>
            <span>
              <p className={styles.first_table_body_title} data-content="other">
                <div className={styles.ballon} ref={firstBallonRef}>
                  <p>
                    Ad Revenue:&nbsp;
                    {(
                      item.monthlyGrossRevenue -
                      (item.googleIap + item.appleIap)
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p>
                    IAP Revenue:&nbsp;
                    {(item.googleIap + item.appleIap).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                </div>
                <p>Monthly Gross Revenue</p>

                <InfoIcon className={styles.info_icon} ref={firstInfoRef} />
              </p>
              <p className={styles.first_table_body_prize}>
                {item?.monthlyGrossRevenue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
            <span>
              <p className={styles.first_table_body_title}>Monthly Spend</p>
              <p className={styles.first_table_body_prize}>
                -
                {item?.monthlySpend.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
            <span>
              <p className={styles.first_table_body_title}>Tool Costs</p>
              <p className={styles.first_table_body_prize}>
                -
                {item?.thirdPartCosts.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
            {item?.detailOthers?.length
              ? item.detailOthers.map((data) => (
                  <span>
                    <p className={styles.first_table_body_title}>
                      {data.description}
                    </p>
                    <p className={styles.first_table_body_prize}>
                      {data.profit.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </p>
                  </span>
                ))
              : undefined}
            <span>
              <p className={styles.first_table_body_title} data-content="bold">
                Monthly Net Revenue
              </p>
              <p className={styles.first_table_body_prize} data-content="bold">
                {item?.monthlyNetRevenue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
          </div>
        </section>
        <section className={styles.second_table}>
          <div className={styles.second_table_body}>
            <span>
              <p className={styles.second_table_body_title}>
                Monthly Net Revenue
              </p>
              <p
                className={styles.second_table_body_prize}
                data-content="background"
              >
                {item?.monthlyNetRevenue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
            <span>
              <p className={styles.second_table_body_title}>Carryover Amount</p>
              <p className={styles.second_table_body_prize}>
                {item?.carryOverAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
            <span>
              <p className={styles.second_table_body_title} data-content="bold">
                Total Net Revenue
              </p>
              <p className={styles.second_table_body_prize} data-content="bold">
                {item?.totalNetRevenue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>

            <span>
              <p className={styles.second_table_body_title} data-content="bold">
                Revenue Share
              </p>
              <p
                className={styles.second_table_body_prize}
                data-content="bold_background"
              >
                {item?.revenueShare.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>

            <span>
              <p
                className={styles.second_table_body_title}
                data-content="other"
              >
                <div className={styles.ballon} ref={secondBallonRef}>
                  <p>Recoupable payments include:</p>
                  <ul>
                    {item.carryOverBalances.map((bonus) => (
                      <li>
                        <p>
                          -&nbsp;
                          {bonus.profit.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                        &nbsp;
                        <p>{bonus.description}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <p>Recoupable Payments</p>

                {item?.carryOverBalances?.length ? (
                  <InfoIcon className={styles.info_icon} ref={secondInfoRef} />
                ) : (
                  <></>
                )}
              </p>
              <p className={styles.second_table_body_prize}>
                -
                {item?.paidBonus.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
            <span>
              <p className={styles.second_table_body_title} data-content="bold">
                Payable Revenue Share**
              </p>
              <p
                className={classnames(
                  item?.payableRevenueShare < 0 && styles.revenue_minus,
                  item?.payableRevenueShare === 0 && styles.revenue_zero,
                  item?.payableRevenueShare < 0 ||
                    (item?.payableRevenueShare < 500 && styles.revenue_invalid),
                  item?.payableRevenueShare > 500 && styles.revenue_valid
                )}
              >
                {item?.payableRevenueShare === 0 &&
                item?.payableRevenueShare < 0
                  ? "-"
                  : item?.payableRevenueShare.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
              </p>
            </span>
          </div>
        </section>
        <section className={styles.third_table}>
          <div className={styles.third_table_body}>
            <span className={styles.third_table_body_bonus}>
              <p className={styles.third_table_body_title}>Bonus Payments</p>
            </span>
            <span className={styles.third_table_body_bonus_list}>
              {item.bonuses.length ? (
                item.bonuses.map((data) => (
                  <div>
                    <p className={styles.third_table_body_bonus_list_title}>
                      {data.description}
                    </p>
                    <p className={styles.third_table_body_bonus_list_prize}>
                      {data.profit.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </p>
                  </div>
                ))
              ) : (
                <div>
                  <p className={styles.third_table_body_bonus_list_title}>-</p>
                  <p className={styles.third_table_body_bonus_list_prize}>
                    $ -
                  </p>
                </div>
              )}
            </span>
            <span className={styles.total_amount}>
              <p>Total Amount</p>
              <p>
                {item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </span>
          </div>
        </section>
      </div>
      <div className={styles.detail_text}>
        <p>
          *For data accuracy, monthly activity calculations are delayed by two
          months.
        </p>
        <p>
          **Revenue share becomes payable once recoupable payments have been
          reimbursed and the amount reaches $500.
        </p>
      </div>
    </div>
  );
}
