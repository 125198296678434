import { SetStateAction, useState } from "react";
import styles from "./ConfirmInvoiceModal.module.scss";
import modalIcon from "../../Img/Invoice/modalIcon.png";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";

import InvoiceConfirmLoading from "../../common/InvoiceConfirmLoading/InvoiceConfirmLoading";
import Cookies from "js-cookie";
export default function ConfirmInvoiceModal({
  studioId,
  date,
  setIsConfirmModal,
}: {
  studioId: number;
  date: string;
  setIsConfirmModal: React.Dispatch<SetStateAction<boolean>>;
}) {
  const { confirmInvoice } = useInvoice();
  const [loading, setLoading] = useState<boolean>(false);
  const onConfirmClick = () => {
    setLoading(true);
    confirmInvoice(studioId, date).then((res: any) => {
      if (res.status === 200) {
        setLoading(false);
        window.location.reload();
      } else if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        setLoading(false);
        alert(res.data.message);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal_contents}>
        <img src={modalIcon} alt="icon" />
        <h2>Send Invoice and Details</h2>
        <p>
          Studio will now have access to the invoice. Would you like to to
          proceed?
        </p>
        <section>
          <button
            onClick={() => (loading ? undefined : setIsConfirmModal(false))}
          >
            Cancel
          </button>
          <button onClick={loading ? undefined : onConfirmClick}>
            {!loading ? "Confirm" : <InvoiceConfirmLoading />}
          </button>
        </section>
      </div>
    </div>
  );
}
