import axios from "axios";
export default class AdsNetworkService {
  http: any;
  newHttp: any;
  constructor(http: any, newHttp: string) {
    this.http = http;
    this.newHttp = newHttp;
  }
  async insertAds(data: any, storeId: any) {
    return await this.http
      .axios(`/admin/publishing`, {
        method: "POST",
        data: JSON.stringify({ data, storeId }),
      })
      .then((res: any) => res);
  }

  async insertPublishingGame(appName: string, appId: string, storeId: string) {
    const bundleId = await getBundleId(appId);
    return await axios
      .all([
        this.newHttp.axios("/appList", {
          method: "POST",
          data: JSON.stringify({
            appName,
            platform: "ANDROID",
            bundleId,
            storeId: "",
          }),
        }),
        this.newHttp.axios("/appList", {
          method: "POST",
          data: JSON.stringify({
            appName,
            platform: "IOS",
            bundleId,
            storeId,
          }),
        }),
      ])
      .then(axios.spread((res1, res2) => res2))
      .catch(console.error);
  }

  async getAdsList() {
    return await this.http
      .axios(`/admin/list`, {
        method: "GET",
      })
      .then((res: any) => res.data)
      .catch(console.error);
  }

  async getAdsnetworkData(
    startDate: string,
    endDate: string
  ): Promise<Array<AdnetworkData>> {
    return await this.newHttp
      .axios(
        `/common/totalStatistic?startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
        }
      )
      .then((res: any) => res.data);
  }
}
const getBundleId = async (appId: string) => {
  let config = {
    method: "get",
    url: `https://graph.facebook.com/v18.0/${appId}?fields=name,object_store_urls,photo_url,icon_url`,
  };
  let bundleId = await axios(config)
    .then((res) => {
      return res.data.object_store_urls.google_play.split("=")[1];
    })
    .catch(console.error);
  return bundleId;
};
