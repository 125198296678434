import adColonyicon from "../Img/24px_ad_Icon/24px_Adcolony.png";
import applovinIcon from "../Img/24px_ad_Icon/24px_Applovin.png";
import mintegralIcon from "../Img/24px_ad_Icon/24px_Mintegral.png";
import ironsourceIcon from "../Img/24px_ad_Icon/24px_IronSource.png";
import pangleIcon from "../Img/24px_ad_Icon/24px_Pangle.png";
import tapjoyIcon from "../Img/24px_ad_Icon/24px_Tapjoy.png";
import verveGroupIcon from "../Img/24px_ad_Icon/24px_VerveGroup.png";
import vungleIcon from "../Img/24px_ad_Icon/24px_Vungle.png";
import inmobiIcon from "../Img/24px_ad_Icon/24px_inmobi.png";
import admobIcon from "../Img/24px_ad_Icon/24px_GoogleAdmob.png";
import fyberIcon from "../Img/24px_ad_Icon/24px_DigitalTurbine.png";
import metaIcon from "../Img/24px_ad_Icon/24px_Meta.png";
import unityIcon from "../Img/24px_ad_Icon/24px_UnityAds.png";
import odeeoIcon from "../Img/24px_ad_Icon/24px_odeeo.png";
import advertyIcon from "../Img/24px_ad_Icon/24px_Adverty.png";
import googleAdsIcon from "../Img/24px_ad_Icon/24px_GoogleAdwords.png";
import tiktokIcon from "../Img/24px_ad_Icon/24px_tiktok.png";
import appleSearchIcon from "../Img/24px_ad_Icon/appleIcon.png";
import noneIcon from "../Img/24px_ad_Icon/none.png";
import mobileFuseIcon from "../Img/24px_ad_Icon/96px_mobilefuse.png";
import hyprmxIcon from "../Img/24px_ad_Icon/96px_hyprmx.png";

const iconList = [
  { id: 1, name: "AdColony", icon: adColonyicon },
  { id: 2, name: "AppLovin", icon: applovinIcon },
  { id: 3, name: "Mintegral", icon: mintegralIcon },
  { id: 4, name: "IronSource", icon: ironsourceIcon },
  { id: 5, name: "Pangle", icon: pangleIcon },
  { id: 6, name: "Tapjoy", icon: tapjoyIcon },
  { id: 7, name: "VerveGroup", icon: verveGroupIcon },
  { id: 8, name: "Vungle", icon: vungleIcon },
  { id: 9, name: "Inmobi", icon: inmobiIcon },
  { id: 10, name: "Google", icon: admobIcon },
  { id: 11, name: "Fyber", icon: fyberIcon },
  { id: 12, name: "Meta", icon: metaIcon },
  { id: 13, name: "Unity", icon: unityIcon },
  { id: 14, name: "Odeeo", icon: odeeoIcon },
  { id: 15, name: "Adverty", icon: advertyIcon },
  { id: 16, name: "Google", icon: googleAdsIcon },
  { id: 17, name: "Tiktok", icon: tiktokIcon },
  { id: 18, name: "MobileFuse", icon: mobileFuseIcon },
  { id: 19, name: "Hyprmx", icon: hyprmxIcon },
  { id: 20, name: "AppleSearch", icon: appleSearchIcon },
  { id: 21, name: "None", icon: noneIcon },
];

export default iconList;
