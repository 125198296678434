import React, { SetStateAction, useState } from "react";
import { useAppInfo } from "../../Context/AppInfoContext";
import styles from "./AmazonModal.module.scss";
export default function AmazonModal({
  selectList,
  setIsAddAppAmazonBtn,
}: {
  selectList: AppInfoData;
  setIsAddAppAmazonBtn: React.Dispatch<SetStateAction<boolean>>;
}) {
  const { createAppInfo } = useAppInfo();
  const [appInfo, setAppInfo] = useState({
    gameId: selectList.gameId,
    platform: "amazon",
    bundleId: selectList.bundleId,
    storeId: "",
  });

  const onClick = () => {
    createAppInfo(appInfo).then(() => {
      window.location.href = "/appInfo";
    });
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAppInfo((prev) => ({ ...prev, storeId: value }));
  };
  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <div className={styles.game_info}>
          <img src={selectList.game.gameIconUrl} alt="" />
          <p>{selectList.game.name}</p>
        </div>

        <div className={styles.input_store_id}>
          <p>Store Id</p>
          <input type="text" id="storeId" onChange={onChange} />
        </div>

        <div className={styles.buttons}>
          <button onClick={() => setIsAddAppAmazonBtn(false)}>
            <p>close</p>
          </button>
          <button onClick={onClick}>
            <p>submit</p>
          </button>
        </div>
      </div>
    </div>
  );
}
