import React from "react";
import { Skeleton } from "@mui/material";
import styles from "./GameStatusSkeleton.module.scss";
export default function GameStatusSkeleton() {
  return (
    <>
      <tr>
        <th className={styles.game_info_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </th>
        <th className={styles.studio_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.studio_name}
          />
        </th>
        <th className={styles.pm_list_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.pm_list}
          />
        </th>
        <th className={styles.game_status_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_status}
          />
        </th>
        <th className={styles.contract_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.contract_date}
          />
        </th>
        <th className={styles.scale_up_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.scale_up_date}
          />
        </th>
      </tr>
      <tr>
        <th className={styles.game_info_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </th>
        <th className={styles.studio_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.studio_name}
          />
        </th>
        <th className={styles.pm_list_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.pm_list}
          />
        </th>
        <th className={styles.game_status_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_status}
          />
        </th>
        <th className={styles.contract_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.contract_date}
          />
        </th>
        <th className={styles.scale_up_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.scale_up_date}
          />
        </th>
      </tr>
      <tr>
        <th className={styles.game_info_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </th>
        <th className={styles.studio_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.studio_name}
          />
        </th>
        <th className={styles.pm_list_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.pm_list}
          />
        </th>
        <th className={styles.game_status_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_status}
          />
        </th>
        <th className={styles.contract_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.contract_date}
          />
        </th>
        <th className={styles.scale_up_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.scale_up_date}
          />
        </th>
      </tr>
      <tr>
        <th className={styles.game_info_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </th>
        <th className={styles.studio_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.studio_name}
          />
        </th>
        <th className={styles.pm_list_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.pm_list}
          />
        </th>
        <th className={styles.game_status_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_status}
          />
        </th>
        <th className={styles.contract_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.contract_date}
          />
        </th>
        <th className={styles.scale_up_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.scale_up_date}
          />
        </th>
      </tr>
      <tr>
        <th className={styles.game_info_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </th>
        <th className={styles.studio_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.studio_name}
          />
        </th>
        <th className={styles.pm_list_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.pm_list}
          />
        </th>
        <th className={styles.game_status_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_status}
          />
        </th>
        <th className={styles.contract_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.contract_date}
          />
        </th>
        <th className={styles.scale_up_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.scale_up_date}
          />
        </th>
      </tr>
      <tr>
        <th className={styles.game_info_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </th>
        <th className={styles.studio_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.studio_name}
          />
        </th>
        <th className={styles.pm_list_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.pm_list}
          />
        </th>
        <th className={styles.game_status_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_status}
          />
        </th>
        <th className={styles.contract_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.contract_date}
          />
        </th>
        <th className={styles.scale_up_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.scale_up_date}
          />
        </th>
      </tr>
      <tr>
        <th className={styles.game_info_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_icon}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_name}
          />
        </th>
        <th className={styles.studio_name_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.studio_name}
          />
        </th>
        <th className={styles.pm_list_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.pm_list}
          />
        </th>
        <th className={styles.game_status_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.game_status}
          />
        </th>
        <th className={styles.contract_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.contract_date}
          />
        </th>
        <th className={styles.scale_up_date_section}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={styles.scale_up_date}
          />
        </th>
      </tr>
    </>
  );
}
