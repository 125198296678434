import axios from "axios";
import { getCookie } from "../../db/cookie";

export default class NewMaxService {
  http: string;
  constructor(http: string) {
    this.http = http;
  }
  async getMaxRequestList() {
    return await axios
      .get(`${this.http}/test/admin/appInfo/max`, {
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async requestMaxIds(appInfoId: number) {
    return await axios
      .post(`${this.http}/test/admin/max/maxAdUnitId`, {
        data: {
          appInfoId,
        },
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  async sendRequestEmail(appInfoId: number) {
    return await axios
      .get(`${this.http}/test/admin/appInfo/max/noti?appInfoId=${appInfoId}`, {
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  async getData(id: number) {
    return await axios
      .get(`${this.http}/test/admin/appInfo/detail/max?id=${id}`)
      .then((res) => res.data)
      .catch((e) => e.response);
  }
}
