import styles from "./PubSummary.module.css";
import genNum from "../../data/generateNumber";
export default function PubSummary({ adNetworkData }: { adNetworkData: any }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Summary</div>
      <div className={styles.dataContainer}>
        <div className={styles.dataBox}>
          <div>Spend</div>
          <div className={styles.spend}>
            {adNetworkData?.totalSpend?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        </div>
        <div className={styles.dataBox}>
          <div>Revenue</div>
          <div className={styles.revenue}>
            {adNetworkData?.totalRevenue?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        </div>
        <div className={styles.dataBox}>
          <div>Profit</div>
          <div className={styles.profit}>
            {adNetworkData?.totalProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </div>
        </div>
        <div className={styles.dataBox} data-content="install">
          <div>Install</div>
          <div className={styles.profit}>
            {genNum(adNetworkData?.totalInstalls) ?? "0"}
          </div>
        </div>
      </div>
    </div>
  );
}
