import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
} from "react";

const AdnetworkContext = createContext(
  {} as {
    getAdsList: () => Promise<Array<GameData>>;
    getAdsnetworkData: (
      startDate: string,
      endDate: string
    ) => Promise<void | Array<AdnetworkData>>;
  }
);
const contextRef = createRef();
export function AdnetworkProvider({
  adsNetworkService,
  children,
}: {
  adsNetworkService: AdnetworkServiceList;
  children: React.ReactNode;
}) {
  const getAdsList = useCallback(async () => {
    return await adsNetworkService.getAdsList().then((data) => {
      return data;
    });
  }, [adsNetworkService]);
  const getAdsnetworkData = useCallback(
    async (startDate: string, endDate: string) => {
      return await adsNetworkService
        .getAdsnetworkData(startDate, endDate)
        .then((res) => res)
        .catch(console.error);
    },
    [adsNetworkService]
  );
  const context = useMemo(
    () => ({ getAdsList, getAdsnetworkData }),
    [getAdsList, getAdsnetworkData]
  );

  return (
    <AdnetworkContext.Provider value={context}>
      {children}
    </AdnetworkContext.Provider>
  );
}

export default AdnetworkProvider;
export const fetchToken = () => contextRef.current;
export const useAdnetworkData = () => useContext(AdnetworkContext);
