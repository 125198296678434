export const checkStatus = (status) => {
  switch (status) {
    case "CAMPAIGN_PAUSED":
      return "Paused";
    case "PAUSED":
      return "Paused";
    case "PENDING_REVIEW":
      return "Review";
    case "IN_PROCESS":
      return "Active";
    case "ACTIVE":
      return "Active";
    default:
      return "Inactive";
  }
};
