import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./CPI.module.scss";
const CPI = ({ adsetData }) => {
  const [cpi, setCpi] = useState(0);
  useEffect(() => {
    setCpi(0);
    adsetData?.insights?.data?.map((data) => {
      return setCpi(
        data?.cost_per_action_type?.find(
          (action) => action?.action_type === "omni_app_install"
        )?.value
      );
    });
  });

  return (
    <div className={styles.container}>
      <div>CPI</div>
      <div>
        {cpi !== 0
          ? Number(cpi).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : "-"}
      </div>
    </div>
  );
};

export default CPI;
