import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import styles from "./InvoiceCalander.module.scss";

import { ReactComponent as ArrowIcon } from "../../Img/Invoice/arrow_left.svg";
import { useKeyEscClose } from "../../common/escClose/escClose";
import { monthCheck } from "../../data/monthCheck";
export default function InvoiceCalander({
  setIsCalanderClick,
  monthList,
  setMonth,
  setYear,
  month,
  year,
  prevDate,
  setPrevDate,
}) {
  const [selectYear, setSelectYear] = useState(year);
  const calanderRef = useRef();

  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (calanderRef?.current && !calanderRef?.current?.contains(e.target)) {
        setIsCalanderClick(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [setIsCalanderClick]);

  const onMonthClick = (item, selectYear) => {
    const month = monthCheck.find((data) => data[item]);
    setMonth(item);
    setYear(selectYear);
    setIsCalanderClick(false);
    setPrevDate(moment(`${selectYear}-${month[item]}`).format("YYYY-MM"));
  };
  const isMonthSelect = (selectYear, item) => {
    return (
      month.includes(item) &&
      selectYear === Number(moment(prevDate).format("YYYY"))
    );
  };
  const onYearCountClick = (type) => {
    if (type === "prev") {
      setSelectYear((prev) => prev - 1);
    } else {
      setSelectYear((prev) => prev + 1);
    }
  };
  useKeyEscClose(() => {
    setIsCalanderClick(false);
  });

  return (
    <div className={styles.container} ref={calanderRef}>
      <div className={styles.year}>
        <button onClick={() => onYearCountClick("prev")} data-content="prev">
          <ArrowIcon className={styles.arrow_left} />
        </button>
        <p>{selectYear}</p>
        <button
          data-content={moment().year() !== selectYear ? "next" : "inactive"}
          onClick={() =>
            moment().year() !== selectYear && onYearCountClick("next")
          }
        >
          <ArrowIcon className={styles.arrow_right} />
        </button>
      </div>
      <ul className={styles.monthList}>
        {monthList.map((item, idx) => {
          const month = monthCheck.find((data) => data[item]);
          return moment().isSameOrAfter(`${selectYear}-${month[item]}`) ? (
            <li
              key={Math.random(idx)}
              data-content={
                isMonthSelect(selectYear, item) ? "active" : "inactive"
              }
              onClick={() => onMonthClick(item, selectYear)}
            >
              {item}
            </li>
          ) : (
            <li
              invalid={
                moment().isSameOrBefore(`${selectYear}-${month[item]}`)
                  ? "invalid"
                  : ""
              }
            >
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
