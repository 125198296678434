import { useState } from "react";
import styles from "./Status.module.scss";
import AdsListAnimation from "../../common/AdsListAnimation/AdsListAmination";
import GameTotalData from "../GameTotalData/GameTotalData";

const Status = ({ adsList, adNetworkData }) => {
  const [select, setSelect] = useState();
  const [isClick, setIsClick] = useState(false);
  const onClick = (item) => {
    setIsClick(!isClick);
    setSelect(item);
    return () => setSelect();
  };
  const isSelect = (item) => {
    return select?.id === item?.id;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Status</div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.menu}>Name</th>
            <th className={styles.menu}>Spend</th>
            <th className={styles.menu}>Total Rev</th>
            <th className={styles.menu}>Profit</th>
            <th className={styles.menu}>Install</th>
          </tr>
        </thead>
        {adNetworkData?.length === 0 ? (
          <AdsListAnimation />
        ) : (
          adsList?.map((data, idx) => {
            return (
              <tbody key={idx}>
                <GameTotalData
                  onClick={onClick}
                  data={data}
                  isSelect={isSelect}
                  select={select}
                  adData={adNetworkData.apps.find(
                    (item) => item.name === data.appName
                  )}
                  isClick={isClick}
                />
              </tbody>
            );
          })
        )}
      </table>
    </div>
  );
};

export default Status;
