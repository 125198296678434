import { useEffect, useState } from "react";

import addIcon from "../../Img/Invoice/addIcon.png";

import styles from "./EditDetails.module.scss";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import EditDetailOther from "../EditDetailOther/EditDetailOther";
import InvoiceAddEditLoading from "../../common/InvoiceAddEditLoading/InvoiceAddEditLoading";
import { useKeyEscClose } from "../../common/escClose/escClose";
import CheckIcon from "../../common/CheckIcon/CheckIcon";
import Cookies from "js-cookie";
export default function EditDetails({ item, setIsEditDetailsClick, date }) {
  const { editThirdPartyPrize, getThirdPartyPrize } = useInvoice();

  const [isAddClick, setIsAddClick] = useState(false);
  const [isDeleteClick, setIsDeleteClick] = useState(false);
  const [thirdPartyList, setThirdPartyList] = useState([]);

  const [detailsItem, setDetailsItem] = useState([
    { id: 1, description: "", prize: 0 },
  ]);

  const onAddClick = () => {
    setDetailsItem([
      ...detailsItem,
      {
        id: detailsItem.length + 1,
        type: 1,
        description: "",
        prize: 0,
        addTrue: true,
      },
    ]);
  };

  useEffect(() => {
    if (item?.isDetail) {
      return getThirdPartyPrize(item?.gameId, date).then((res) => {
        setDetailsItem(() =>
          res?.data?.balances.map((item) => ({
            id: item.id,
            description: item.description,
            prize: item.profit,
          }))
        );
      });
    }
  }, [date, item, getThirdPartyPrize]);

  useEffect(() => {
    setThirdPartyList(() => {
      return detailsItem.map((data) =>
        data.addTrue
          ? {
              month: date,
              description: data.description,
              type: "DETAIL_OTHER",
              profit: Number(data.prize),
              userId: item?.userId,
              gameStage: item?.stage,
              gameId: item?.gameId,
            }
          : {
              id: data.id,
              month: date,
              description: data.description,
              type: "DETAIL_OTHER",
              profit: Number(data.prize),
              userId: item?.userId,
              gameStage: item?.stage,
              gameId: item?.gameId,
            }
      );
    });
  }, [item, date, detailsItem]);

  const onEditThirdPartyPrize = () => {
    if (thirdPartyList.length) {
      setIsAddClick(true);
      editThirdPartyPrize({ detailOthers: thirdPartyList, month: date }).then(
        (res) => {
          if (res.status === 201) {
            window.location.reload();
          } else if (res.status === 401) {
            Cookies.remove("Authentication");
            window.location.reload();
          } else {
            setIsAddClick(false);
            alert(res.data.message);
          }
        }
      );
    } else {
      window.location.reload();
    }
  };
  useKeyEscClose(() => {
    setIsEditDetailsClick(false);
  });
  return (
    <article className={styles.container}>
      <section className={styles.contents}>
        <h2>Edit Details</h2>
        <section className={styles.game_info}>
          {CheckIcon(item.gameIconUrl) ? (
            <img
              className={styles.game_icon}
              src={item?.gameIconUrl}
              alt="icon"
            />
          ) : (
            <div className={styles.game_icon} />
          )}
          <p>{item.gameName}</p>
        </section>
        <section className={styles.insert_other}>
          <h3>Insert Other</h3>
          <ul className={styles.insert_other_list}>
            {detailsItem.map((list) => (
              <EditDetailOther
                list={list}
                item={item}
                setDetailsItem={setDetailsItem}
                detailsItem={detailsItem}
                date={date}
                isDeleteClick={isDeleteClick}
                setIsDeleteClick={setIsDeleteClick}
                isAddClick={isAddClick}
              />
            ))}
          </ul>
          <button
            className={styles.detail_item_add_button}
            onClick={isAddClick || isDeleteClick ? undefined : onAddClick}
          >
            <img src={addIcon} alt="icon" />
            <p>Add Item</p>
          </button>
        </section>
        <section className={styles.buttons}>
          <button
            onClick={() =>
              isAddClick || isDeleteClick
                ? undefined
                : setIsEditDetailsClick(false)
            }
          >
            <p> Cancel</p>
          </button>
          <button onClick={isAddClick ? undefined : onEditThirdPartyPrize}>
            {isAddClick ? <InvoiceAddEditLoading /> : <p>Edit</p>}
          </button>
        </section>
      </section>
    </article>
  );
}
