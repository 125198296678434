import React from "react";
import { ReactComponent as DollarIcon } from "../../Img/Invoice/dollar.svg";
import { ReactComponent as DeleteIcon } from "../../Img/Invoice/close.svg";
import styles from "./AddDetailOther.module.scss";
import inputNumberFormat from "../../common/numberFormat";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
export default function AddDetailOther({ detailsItem, setDetailsItem }) {
  const prizeRef = useRef(null);
  const [isPrizeClick, setIsPrizeClick] = useState(false);
  const deleteClick = () => {
    setDetailsItem((prev) => {
      return prev.filter((data) => data.id !== detailsItem?.id);
    });
  };
  const onChange = (e) => {
    const { value, id } = e.target;
    switch (id) {
      case "detail_description":
        setDetailsItem((prev) =>
          prev?.map((data) =>
            data.id === detailsItem.id ? { ...data, description: value } : data
          )
        );
        return;
      case "detail_prize":
        setDetailsItem((prev) =>
          prev?.map((data) =>
            data.id === detailsItem.id ? { ...data, prize: value } : data
          )
        );
        return inputNumberFormat(value);
      default:
        return;
    }
  };

  useEffect(() => {
    let clickInputHandler = (e) => {
      if (prizeRef?.current?.contains(e.target)) {
        setIsPrizeClick(true);
      } else {
        setIsPrizeClick(false);
      }
    };
    document.addEventListener("mousedown", clickInputHandler);
    return () => document.removeEventListener("mousedown", clickInputHandler);
  }, []);
  return (
    <li className={styles.container}>
      <input
        type="text"
        id="detail_description"
        className={styles.desciption}
        onChange={onChange}
      />
      <label data-content={isPrizeClick ? "active" : "inactive"} ref={prizeRef}>
        <DollarIcon />
        <input
          type="text"
          id="detail_prize"
          className={styles.prize}
          onChange={onChange}
        />
      </label>
      <DeleteIcon className={styles.delete_button} onClick={deleteClick} />
    </li>
  );
}

//
