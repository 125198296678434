import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./index.css";
import TokenStorage from "./db/token";
import { AuthProvider } from "./Context/AuthContext";
import { InvoiceProvider } from "./Context/NewContext/InvoiceContext";
import AdminAuthService from "./service/adminAuth";
import {
  HttpClient,
  AdsNetworkHttpClient,
  AppInfoHttpClient,
} from "./network/http";
import AdsService from "./service/adsList";
import MaxService from "./service/max";
import AdNetworkService from "./service/adNetwork";
import AppInfoService from "./service/appInfo";
import GameInfoService from "./service/gameInfo";
import { RecoilRoot } from "recoil";
import MetaCampaignService from "./service/New/metaCampaign";
import NewMaxService from "./service/New/max";
import InvoiceService from "./service/invoice";
import GameStatusService from "./service/gameStatus";
//test

const baseURL = process.env.REACT_APP_BASE_URL;
const adNetworkURL = process.env.REACT_APP_ADS_NETWORK_URL;
const newAdnetworkURL = process.env.REACT_APP_NEW_ADS_NETWORK_URL;
const appInfoUrl = process.env.REACT_APP_APP_INFO_URL;
const gameInfoURl = process.env.REACT_APP_GAME_URL;
const tokenStorage = new TokenStorage();

const httpClient = new HttpClient(baseURL);
const adsNetworkHttpClient = new AdsNetworkHttpClient(adNetworkURL);
const newAdsNetworkHttpClient = new AdsNetworkHttpClient(newAdnetworkURL);
const appInfoHttpClient = new AppInfoHttpClient(appInfoUrl);
const gameInfoHttpClient = new AppInfoHttpClient(gameInfoURl);

const adminClientAuth = new AdminAuthService(httpClient, tokenStorage);
const adsService = new AdsService(httpClient, tokenStorage);
const maxService = new MaxService(httpClient, tokenStorage);
const adsNetworkService = new AdNetworkService(
  adsNetworkHttpClient,
  newAdsNetworkHttpClient
);
const appInfoService = new AppInfoService(appInfoHttpClient);
const gameInfoService = new GameInfoService(gameInfoHttpClient);


//NEW
const metaCampaignService = new MetaCampaignService(httpClient);
const newMaxService = new NewMaxService(baseURL);
const invoiceService = new InvoiceService(httpClient);
const gameStatusService = new GameStatusService(httpClient);
const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <RecoilRoot>
      <AuthProvider adminClientAuth={adminClientAuth}>
        <InvoiceProvider invoiceService={invoiceService}>
          <App
            maxService={maxService}
            adminClientAuth={adminClientAuth}
            adsService={adsService}
            adsNetworkService={adsNetworkService}
            appInfoService={appInfoService}
            gameInfoService={gameInfoService}
            metaCampaignService={metaCampaignService}
            newMaxService={newMaxService}
            gameStatusService={gameStatusService}
          />
        </InvoiceProvider>
      </AuthProvider>
    </RecoilRoot>
  </BrowserRouter>,
  rootElement
);
