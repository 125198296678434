import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import axios from "axios";

const GameListContext = createContext({} as GameListContextProps);
const contextRef = createRef();
export function GameListProvider({
  adsService,
  children,
}: {
  adsService: AdServiceList;
  children: any;
}) {
  const [progressAdsList, setProgressAdsAdsList] = useState<any[]>([]);
  const [completeAdsList, setCompleteAdsList] = useState<any[]>([]);

  const [limitError, setLimitError] = useState(
    {} as { status: number; message: string }
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [completeTypeList, setCompleteTypeList] = useState<any[]>([]);
  const [adsData, setAdsData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [progressTypeList, setProgressTypeList] = useState<any[]>([]);

  const [progressStudioList, setProgressStudioList] = useState<string[]>([]);
  const [completeStudioList, setCompleteStudioList] = useState<string[]>([]);

  const getAllList = useCallback(async () => {
    setIsLoading(true);
    return await adsService.getAllAds().then((res: any) => {
      if (res?.status === 500) {
        setIsLoading(false);
        setLimitError({ status: res?.status, message: "Limit Error" });
      } else {
        setIsLoading(false);
        setAdsData(res.data);
      }
    });
  }, [adsService]);

  const getList = useCallback(async () => {
    return await adsService.getList().then((res: AdsList) => {
      setProgressAdsAdsList(
        res.data
          .filter((item: DataInfo) => moment().isBefore(item.endTime))
          .sort(
            (a: DataInfo, b: DataInfo) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
      );

      setProgressTypeList(
        res.data.filter((item: DataInfo) => moment().isBefore(item.endTime))
      );
    });
  }, [adsService]);

  const getCompleteList = useCallback(async () => {
    return await adsService.getCompleteList(page).then((res: AdsList) => {
      setCompleteAdsList((prev: Array<DataInfo>) =>
        [...prev, ...res.data].sort(
          (a: DataInfo, b: DataInfo) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      );
      setCompleteTypeList((prev) => [...prev, ...res.data]);
    });
  }, [adsService, page]);

  useEffect(() => {
    getAllList();
    getList();
    getCompleteList();
  }, [getAllList, getCompleteList, getList]);
  useEffect(() => {
    let progresslist: string[] = [];

    progressAdsList?.map((data) => {
      return progresslist.push(data?.studioName);
    });

    return setProgressStudioList(progresslist);
  }, [progressAdsList]);

  useEffect(() => {
    let completeList: string[] = [];
    completeAdsList?.map((data) => {
      return completeList.push(data?.studioName);
    });

    setCompleteStudioList((prev) => [...prev, ...completeList]);
  }, [completeAdsList]);

  const getStudioInfo = useCallback(async (id: number) => {
    return await axios(
      `https://server.mondayoff.me/test/admin/appInfo/detail/game?id=${id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.data)
      .catch((e) => e.response);
  }, []);

  const context = useMemo(
    () => ({
      progressAdsList,
      completeAdsList,
      adsData,
      progressTypeList,
      progressStudioList,
      completeTypeList,
      completeStudioList,
      setProgressStudioList,
      setPage,
      getStudioInfo,
      limitError,
      isLoading,
    }),
    [
      progressAdsList,
      completeAdsList,
      adsData,
      progressTypeList,
      progressStudioList,
      completeTypeList,
      completeStudioList,
      getStudioInfo,
      limitError,
      isLoading,
    ]
  );

  return (
    <GameListContext.Provider value={context}>
      {children}
    </GameListContext.Provider>
  );
}

export default GameListContext;
export const fetchToken = () => contextRef.current;
export const useGameList = () => useContext(GameListContext);
