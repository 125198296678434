import { getCookie } from "../db/cookie";

export default class InvoiceService implements InvoiceServiceList {
  http: any;
  constructor(http: any) {
    this.http = http;
  }
  async getStudioInfo(studioId: number): Promise<StudioInfo> {
    return await this.http
      .axios(`/test/admin/studio/info?studioId=${studioId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: StudioInfo) => res)
      .catch((e: any) => e.response);
  }
  async editStudioInfo(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/studio/info/update`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async getOverviewData(
    startDate: string,
    endDate: string
  ): Promise<OverviewList> {
    return await this.http
      .axios(
        `/test/common/admin/finance/overview?startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: OverviewList) => res)
      .catch((e: any) => e.response);
  }

  async downloadOverviewData(
    startDate: string,
    endDate: string,
    gameIds: string
  ): Promise<void> {
    return await this.http
      .axios(
        `/test/common/admin/finance/overview/csv?startDate=${startDate}&endDate=${endDate}&everyDayGameIds=${gameIds}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: InvoiceList) => res)
      .catch((e: any) => e.response);
  }

  async getList(date: string): Promise<InvoiceList> {
    return await this.http
      .axios(`/test/admin/invoice/list?month=${date}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async getBonus(gameId: number, month: string): Promise<BonusList> {
    return await this.http
      .axios(
        `/test/admin/game/search/bonusBalance?gameId=${gameId}&month=${month}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: BonusList) => res)
      .catch((e: any) => e.response);
  }

  async getThirdPartyPrize(gameId: number, date: string): Promise<void> {
    return await this.http
      .axios(
        `/test/admin/game/search/detailOtherBalance?gameId=${gameId}&month=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async addThirdPartyPrize(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/detailOther`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async addRateInfo(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/rate`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async editRateInfo(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/rate`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async editThirdPartyPrize(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/detailOther`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async deleteThirdPartyPrize(data: any): Promise<void> {
    return await this.http
      .axios("/test/admin/balance/detailOther", {
        method: "DELETE",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async addBonus(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/bonus`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async modifiedBonus(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/bonus`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async deleteBonus(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/bonus`, {
        method: "DELETE",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async searchList(type: string, name: string): Promise<void> {
    return await this.http
      .axios(
        `/test/admin/game/search/testGame?searchType=${type}&searchText=${name}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async testAddBonus(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/test/bonus`, {
        method: "POST",
        data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async testModifiedBonus(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/test/bonus`, {
        method: "POST",
        data: {
          bonus: data,
        },
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async testDeleteBonus(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/test/bonus`, {
        method: "DELETE",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async confirmInvoice(userId: number, date: string): Promise<void> {
    return await this.http
      .axios(
        `/test/admin/studio/invoice/confirm?studioId=${userId}&month=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
        }
      )
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async totalConfirm(date: string): Promise<void> {
    return await this.http
      .axios(`/test/admin/balance/third/confirm?month=${date}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async getRateInfo(date: string): Promise<RateInfo> {
    return await this.http
      .axios(`/test/admin/balance/rateInfo?month=${date}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async addRemittance(data: any): Promise<void> {
    return await this.http
      .axios(`/test/admin/invoice/payment/statement`, {
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }

  async downloadInvoice(studioId: number, date: string): Promise<void> {
    return await this.http
      .axios(
        `/test/admin/invoice/download?studioId=${studioId}&month=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
          responseType: "blob",
        }
      )
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async downloadDetail(studioId: number, gameId: number, date: string) {
    return await this.http
      .axios(
        `/test/admin/invoice/detail/download?studioId=${studioId}&month=${date}&gameId=${gameId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
          responseType: "blob",
        }
      )
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
  async downloadRemittance(studioId: number, date: string): Promise<void> {
    return await this.http
      .axios(
        `/test/admin/invoice/payment/statement?studioId=${studioId}&month=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getCookie("Authentication")}`,
          },
          responseType: "blob",
        }
      )
      .then((res: any) => res)
      .catch((e: any) => e.response);
  }
}
