import React, { useEffect } from "react";
import styles from "./InvoiceConfirmLoading.module.scss";
import lottie from "lottie-web";
import listAnimation from "../jsonFile/X4YQnjffiE.json";
const InvoiceConfirmLoading = () => {
  useEffect(() => {
    const container = document.querySelector("#container");
    lottie.loadAnimation({
      container: container,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: listAnimation,
    });
  }, []);
  return (
    <div className={styles.container}>
      <div id="container" style={{ width: "50px" }}></div>
    </div>
  );
};

export default InvoiceConfirmLoading;
