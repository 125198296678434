import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Complete.module.scss";
import Filter from "../Filter/Filter";
import AdsListAnimation from "../../common/AdsListAnimation/AdsListAmination";
import emptyBox from "../../Img/adsData/emptybox.png";
import TypeFilter from "../TypeFilter/TypeFilter";
import { useGameList } from "../../Context/GameListContext";

import { checkStatus } from "../../common/checkStatus";

const Complete = () => {
  const {
    completeAdsList,
    adsData,
    setPage,
    completeTypeList,
    completeStudioList,
    limitError,
    isLoading,
  } = useGameList();

  const searchTypeList = [
    {
      id: 1,
      type: "Game",
    },
    {
      id: 2,
      type: "Studio",
    },
  ];
  const [searchType, setSearchType] = useState({
    id: 1,
    type: "Game",
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectType, setSelectType] = useState("All");

  const scrollRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!scrollRef.current) return;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) setPage((prev) => prev + 1);
    });

    observer.observe(scrollRef.current);
    return () => observer.disconnect();
  });

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <Filter
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          studioList={completeStudioList}
          searchType={searchType}
          searchTypeList={searchTypeList}
          setSearchType={setSearchType}
        />
        <TypeFilter
          typeList={completeTypeList}
          selectType={selectType}
          setSelectType={setSelectType}
        />
      </div>

      <div className={styles.dataBox}>
        <ul className={styles.items}>
          <li>Game</li>
          <li>CPI</li>
          <li>Studio</li>
          <li>Status</li>
          <li>type</li>
          <li>Upload</li>
          <li>End Data</li>
        </ul>
        {isLoading ? (
          <AdsListAnimation />
        ) : limitError.status === 500 ? (
          <div className={styles.error}>
            <img src={emptyBox} alt="img" />
            <p>{limitError.message}</p>
          </div>
        ) : (
          <div className={styles.dataScroll}>
            {completeAdsList
              ?.filter((data) => {
                if (searchType.id === 1) {
                  if (searchValue === "") {
                    return data;
                  } else if (
                    data?.gameName
                      .toLowerCase()
                      .replace(/(\s*)/g, "")
                      .includes(
                        searchValue.replace(/(\s*)/g, "").toLowerCase()
                      ) ||
                    data?.gameName
                      .toUpperCase()
                      .replace(/(\s*)/g, "")
                      .includes(searchValue.replace(/(\s*)/g, "").toUpperCase())
                  ) {
                    return data;
                  }
                } else {
                  if (searchValue === "All Studios") {
                    return data;
                  } else if (
                    data?.studioName
                      .toLowerCase()
                      .replace(/(\s*)/g, "")
                      .includes(
                        searchValue.replace(/(\s*)/g, "").toLowerCase()
                      ) ||
                    data?.studioName
                      .toUpperCase()
                      .replace(/(\s*)/g, "")
                      .includes(searchValue.replace(/(\s*)/g, "").toUpperCase())
                  ) {
                    return data;
                  }
                }
              })
              .filter((data) => {
                if (selectType === "All") {
                  return data;
                } else if (
                  data?.type
                    .toLowerCase()
                    .replace(/(\s*)/g, "")
                    .includes(selectType.replace(/(\s*)/g, "").toLowerCase()) ||
                  data?.type
                    .toUpperCase()
                    .replace(/(\s*)/g, "")
                    .includes(selectType.replace(/(\s*)/g, "").toUpperCase())
                ) {
                  return data;
                }
              })
              .map((data, idx) => {
                let ads = adsData?.filter((ad) =>
                  ad?.name.split("_").includes(data?.studioName)
                );
                let status = ads?.find((ad) =>
                  ad?.name?.includes(data?.gameName)
                );
                let CPI = status?.adsets?.data?.flatMap((data: any) => {
                  return data?.insights
                    ? data.insights?.data?.map((d: any) => {
                        let prize = Number(
                          d?.cost_per_action_type
                            ? d?.cost_per_action_type?.find(
                                (action: any) =>
                                  action?.action_type === "omni_app_install"
                              )?.value
                            : "0.00"
                        );

                        return isNaN(prize)
                          ? "$0.00"
                          : prize.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            });
                      })
                    : "$0.00";
                });

                let gameName = data?.gameName;
                return (
                  <Link
                    key={Math.random()}
                    to={`/test/complete/${data.id}`}
                    state={{ data: data }}
                    ref={
                      idx + 1 === completeAdsList.length ? scrollRef : undefined
                    }
                  >
                    <ul className={styles.dataList}>
                      <li className={styles.game}>
                        <img
                          src={data?.gameIcon}
                          alt=""
                          className={styles.gameIcon}
                        />
                        <div className={styles.gameName}>{gameName}</div>
                      </li>
                      <li className={styles.CPI}>{CPI}</li>
                      <li className={styles.studioName}>{data?.studioName}</li>
                      <li
                        className={styles.campaign_status}
                        data-content={checkStatus(status?.effective_status)}
                      >
                        {checkStatus(status?.effective_status)}
                      </li>
                      <li className={styles.type} data-content={data?.type}>
                        {data?.type}
                      </li>
                      <li className={styles.createdAt}>
                        {moment(data?.createdAt).format("YYYY. MM. DD")}
                      </li>
                      <li className={styles.createdAt}>
                        {moment(data.endTime).format("YYYY. MM. DD")}
                      </li>
                    </ul>
                  </Link>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Complete);
