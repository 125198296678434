import styles from "./App.module.scss";
import { ChartProvider } from "./Context/ChartContext";
import { MaxProvider } from "./Context/MaxContext";
import NewSideBar from "./components/Sidebar/NewSideBar";
import RouterTest from "./Router/Router.test";
import Router from "./Router/Router";
export default function App({
  adsService,
  adminClientAuth,
  maxService,
  adsNetworkService,
  appInfoService,
  gameInfoService,
  studioInfoService,
  metaCampaignService,
  newMaxService,
  gameStatusService,
}) {
  return (
    <div className={styles.mainContainer}>
      <NewSideBar />
      <div className={styles.main}>
        <MaxProvider maxService={maxService}>
          <ChartProvider adsService={adsService}>
            <Router
              adsService={adsService}
              adminClientAuth={adminClientAuth}
              maxService={maxService}
              adsNetworkService={adsNetworkService}
              appInfoService={appInfoService}
              gameInfoService={gameInfoService}
              studioInfoService={studioInfoService}
              gameStatusService={gameStatusService}
              newMaxService={newMaxService}
              metaCampaignService={metaCampaignService}
            />
          </ChartProvider>
        </MaxProvider>
      </div>
    </div>
  );
}
