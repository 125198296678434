import React from "react";

import moment from "moment";
import { subDays } from "date-fns";
import styles from "./PubCalendar.module.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
export default function PubCalender({
  state,
  setState,
  thirtyState,
  setThirtyState,
  selectRange,
  setSelectRange,
  select,
  setSelect,
  clickCalendar,
  setClickCalendar,
  dayList,
  rangeDateUpdate,
  allTimeState,
  setAllTimeSteate,
}) {
  const onClickDateSelect = (key) => {
    if (key === 1) {
      setState((prevState) => {
        return { ...prevState, endDate: state.endDate };
      });
      setClickCalendar(false);
    } else if (key === 2) {
      setThirtyState((prevState) => {
        return {
          ...prevState,
          startDate: thirtyState.startDate,
          endDate: thirtyState.endDate,
        };
      });
      setClickCalendar(false);
    } else if (key === 3) {
      setAllTimeSteate((prevState) => {
        return {
          ...prevState,
          startDate: allTimeState.startDate,
          endDate: allTimeState.endDate,
        };
      });
      setClickCalendar(false);
    } else {
      setClickCalendar(!clickCalendar);
    }
  };
  const isSelect = (list) => {
    return select?.id === list.id;
  };

  return (
    <div className={styles.container}>
      <h1>Overview</h1>
      <div className={styles.select_area}>
        <div className={styles.selectBox}>
          <div className={styles.dateContainer}>
            <div>Show</div>
            {select?.id === 1 && (
              <div>
                <span>
                  {moment(state?.startDate).isSame(state.endDate, "year")
                    ? moment(state?.startDate).format("MMM DD")
                    : moment(state?.startDate).format("MMM DD, YYYY")}
                </span>
                <span className="endDate">
                  {moment(state?.endDate).format("MMM DD, YYYY")}
                </span>
              </div>
            )}
            {select?.id === 2 && (
              <div>
                <span>
                  {moment(thirtyState?.startDate).isSame(
                    thirtyState?.endDate,
                    "year"
                  )
                    ? moment(thirtyState?.startDate).format("MMM DD")
                    : moment(thirtyState?.startDate).format("MMM DD, YYYY")}
                </span>

                <span className="endDate">
                  {moment(thirtyState?.endDate).format("MMM DD, YYYY")}
                </span>
              </div>
            )}
            {select?.id === 3 && (
              <div>
                <span>
                  {moment(allTimeState?.startDate).isSame(
                    allTimeState?.endDate,
                    "year"
                  )
                    ? moment(allTimeState?.startDate).format("MMM DD")
                    : moment(allTimeState?.startDate).format("MMM DD, YYYY")}
                </span>

                <span className="endDate">
                  {moment(allTimeState?.endDate).format("MMM DD, YYYY")}
                </span>
              </div>
            )}
            {select?.id === 4 && (
              <div>
                <span>
                  {moment(selectRange[0]?.startDate).isSame(
                    selectRange[0]?.endDate,
                    "year"
                  )
                    ? moment(selectRange[0]?.startDate).format("MMM DD")
                    : moment(selectRange[0]?.startDate).format("MMM DD, YYYY")}
                </span>
                <span className="endDate">
                  {moment(selectRange[0]?.endDate).format("MMM DD, YYYY")}
                </span>
              </div>
            )}
          </div>

          {dayList.map((d) => (
            <div
              onClick={() => {
                onClickDateSelect(d.id);
                setSelect(d);
              }}
              key={d.id}
              className={isSelect(d) ? styles.activeSelect : styles.selectDate}
            >
              {d.select}
            </div>
          ))}
        </div>
        {clickCalendar && (
          <div className={styles.calendarBox}>
            <div className={styles.calendar_block}>
              <DateRangePicker
                onChange={(item) => setSelectRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={selectRange}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                className={styles.calendarContainer}
                maxDate={subDays(new Date(moment().format("YYYY-MM-DD")), 4)}
              ></DateRangePicker>
              <button onClick={rangeDateUpdate} className={styles.updateBtn}>
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
