import { useEffect, useState } from "react";
import styles from "./InvoiceForm.module.scss";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";

import logo from "../../Img/Invoice/logo.png";
import moment from "moment";
import Cookies from "js-cookie";
export default function InvoiceForm({
  list,
  date,
}: {
  list: any;
  date: string;
}) {
  const { getBonus, getStudioInfo } = useInvoice();
  const [sumPrize, setSumPrize] = useState(0);
  const [studioInfo, setStudioInfo] = useState<any>({});
  const [bonus, setBonus] = useState<any>([]);

  useEffect(() => {
    let sum = bonus?.reduce((cur: any, sum: any) => cur + sum.profit, 0);
    let revenueShareSum = list?.detailGames?.reduce(
      (sum: any, cur: any) => sum + cur.payableRevenueShare,
      0
    );

    setSumPrize(() =>
      sum + revenueShareSum > 500 ? sum + revenueShareSum : 0
    );
  }, [bonus, list.detailGames]);

  useEffect(() => {
    let detailGames = list?.detailGames.map((item: any) => item.gameId);

    detailGames?.map((id: any) =>
      getBonus(id, date).then(async (res) => {
        if (res.status === 404) {
          setBonus([]);
        } else if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else {
          res?.data?.balances.map((item) =>
            setBonus((prev: any) => [...prev, item])
          );
        }
      })
    );
  }, [date, getBonus, list?.detailGames]);

  useEffect(() => {
    getStudioInfo(list?.studioId).then((res: any) => {
      if (res?.status === 200) {
        setStudioInfo(res.data);
      } else if (res?.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    });
  }, [getStudioInfo, list?.studioId]);

  return (
    <section className={styles.container}>
      <section className={styles.header}>
        <h2>Invoice</h2>
        <img src={logo} alt="logo" />
      </section>

      <section className={styles.company_info}>
        <h3>MondayOFF Inc.</h3>
        <section>
          <span>
            <p>14F, 465, Bongeunsa-ro,</p>
            <p>Gangnam-gu, Seoul,</p>
            <p>Republic of Korea</p>
            <p>VAT : 6268601144</p>
          </span>
          <span>
            <p>Phone : +82 10 7140 6347</p>
            <p>Fax : +82 050 7354 3106</p>
            <p>www.mondayoff.me</p>
          </span>
        </section>
      </section>
      <section className={styles.studio_info}>
        <span>
          <p>To</p>
          <p>{studioInfo?.studio?.paymentTo}</p>
          <p>{studioInfo?.studio?.businessAddress}</p>
          <span className={styles.studio_vat}>
            <p>VAT : </p> <p>&nbsp;{studioInfo?.studio?.vatOrGst}</p>
          </span>
          <span className={styles.studio_tel}>
            <p>Tel : </p> <p>&nbsp;{studioInfo?.studio?.phoneNumber}</p>
          </span>
          <span className={styles.studio_mail}>
            <p>Mail : </p>
            <p>&nbsp;{studioInfo?.studio?.email}</p>
          </span>
        </span>
        <span>
          <span className={styles.studio_issue_date}>
            <p>Issue Date</p>
            <p>
              {list.issueDate === "Not Available"
                ? "Not Available"
                : moment(list.issueDate).endOf("month").format("DD MMM YYYY")}
            </p>
          </span>
          <span className={styles.studio_due_date}>
            <p>Due Date</p>
            <p>
              {list.issueDate === "Not Available"
                ? "Not Available"
                : moment(list.issueDate)
                    .add(1, "month")
                    .endOf("month")
                    .format("DD MMM YYYY")}
            </p>
          </span>
        </span>
      </section>
      <section className={styles.invoice_item}>
        <h3>Invoice Item</h3>
        <table className={styles.invoice_item_table}>
          <thead>
            <tr>
              <th>Description</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {bonus?.map((item: any) =>
              item.profit !== 0 ? (
                <tr>
                  <td>
                    {item.description} -{" "}
                    {
                      list.detailGames.find(
                        (data: any) => data.gameId === item.gameId
                      ).gameName
                    }
                  </td>
                  <td>
                    {item.profit.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                </tr>
              ) : (
                <></>
              )
            )}
            {list.detailGames.map(
              (item: any) =>
                item.stage !== "SL" &&
                (item.payableRevenueShare ? (
                  <tr>
                    <td>
                      Revenue Share -{" "}
                      {
                        list.detailGames.find(
                          (data: any) => data.gameId === item.gameId
                        ).gameName
                      }
                    </td>
                    <td>
                      {item.payableRevenueShare.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </td>
                  </tr>
                ) : (
                  <></>
                ))
            )}
          </tbody>
        </table>
        <section className={styles.invoice_total}>
          <div className={styles.invoice_prize}>
            <p>Total Before Tax</p>
            <p>
              {sumPrize.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
          <div className={styles.invoice_tax}>
            <p>Tax(0.00%)</p>
            <p>$0.00</p>
          </div>
          <div className={styles.invoice_total_prize}>
            <p>Invoice Total (USD)</p>
            <p>
              {sumPrize.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
        </section>
      </section>
    </section>
  );
}
