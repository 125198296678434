import React, { useEffect } from "react";
import styles from "./InvoiceGameMorePopup.module.scss";
import { ReactComponent as EditIcon } from "../../Img/Invoice/edit.svg";
import { ReactComponent as InvoiceIcon } from "../../Img/Invoice/invoice.svg";
import { ReactComponent as EyeIcon } from "../../Img/Invoice/eye.svg";

import { useAuth } from "../../Context/AuthContext";
export default function InvoiceGameMorePopup({
  setIsEditInvoiceItemClick,
  setIsPreviewClick,
  setIsEditDetailsClick,
  setIsAddInvoiceItemClick,
  item,
  list,
  isRateInfo,
}) {
  const { invoiceUser } = useAuth();
  useEffect(() => {
    const popup = document.getElementsByClassName(styles.container);
    if (popup[0].children.length === 0) {
      popup[0].style.display = "none";
    }
  }, []);
  return (
    <section className={styles.container}>
      {invoiceUser.role === "SUPER" &&
        list.invoiceStatus !== "PAID" &&
        isRateInfo && (
          <>
            <button
              className={styles.edit_details_button}
              onClick={() => setIsEditDetailsClick(true)}
            >
              <EditIcon />
              <p>Edit Details</p>
            </button>
          </>
        )}
      {invoiceUser.role === "SUPER" && !item.isBonus && (
        <button
          className={styles.add_invoice_item_button}
          onClick={() => setIsAddInvoiceItemClick(true)}
        >
          <InvoiceIcon />
          <p>Add Invoice Item</p>
        </button>
      )}
      {item.isBonus && list.invoiceStatus !== "PAID" && isRateInfo && (
        <button
          className={styles.edit_button}
          onClick={() => setIsEditInvoiceItemClick(true)}
        >
          <EditIcon />
          <p>Edit Invoice item</p>
        </button>
      )}

      <button
        className={styles.preview_button}
        onClick={() => setIsPreviewClick(true)}
      >
        <EyeIcon />
        <p>Preview Monthly Statement</p>
      </button>
    </section>
  );
}
