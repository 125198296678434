import { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

import FinanceOverviewDataList from "../../components/FinanceOverviewDataList/FinanceOverviewDataList";
import FinanceOverviewSummary from "../../components/FinanceOverviewSummary/FinanceOverviewSummary";
import ReactCalander from "../../components/ReactCalander/ReactCalander";
import { useAuth } from "../../Context/AuthContext";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { deleteCookie, getCookie } from "../../db/cookie";
import styles from "./FinanceOverview.module.scss";

export default function FinanceOverview() {
  const { invoiceUser } = useAuth();
  const { getOverviewData } = useInvoice();
  const menuList: Array<{ id: number; menu: string }> = [
    { id: 1, menu: "7days" },
    { id: 2, menu: "30days" },
    { id: 3, menu: "Custom" },
  ];
  const [selectDay, setSelectDay] = useState<{ id: number }>({ id: 1 });
  const [selectDate, setSelectDate] = useState<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>({
    startDate: new Date(String(moment().subtract(10, "day"))),
    endDate: new Date(String(moment().subtract(3, "day"))),
    key: "selection",
  });
  const calanderRef = useRef<HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<TotalData>({
    totalAppleIAP: 0,
    totalGoogleIAP: 0,
    totalIAP: 0,
    totalInstalls: 0,
    totalProfit: 0,
    totalRevenue: 0,
    totalSpend: 0,
  });
  const [overviewList, setOverviewList] = useState<
    Array<FinanceOverviewGameData>
  >([]);
  const [isSelectCalander, setIsSelectCalander] = useState<boolean>(false);
  useEffect(() => {
    if (getCookie("Authentication")) {
      setIsLoading(true);
      const startDate = moment(selectDate.startDate).format("YYYY-MM-DD");
      const endDate = moment(selectDate.endDate).format("YYYY-MM-DD");
      getOverviewData(startDate, endDate).then((res: OverviewList) => {
        if (res.status === 200) {
          setIsLoading(false);
          setTotalData({
            totalAppleIAP: res.data.totalAppleIAP,
            totalGoogleIAP: res.data.totalGoogleIAP,
            totalIAP: res.data.totalIAP,
            totalInstalls: res.data.totalInstalls,
            totalProfit: res.data.totalProfit,
            totalRevenue: res.data.totalRevenue,
            totalSpend: res.data.totalSpend,
          });
          setOverviewList(res.data.overview);
        } else if (res.status === 400) {
          setIsLoading(false);
          alert(res.data.message);
        } else {
          deleteCookie("Authentication");
          if (!getCookie("Authentication")) {
            window.location.reload();
          }
        }
      });
    }
  }, [getOverviewData]);

  const updateData = useCallback((startDate: Date, endDate: Date) => {
    if (getCookie("Authentication")) {
      setIsLoading(true);
      getOverviewData(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      ).then((res: any) => {
        if (res.status === 401) {
          deleteCookie("Authentication");
          window.location.reload();
        } else if (res.status === 400) {
          setIsLoading(false);
          alert(res.data.message);
        } else {
          setIsLoading(false);
          setTotalData({
            totalAppleIAP: res.data.totalAppleIAP,
            totalGoogleIAP: res.data.totalGoogleIAP,
            totalIAP: res.data.totalIAP,
            totalInstalls: res.data.totalInstalls,
            totalProfit: res.data.totalProfit,
            totalRevenue: res.data.totalRevenue,
            totalSpend: res.data.totalSpend,
          });
          setOverviewList(res.data.overview);
        }
      });
    }
  }, []);

  const onCalanderRangeApply = useCallback(() => {
    setIsLoading(true);
    setIsSelectCalander(false);
    const startDate = moment(selectDate.startDate).format("YYYY-MM-DD");
    const endDate = moment(selectDate.endDate).format("YYYY-MM-DD");
    getOverviewData(startDate, endDate).then((res: any) => {
      if (res.status === 401) {
        deleteCookie("Authentication");
        window.location.reload();
      } else if (res.status === 400) {
        setIsLoading(false);
        alert(res.data.message);
      } else {
        setIsLoading(false);

        setTotalData({
          totalAppleIAP: res.data.totalAppleIAP,
          totalGoogleIAP: res.data.totalGoogleIAP,
          totalIAP: res.data.totalIAP,
          totalInstalls: res.data.totalInstalls,
          totalProfit: res.data.totalProfit,
          totalRevenue: res.data.totalRevenue,
          totalSpend: res.data.totalSpend,
        });
        setOverviewList(res.data.overview);
      }
    });
  }, [selectDate]);

  useEffect(() => {
    const calanderOutClickHandler = (e: any) => {
      if (calanderRef?.current?.contains(e.target)) {
        return setIsSelectCalander(true);
      } else {
        return setIsSelectCalander(false);
      }
    };
    document.addEventListener("mousedown", calanderOutClickHandler);
    return () =>
      document.removeEventListener("mousedown", calanderOutClickHandler);
  }, []);

  const isSelectMenu = (id: number) => {
    return selectDay.id === id;
  };
  const onDayBtnClick = async (id: number) => {
    switch (id) {
      case 1:
        setIsSelectCalander(false);
        setSelectDate({
          startDate: new Date(String(moment().subtract(10, "day"))),
          endDate: new Date(String(moment().subtract(3, "day"))),
          key: "selection",
        });
        updateData(
          new Date(String(moment().subtract(10, "day"))),
          new Date(String(moment().subtract(3, "day")))
        );
        setSelectDay({ id });
        return;
      case 2:
        setIsSelectCalander(false);
        setSelectDate({
          startDate: new Date(String(moment().subtract(33, "day"))),
          endDate: new Date(String(moment().subtract(3, "day"))),
          key: "selection",
        });

        updateData(
          new Date(String(moment().subtract(33, "day"))),
          new Date(String(moment().subtract(3, "day")))
        );
        setSelectDay({ id });
        return;

      case 3:
        setIsSelectCalander((prev) => !prev);
        setSelectDay({ id });
        return;
      default:
        setIsSelectCalander(false);

        setSelectDate({
          startDate: new Date(String(moment().subtract(10, "day"))),
          endDate: new Date(String(moment().subtract(3, "day"))),
          key: "selection",
        });
        updateData(
          new Date(String(moment().subtract(10, "day"))),
          new Date(String(moment().subtract(3, "day")))
        );
        setSelectDay({ id });
        return;
    }
  };
  return (
    <section className={styles.container}>
      <div className={styles.top_section}>
        <span className={styles.welcome_text}>
          <p>Hello, {invoiceUser.name}!👋</p>
        </span>
        <span className={styles.calander_area}>
          <section className={styles.select_date_view}>
            <p>Show</p>
            <p>
              {moment(selectDate.startDate).format("MMM DD, YYYY")} -{" "}
              {moment(selectDate.endDate).format("MMM DD, YYYY")}
            </p>
          </section>
          <section className={styles.select_date_btns}>
            {menuList.map((list) => (
              <button
                className={styles.day_btn}
                onClick={() => onDayBtnClick(list.id)}
                data-content={isSelectMenu(list.id) && "active"}
              >
                <p>{list.menu}</p>
              </button>
            ))}
          </section>
          {isSelectCalander && (
            <ReactCalander
              selectDate={selectDate}
              setSelectDate={setSelectDate}
              setIsSelectCalander={setIsSelectCalander}
              onCalanderRangeApply={onCalanderRangeApply}
              calanderRef={calanderRef}
            />
          )}
        </span>
      </div>
      <FinanceOverviewSummary data={totalData} isLoading={isLoading} />
      <FinanceOverviewDataList
        list={overviewList}
        isLoading={isLoading}
        selectDate={selectDate}
      />
    </section>
  );
}
