import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useMemo,
} from "react";

const GameStatusContext = createContext({} as GameStatusContextProp);
const contextRef = createRef();
export function GameStatusProvider({
  gameStatusService,
  children,
}: {
  gameStatusService: GameStatusServiceList;
  children: React.ReactNode;
}) {
  const getList = useCallback(async () => {
    return await gameStatusService.getList().then((res: GameStatusList) => {
      return res;
    });
  }, [gameStatusService]);
  const getSearchGameList = useCallback(
    async (gameName: string) => {
      return await gameStatusService
        .getSearchGameList(gameName)
        .then((res: GameStatusList) => {
          return res;
        });
    },
    [gameStatusService]
  );
  const getSearchStudioList = useCallback(
    async (studioName: string) => {
      return await gameStatusService
        .getSearchStudioList(studioName)
        .then((res: GameStatusList) => {
          return res;
        });
    },
    [gameStatusService]
  );
  const updateGameStatus = useCallback(
    async (data: any) => {
      return await gameStatusService
        .updateGameStatus(data)
        .then((res: any) => res);
    },
    [gameStatusService]
  );
  const terminateGame = useCallback(
    async (data: TerminateData) => {
      return await gameStatusService
        .terminateGame(data)
        .then((res: ResponseData) => res);
    },
    [gameStatusService]
  );

  const downloadMaxCohort = useCallback(
    async (data: Cohort) => {
      return await gameStatusService
        .downloadMaxCohort(data)
        .then((res: DataBlob) => {
          return res;
        });
    },
    [gameStatusService]
  );

  const downloadCostMZ = useCallback(
    async (data: Cohort) => {
      return await gameStatusService
        .downloadCostMZ(data)
        .then((res: DataBlob) => res);
    },
    [gameStatusService]
  );
  const downloadCostUA = useCallback(
    async (data: Cohort) => {
      return await gameStatusService
        .downloadCostUA(data)
        .then((res: DataBlob) => res);
    },
    [gameStatusService]
  );
  const context = useMemo(
    () => ({
      getList,
      getSearchGameList,
      getSearchStudioList,
      updateGameStatus,
      terminateGame,
      downloadMaxCohort,
      downloadCostMZ,
      downloadCostUA,
    }),
    [
      getList,
      getSearchGameList,
      getSearchStudioList,
      updateGameStatus,
      terminateGame,
      downloadMaxCohort,
      downloadCostMZ,
      downloadCostUA,
    ]
  );

  return (
    <GameStatusContext.Provider value={context}>
      {children}
    </GameStatusContext.Provider>
  );
}

export default GameStatusProvider;
export const fetchToken = () => contextRef.current;
export const useGameStatus = () => useContext(GameStatusContext);
