import { useCallback } from "react";
import styles from "./SearchAppList.module.scss";
import { ReactComponent as SearchIcon } from "../../Img/GameStatus/search.svg";
import { ReactComponent as DeleteIcon } from "../../Img/GameStatus/delete.svg";

export default function SearchAppList({
  isTypeSelect,
  isStatusSelect,
  setIsTypeSelect,
  setIsStatusSelect,
  setsearchText,
  searchText,
  typeList,
  onKeyPress,
}) {
  const isTypeSelected = useCallback(
    (id) => {
      return isTypeSelect.id === id;
    },
    [isTypeSelect.id]
  );
  const onSearchTextChange = (e) => {
    setsearchText(e.target.value);
  };

  const onDeleteClick = () => {
    return setsearchText("");
  };
  return (
    <div className={styles.container}>
      <span className={styles.search_area}>
        <label className={styles.search_box}>
          <SearchIcon />
          <input
            type="text"
            placeholder="Search"
            onChange={onSearchTextChange}
            value={searchText}
            onKeyDown={onKeyPress}
          />
          <DeleteIcon onClick={onDeleteClick} />
        </label>
        <ul className={styles.menu_list}>
          {typeList.map((item) => (
            <li
              onClick={() => setIsTypeSelect({ id: item.id })}
              select-menu={isTypeSelected(item.id) ? "active" : "inactive"}
              key={Math.random(item.id)}
            >
              {item.menu}
            </li>
          ))}
        </ul>
      </span>
    </div>
  );
}
