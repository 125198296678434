import axios from "axios";

export default class MaxService {
  http: any;
  tokenStorage: string;
  constructor(http: any, tokenStorage: string) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }

  async getMaxData(appId: string) {
    return await this.http
      .axios(`/max/${appId}`, {
        method: "GET",
      })
      .then((res: MaxDataList) => {
        return res.data;
      })
      .catch((e: any) => e.response);
  }
  async getNewMaxData(id: number) {
    return await axios(
      `https://server.mondayoff.me/test/admin/appInfo/detail/max?id=${id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.data)
      .catch((e) => e.toJSON());
  }
}
