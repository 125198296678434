import React from "react";
import styles from "./FinanceOverviewSummary.module.scss";

import FinanceOverviewSummarySkeleton from "../FinanceOverviewSummarySkeleton/FinanceOverviewSummarySkeleton";
export default function FinanceOverviewSummary({ data, isLoading }) {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Summary</h2>
      {isLoading ? (
        <FinanceOverviewSummarySkeleton />
      ) : (
        <section className={styles.data_section}>
          <span className={styles.ad_revenue}>
            <div className={styles.data_name}>
              <p>Ad Revenue</p>
            </div>
            <div className={styles.data}>
              {data?.totalRevenue?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
          <span className={styles.iap_revenue}>
            <div className={styles.data_name}>
              <p>IAP Revenue</p>
            </div>
            <div className={styles.data}>
              {data?.totalIAP?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
          <span className={styles.spend}>
            <div className={styles.data_name}>
              <p>Spend</p>
            </div>
            <div className={styles.data}>
              {data?.totalSpend?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
          <span className={styles.prifit}>
            <div className={styles.data_name}>
              <p>Profit</p>
            </div>
            <div className={styles.data}>
              {data?.totalProfit?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </span>
        </section>
      )}
    </section>
  );
}
