import axios from "axios";
import { getCookie } from "../../db/cookie";

export default class MetaCampaignService {
  constructor(http) {
    this.http = http;
  }
  async getProgress() {
    return await this.http
      .axios(`/test/admin/game/inprogress`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async getComplete() {
    return await this.http
      .axios(`/test/admin/game/complete`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  async getCampaignDetail(appInfoId) {
    return await this.http
      .axios(`/test/admin/appInfo/detail/campaign?id=${appInfoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  async appInfo(id) {
    return await axios(`${this.http}/test/admin/appInfo/detail/game?id=${id}`, {
      method: "GET",
    })
      .then((res) => res.data)
      .catch((e) => e.toJSON());
  }
}
