import axios from "axios";

export default async function CheckIcon(url) {
  let data = await axios
    .get(url)
    .then((res) => res)
    .catch((e) => e.response);
  let isIcon = (await data.status) === 403 ? false : true;
  return isIcon;
}
