import React, { useEffect, useState, useRef } from "react";
import fileDownload from "js-file-download";
import styles from "./InvoiceList.module.scss";
import { ReactComponent as MoreIcon } from "../../Img/Invoice/more.svg";
import { ReactComponent as PlusIcon } from "../../Img/Invoice/plus.svg";
import { ReactComponent as CheckIcon } from "../../Img/Invoice/check.svg";
import { ReactComponent as ExpandIcon } from "../../Img/Invoice/expand.svg";
import { ReactComponent as EyeIcon } from "../../Img/Invoice/eye.svg";
import { ReactComponent as InvoiceIcon } from "../../Img/Invoice/invoice.svg";
import { ReactComponent as EditIcon } from "../../Img/Invoice/edit.svg";
import { ReactComponent as DownIcon } from "../../Img/Invoice/download.svg";
import InvoiceGameList from "../InvoiceGameList/InvoiceGameList";
import EditStudioInfo from "../EditStudioInfo/EditStudioInfo";
import ConfirmInvoice from "../ConfirmInvoice/ConfirmInvoice";
import PreviewInvoice from "../PreviewInvoice/PreviewInvoice";
import { useAuth } from "../../Context/AuthContext";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { deleteCookie } from "../../db/cookie";
import Cookies from "js-cookie";

export default function InvoiceList({ list, date, isAddRateInfo }) {
  const { invoiceUser } = useAuth();
  const { addRemittance, downloadInvoice, downLoadRemittance } = useInvoice();

  const listRef = useRef(null);
  const moreRef = useRef(null);
  const fileRef = useRef(null);
  const editButtonRef = useRef(null);
  const [gameList, setGameList] = useState([]);
  const [isStudioClick, setIsStudioClick] = useState(false);
  const [isCheckInvoiceClick, setIsCheckInvoiceClick] = useState(false);
  const [isMoreClick, setIsMoreClick] = useState(false);
  const [isEditStudioInfoClick, setIsEditStudioInfoClick] = useState(false);
  const [isPreviewInvoiceClick, setIsPreviewInvoiceClick] = useState(false);
  const [sumAmount, setSumAmount] = useState(0);
  const [invoiceStatus, setInvoiceStatus] = useState("");

  useEffect(() => {
    setGameList(list?.detailGames);
  }, [list?.detailGames]);
  useEffect(() => {
    let handleOutsideClick = (e) => {
      if (!editButtonRef?.current?.contains(e.target)) {
        setIsMoreClick(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  useEffect(() => {
    let sum = list?.detailGames
      .map((item) => item?.amount)
      .reduce((acc, cur) => acc + cur);
    setSumAmount(sum);
  }, [list?.detailGames]);
  useEffect(() => {
    switch (list?.invoiceStatus) {
      case "NOT_AVAILABLE":
        setInvoiceStatus("N/A");
        return;
      case "INSUFFICIENT":
        setInvoiceStatus("N/A");
        return;
      case "INCOMPLETE":
        setInvoiceStatus("Incomplete");
        return;
      case "FINALIZING":
        setInvoiceStatus("Finalizing");
        return;
      case "PENDING":
        setInvoiceStatus("Pending");
        return;
      case "PAID":
        setInvoiceStatus("Paid");
        return;
      case "IN_REVIEW":
        setInvoiceStatus("In Review");
        return;
      default:
        setInvoiceStatus(list?.invoiceStatus);
        return;
    }
  }, [list?.invoiceStatus]);
  const onViewGameListClick = () => {
    setIsStudioClick((prev) => !prev);
  };

  const onUploadRemittance = () => {
    let formData = new FormData();
    formData.append("file", fileRef.current.files[0]);
    formData.append("studioId", list.studioId);
    formData.append("month", date);
    return addRemittance(formData).then((res) => {
      if (res.status === 201) {
        window.location.reload();
      } else if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    });
  };
  const onInvoceDownClick = () => {
    return downloadInvoice(list.studioId, date).then((res) => {
      if (res.status === 400) {
        alert(res.data.message);
      } else if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        fileDownload(res.data, `Invoice-${list.studioName}-${date}.pdf`);
      }
    });
  };
  const onRemittanceDownClick = () => {
    return downLoadRemittance(list.studioId, date).then((res) => {
      if (res.status === 400) {
        alert(res.data.message);
      } else if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        fileDownload(res.data, `Remittance-${list.studioName}-${date}.pdf`);
      }
    });
  };

  return (
    <>
      <li className={styles.container} ref={listRef}>
        {isEditStudioInfoClick && (
          <EditStudioInfo
            setIsEditStudioInfoClick={setIsEditStudioInfoClick}
            list={list}
          />
        )}
        {isCheckInvoiceClick && (
          <ConfirmInvoice
            setIsCheckInvoiceClick={setIsCheckInvoiceClick}
            date={date}
            list={list}
          />
        )}
        {isPreviewInvoiceClick && (
          <PreviewInvoice
            setIsPreviewInvoiceClick={setIsPreviewInvoiceClick}
            date={date}
            list={list}
          />
        )}

        {isMoreClick && (
          <ul className={styles.edit_popup} ref={moreRef}>
            {invoiceUser.role === "SUPER" &&
              invoiceStatus !== "Paid" &&
              invoiceStatus !== "N/A" &&
              isAddRateInfo && (
                <li
                  onClick={() => {
                    setIsCheckInvoiceClick(true);
                  }}
                >
                  <InvoiceIcon className={styles.invoice_icon} />
                  <p>Check Invoice</p>
                </li>
              )}
            <li onClick={() => setIsEditStudioInfoClick(true)}>
              <EditIcon />
              <p>Edit Studio Info</p>
            </li>
            {list.isInvoice && invoiceStatus !== "N/A" && (
              <li onClick={() => setIsPreviewInvoiceClick(true)}>
                <EyeIcon />
                <p>Preview Invoice</p>
              </li>
            )}
            {list.isInvoice && invoiceStatus !== "N/A" && (
              <li onClick={onInvoceDownClick}>
                <DownIcon className={styles.invoice_download} />
                <p>Download Invoice</p>
              </li>
            )}
            {list.isRemittance && invoiceStatus !== "N/A" && (
              <li onClick={onRemittanceDownClick}>
                <DownIcon className={styles.invoice_download} />
                <p>Download Remittance Doc</p>
              </li>
            )}
          </ul>
        )}
        <span className={styles.name}>
          <ExpandIcon
            onClick={onViewGameListClick}
            data-content={isStudioClick && "active"}
            className={styles.expand_icon}
          />
          <div className={styles.gameIcon} />
          <p>{list?.studioName}</p>
        </span>
        <span className={styles.gameStatus}>
          {list?.clGameCount > 0 && (
            <span className={styles.CLCount}>
              <p>CL</p>
              <p>{list?.clGameCount}</p>
            </span>
          )}
          {list?.slGameCount > 0 && (
            <span className={styles.SLCount}>
              <p>SL</p>
              <p>{list?.slGameCount}</p>
            </span>
          )}
          {list?.etcGameCount > 0 && (
            <span className={styles.ETCCount}>
              <p>ETC</p>
              <p>{list?.etcGameCount}</p>
            </span>
          )}
        </span>
        <p
          className={styles.paymentInfo}
          data-content={list?.paymentInfomation}
        >
          {list?.paymentInfomation}
        </p>
        <p className={styles.amount}>
          {sumAmount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className={styles.issueDate}>{list?.issueDate}</p>
        <p className={styles.invoiceInfo} data-content={invoiceStatus}>
          {invoiceStatus}
        </p>

        {invoiceUser.role === "SUPER" ? (
          invoiceStatus === "In Review" ? (
            <div className={styles.remittance_invalid}>
              <PlusIcon className={styles.invalid} />
              <p>Remittance Doc</p>
            </div>
          ) : invoiceStatus === "Pending" ? (
            <label className={styles.remittance_active}>
              <PlusIcon className={styles.active} />
              <p>Remittance Doc</p>
              <input
                type="file"
                accept=".pdf"
                className={styles.remittance_file}
                ref={fileRef}
                onChange={onUploadRemittance}
              />
            </label>
          ) : invoiceStatus === "Paid" ? (
            <div className={styles.remittance_added}>
              <CheckIcon />
              <p>Doc Added</p>
            </div>
          ) : (
            <div></div>
          )
        ) : list.isStudioDetail ? (
          !list.isInvoice ? (
            <button
              className={styles.action}
              onClick={() => setIsCheckInvoiceClick(true)}
            >
              Check Invoice
            </button>
          ) : (
            <div className={styles.confirmed_invoice}>Confirmed</div>
          )
        ) : (
          <div></div>
        )}
        <button
          className={styles.more}
          onClick={() => setIsMoreClick((prev) => !prev)}
          ref={editButtonRef}
        >
          <MoreIcon />
        </button>
      </li>

      {isStudioClick && (
        <ul className={styles.game_list}>
          {gameList?.map((item) => (
            <InvoiceGameList
              item={item}
              date={date}
              list={list}
              isRateInfo={isAddRateInfo}
              status={list?.invoiceStatus}
            />
          ))}
        </ul>
      )}
    </>
  );
}
