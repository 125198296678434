import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { getCookie } from "../db/cookie";

const AppInfoContext = createContext({} as AppInfoContextProps);
const contextRef = createRef();
export function AppInfoProvider({
  appInfoService,
  children,
}: {
  appInfoService: AppInfoServiceList;
  children: React.ReactNode;
}) {
  const [selectList, setSelectList] = useState<Array<AppInfoData>>([]);
  const [dataList, setDataList] = useState<Array<AppInfoData>>([]);
  const getAppInfo = useCallback(async () => {
    return await appInfoService.getAppInfo().then((res: any) => {
      if (getCookie("id")) {
        setSelectList(
          res.find((item: AppInfoData) => item.id === Number(getCookie("id")))
        );
      } else {
        setSelectList(res[0]);
      }
      return setDataList(res);
    });
  }, [appInfoService]);
  const updateMaxAdUnitId = useCallback(
    (data: any) => {
      return appInfoService.updateMaxAdUnitId(data);
    },
    [appInfoService]
  );
  const createAppInfo = useCallback(
    async (appInfo: {
      gameId: number;
      platform: string;
      bundleId: string;
      storeId: string;
    }) => {
      return await appInfoService.createAppInfo(appInfo);
    },
    [appInfoService]
  );
  const updateGoogleMZId = useCallback(
    async (appId: string) => {
      return await appInfoService.updateGoogleMZId(appId);
    },
    [appInfoService]
  );
  useEffect(() => {
    getAppInfo();
  }, [getAppInfo]);
  const context = useMemo(
    () => ({
      selectList,
      dataList,
      setSelectList,
      updateMaxAdUnitId,
      createAppInfo,
      updateGoogleMZId,
      getAppInfo,
    }),
    [
      dataList,
      selectList,
      updateMaxAdUnitId,
      createAppInfo,
      updateGoogleMZId,
      getAppInfo,
    ]
  );
  return (
    <AppInfoContext.Provider value={context}>
      {children}
    </AppInfoContext.Provider>
  );
}

export default AppInfoContext;
export const fetchToken = () => contextRef.current;
export const useAppInfo = () => useContext(AppInfoContext);
