import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useKeyEscClose } from "../../common/escClose/escClose";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";

import styles from "./EditStudioInfo.module.scss";
export default function EditStudioInfo({ setIsEditStudioInfoClick, list }) {
  const [studioInfo, setStudioInfo] = useState({});
  const [isStudioEditClick, setIsStudioEditClick] = useState(false);
  const [editInfo, setEditInfo] = useState({
    studio: {
      id: list?.studioId,
      name: list?.studioName,
      paymentTo: "",
      email: "",
      businessAddress: "",
      phoneNumber: "",
      vatOrGst: "",
    },
    payment: {
      userId: list?.userId,
      bankName: "",
      bankAddress: "",
      accountName: "",
      accountNumber: "",
      swiftCode: "",
      branchCode: "",
      ifscCode: "",
      routingNumber: "",
    },
  });
  useKeyEscClose(() => {
    setIsEditStudioInfoClick(false);
  });
  useEffect(() => {
    setEditInfo({
      studio: {
        id: list?.studioId,
        name: list?.studioName,
        paymentTo: studioInfo?.studio?.paymentTo ?? "",
        email: studioInfo?.studio?.email ?? "",
        businessAddress: studioInfo?.studio?.businessAddress ?? "",
        phoneNumber: studioInfo?.studio?.phoneNumber ?? "",
        vatOrGst: studioInfo?.studio?.vatOrGst ?? "",
      },
      payment: {
        studioId: list?.studioId,
        bankName: studioInfo?.payment?.bankName ?? "",
        bankAddress: studioInfo?.payment?.bankAddress ?? "",
        accountName: studioInfo?.payment?.accountName ?? "",
        accountNumber:
          studioInfo?.payment?.accountNumber.replace(/(\s*)/g, "") ?? "",
        swiftCode: studioInfo?.payment?.swiftCode ?? "",
        branchCode: studioInfo?.payment?.branchCode ?? "",
        ifscCode: studioInfo?.payment?.ifscCode ?? "",
        routingNumber: studioInfo?.payment?.routingNumber ?? "",
      },
    });
  }, [studioInfo, list]);

  const { getStudioInfo, editStudioInfo } = useInvoice();
  useEffect(() => {
    getStudioInfo(list?.studioId).then((res) => {
      if (res.status === 200) {
        setStudioInfo(res.data);
      } else if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    });
  }, [getStudioInfo, list?.studioId]);
  const onStudioInfoChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "edit_studio_name":
        setEditInfo({
          ...editInfo,
          studio: { ...editInfo.studio, paymentTo: value },
        });
        return;
      case "edit_studio_email":
        setEditInfo({
          ...editInfo,
          studio: { ...editInfo.studio, email: value },
        });
        return;
      case "edit_studio_address":
        setEditInfo({
          ...editInfo,
          studio: { ...editInfo.studio, businessAddress: value },
        });
        return;
      case "edit_studio_phone_number":
        setEditInfo({
          ...editInfo,
          studio: { ...editInfo.studio, phoneNumber: value },
        });
        return;
      case "edit_studio_vat":
        setEditInfo({
          ...editInfo,
          studio: { ...editInfo.studio, vatOrGst: value },
        });
        return;
      case "edit_studio_bank_name":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, bankName: value },
        });
        return;
      case "edit_studio_bank_address":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, bankAddress: value },
        });
        return;
      case "edit_studio_account_name":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, accountName: value },
        });
        return;
      case "edit_studio_account_number":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, accountNumber: value },
        });
        return;
      case "edit_studio_swift_code":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, swiftCode: value },
        });
        return;
      case "edit_studio_branch_code":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, branchCode: value },
        });
        return;
      case "edit_studio_ifsc_code":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, ifscCode: value },
        });
        return;
      case "edit_studio_routing_number":
        setEditInfo({
          ...editInfo,
          payment: { ...editInfo.payment, routingNumber: value },
        });
        return;
      default:
        return;
    }
  };
  const editInfoClick = () => {
    if (!editInfo?.studio?.paymentTo?.length) {
      alert("Please check the studio name");
    } else if (!editInfo?.studio?.businessAddress?.length) {
      alert("Please check the studio address");
    } else if (!editInfo?.studio?.phoneNumber?.length) {
      alert("Please check the studio phone number");
    } else if (!editInfo?.payment?.bankName?.length) {
      alert("Please check the studio bank name");
    } else if (!editInfo?.payment?.bankAddress?.length) {
      alert("Please check the studio bank address");
    } else if (!editInfo?.payment?.bankName?.length) {
      alert("Please check the studio bank name");
    } else if (!editInfo?.payment?.accountName?.length) {
      alert("Please check the studio account name");
    } else if (!editInfo?.payment?.accountNumber?.length) {
      alert("Please check the studio account number");
    } else if (!editInfo?.payment?.swiftCode?.length) {
      alert("Please check the studio swift code");
    } else {
      setIsStudioEditClick(true);
      editStudioInfo(editInfo).then((res) => {
        if (res.status === 201) {
          window.location.reload();
        } else if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else {
          alert(res.data.message);
          setIsStudioEditClick(false);
        }
      });
    }
  };

  return (
    <section className={styles.container}>
      <section className={styles.contents}>
        <h2>Edit Studio Info</h2>
        <section className={styles.studio_info}>
          <div className={styles.studio_profile_img} />
          <span className={styles.studio_name_email}>
            <p>{list?.studioName}</p>
            <p>{editInfo?.studio?.email}</p>
          </span>
        </section>
        <p>Studio Detail</p>
        <section className={styles.studio_detail_edit}>
          <span className={styles.studio_name}>
            <p>
              Payment To <p>*</p>
            </p>
            <input
              type="text"
              placeholder="Licensor Name"
              id="edit_studio_name"
              value={editInfo?.studio?.paymentTo ?? ""}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.studio_email}>
            <p>
              Email<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Email"
              id="edit_studio_email"
              value={editInfo?.studio?.email ?? ""}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.studio_address}>
            <p>
              Studio Address<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Studio Address"
              id="edit_studio_address"
              value={editInfo?.studio?.businessAddress}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.studio_phone}>
            <p>
              Phone Number<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Phone Number"
              id="edit_studio_phone_number"
              value={editInfo?.studio?.phoneNumber}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.studio_vat_gst}>
            <p>Studio VAT / GST</p>
            <input
              type="text"
              placeholder="Studio VAT / GST"
              id="edit_studio_vat"
              value={editInfo?.studio?.vatOrGst}
              onChange={onStudioInfoChange}
            />
          </span>
        </section>
        <p>Payment Details</p>
        <section className={styles.payment_detail_edit}>
          <span className={styles.bank_name}>
            <p>
              Bank Name<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Bank Name"
              id="edit_studio_bank_name"
              value={editInfo?.payment?.bankName}
              onChange={onStudioInfoChange}
            />
          </span>

          <span className={styles.bank_address}>
            <p>
              Bank Address<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Bank Address"
              id="edit_studio_bank_address"
              value={editInfo?.payment?.bankAddress}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.account_name}>
            <p>
              Bank Account Name<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Account Name"
              id="edit_studio_account_name"
              value={editInfo?.payment?.accountName}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.account_number}>
            <p>
              Account Number (IBAN Number)<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Account Number (IBAN Number)"
              id="edit_studio_account_number"
              value={editInfo?.payment?.accountNumber}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.swift_code}>
            <p>
              Swift Code<p>*</p>
            </p>
            <input
              type="text"
              placeholder="Swift Code"
              id="edit_studio_swift_code"
              value={editInfo?.payment?.swiftCode}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.branch_code}>
            <p>Branch Code</p>
            <input
              type="text"
              placeholder="Branch Code"
              id="edit_studio_branch_code"
              value={editInfo?.payment?.branchCode}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.ifsc_code}>
            <p>IFSC Code</p>
            <input
              type="text"
              placeholder="IFSC Code"
              id="edit_studio_ifsc_code"
              value={editInfo?.payment?.ifscCode}
              onChange={onStudioInfoChange}
            />
          </span>
          <span className={styles.rounting_number}>
            <p>Routing Number</p>
            <input
              type="text"
              placeholder="Routing Number"
              id="edit_studio_routing_number"
              value={editInfo?.payment?.routingNumber}
              onChange={onStudioInfoChange}
            />
          </span>
        </section>
        <section className={styles.buttons}>
          <button onClick={() => setIsEditStudioInfoClick(false)}>
            <p>Close</p>
          </button>
          <button onClick={isStudioEditClick ? undefined : editInfoClick}>
            <p>Edit</p>
          </button>
        </section>
      </section>
    </section>
  );
}
