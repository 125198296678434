export default class AppInfoService {
  http: any;
  constructor(http: any) {
    this.http = http;
  }
  async createAppInfo(appInfoData: {
    gameId: number;
    platform: string;
    bundleId: string;
    storeId: string;
  }) {
    return await this.http
      .axios("/", {
        method: "POST",
        data: JSON.stringify(appInfoData),
      })
      .then((res: ResponseData) => res)
      .catch((e: any) => {
        return e;
      });
  }
  async getAppInfo() {
    return await this.http
      .axios("/", {
        method: "GET",
      })
      .then((res: AppInfoResponse) => res.data);
  }
  async updateAppInfo(appInfo: any) {
    return await this.http
      .axios("/update", {
        method: "POST",
        data: JSON.stringify(appInfo),
      })
      .then((res: any) => {
        return res;
      });
  }
  async updateGoogleMZId(appId: string) {
    return await this.http
      .axios(`/googleMZId`, {
        method: "POST",
        data: JSON.stringify({ appId }),
      })
      .then((res: ResponseData) => res);
  }
  async updateMaxAdUnitId(data: any) {
    return await this.http
      .axios(`/maxAdUnitId`, {
        method: "POST",
        data: JSON.stringify(data),
      })
      .then((res: ResponseData) => res);
  }
}
