import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import styles from "./InvoiceListLoading.module.scss";

const InvoiceListLoading = () => {
  return (
    <>
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>{" "}
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>{" "}
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>{" "}
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>{" "}
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>
      <li className={styles.container}>
        <span className={styles.name_section}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_icon}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            className={styles.game_name}
          />
        </span>
        <span className={styles.game_status_section}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.payment_info}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.amount}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.issue_date}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.invoice_status}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
        <span className={styles.action}>
          <Skeleton animation="wave" variant="rectangular" />
        </span>
      </li>
    </>
  );
};

export default InvoiceListLoading;
