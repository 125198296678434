import React from "react";
import styles from "./PlayTimeData.module.scss";
const PlayTimeData = ({ d0PlayTime }) => {
  return (
    <div className={styles.container}>
      <div>D0 Playtime</div>
      <div>{d0PlayTime !== 0 ? d0PlayTime : "-"}</div>
    </div>
  );
};

export default PlayTimeData;
