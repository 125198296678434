import React, { useState, useEffect, useRef } from "react";
import styles from "./AddInvoiceModal.module.scss";
import searchIcon from "../../Img/Invoice/search.png";
import closeIcon from "../../Img/Invoice/close.png";
import selectedCancelIcon from "../../Img/Invoice/selected_cancel.png";
import AddInvoiceItem from "../AddInvoiceItem/AddInvoiceItem";
import { useInvoice } from "../../Context/NewContext/InvoiceContext";
import { deleteCookie } from "../../db/cookie";
import { ReactComponent as PlusIcon } from "../../Img/Invoice/plus.svg";
import { useKeyEscClose } from "../../common/escClose/escClose";
import InvoiceSearchLoading from "../../common/InvoiceSearchLoading/InvoiceSearchLoading";
import Cookies from "js-cookie";
import InvoiceAddEditLoading from "../../common/InvoiceAddEditLoading/InvoiceAddEditLoading";
export default function AddInvoiceModal({ setIsAddInvoiceClick, date }) {
  const { searchList, testAddBonus } = useInvoice();
  const [invoiceItem, setInvoiceItem] = useState([
    { id: 1, type: 1, item: "", prize: 0 },
  ]);
  const [addGameBonus, setAddGameBonus] = useState({});
  const [bonusSumPrize, setBonusSumPrize] = useState(0);
  const [selectResultListItem, setSelectResultListItem] = useState({});
  const [searchResultList, setSearchResultList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSearchList, setIsSearchList] = useState(false);
  const [isBonusAddClick, setIsBonusAddClick] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isSelectSearchType, setIsSelectSearchType] = useState({
    id: 1,
    type: "Game",
  });
  const searchRef = useRef(null);
  useEffect(() => {
    setBonusSumPrize(() =>
      invoiceItem.reduce((acc, cur) => acc + cur.prize, 0)
    );
  }, [invoiceItem]);
  useEffect(() => {
    setAddGameBonus(() =>
      invoiceItem.map((data) => ({
        month: date,
        description: data.item,
        type: data.type === 1 ? "DEDUCT_BONUS" : "NON_DEDUCT_BONUS",
        profit: data.prize,
        gameStage: selectResultListItem.gameStage,
        gameId: selectResultListItem.gameId,
        userId: selectResultListItem.userId,
      }))
    );
  }, [
    invoiceItem,
    selectResultListItem.gameId,
    selectResultListItem.userId,
    date,
    selectResultListItem.gameStage,
  ]);
  const onKeyPress = (e) => {
    if (!searchText.length && e.keyCode === 13) {
      return;
    }
    if (e.keyCode === 13) {
      setIsSearchList(true);
      let type = isSelectSearchType.id === 1 ? "game" : "studio";
      searchList(type, searchText).then((res) => {
        if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else {
          setIsSearchList(false);
          setSearchResultList(res.data.games);
        }
      });
    }
  };

  const onBonusAddClick = () => {
    setIsBonusAddClick(true);
    if (!Object.keys(selectResultListItem).length) {
      setIsBonusAddClick(false);
      alert("Please select the game");
    } else {
      testAddBonus({ bonus: addGameBonus, month: date }).then((res) => {
        if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else if (res.status === 400) {
          setIsBonusAddClick(false);
          alert(res.data.message);
        } else {
          setIsBonusAddClick(false);
          window.location.reload();
        }
      });
    }
  };
  const onSelectListItemClick = (item) => {
    setSelectResultListItem(item);
  };
  const isSelectItem = (list) => {
    return list.gameId === selectResultListItem.gameId;
  };
  const searchTypeList = [
    { id: 1, type: "Game" },
    { id: 2, type: "Studio" },
  ];
  const onAddClick = () => {
    setInvoiceItem([
      ...invoiceItem,
      { id: invoiceItem.length + 1, type: 1, item: "", prize: 0 },
    ]);
  };
  const isSelect = (id) => {
    return isSelectSearchType.id === id;
  };
  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  useKeyEscClose(() => {
    setIsAddInvoiceClick(false);
  });
  useEffect(() => {
    if (document.activeElement === searchRef.current) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, []);

  return (
    <section className={styles.container}>
      <article className={styles.contents}>
        <h2 className={styles.contents_title}>Add Invoice</h2>
        <div className={styles.search_area}>
          <div className={styles.search_box}>
            <label className={styles.search}>
              <img src={searchIcon} alt="icon" />
              <input
                type="text"
                placeholder="Search"
                onChange={onSearchTextChange}
                onKeyDown={onKeyPress}
                value={searchText}
                ref={searchRef}
              />
              <img
                src={closeIcon}
                alt="icon"
                onClick={() => setSearchText("")}
              />
            </label>
            <span className={styles.search_type_button}>
              {searchTypeList?.map((item) => (
                <button
                  onClick={() => setIsSelectSearchType(item)}
                  data-content={isSelect(item.id) && "active"}
                >
                  {item.type}
                </button>
              ))}
            </span>
          </div>

          <div
            className={styles.search_result}
            data-content={
              isSearchList ||
              (searchResultList.length && !selectResultListItem.gameId)
                ? "active"
                : "inactive"
            }
          >
            {isSearchList ? (
              <InvoiceSearchLoading />
            ) : (
              <ul className={styles.search_result_list}>
                {searchResultList.map((list) => (
                  <li
                    key={list.id}
                    className={styles.search_result_item}
                    onClick={() => onSelectListItemClick(list)}
                    data-content={isSelectItem(list) ? "active" : "inactive"}
                  >
                    {/* <img src={list.gameIconUrl} alt="icon" /> */}
                    <div className={styles.game_icon} />
                    <p>{list.gameName}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {selectResultListItem.gameId ? (
          <div className={styles.selected_item}>
            <span>
              <div className={styles.game_icon} />
              <p>{selectResultListItem.gameName}</p>
            </span>

            <img
              src={selectedCancelIcon}
              alt="icon"
              className={styles.cancel_icon}
              onClick={() => setSelectResultListItem({})}
            />
          </div>
        ) : (
          <div className={styles.game_item}>
            <p className={styles.no_select_item}>No Game Selected</p>
          </div>
        )}

        <div className={styles.invoice_item}>
          <p className={styles.invoice_item_title}>Invoice Item</p>

          <ul className={styles.invoice_item_list}>
            {invoiceItem?.map((list) => {
              return (
                <AddInvoiceItem
                  list={list}
                  key={list.id}
                  invoiceItem={invoiceItem}
                  setInvoiceItem={setInvoiceItem}
                />
              );
            })}
          </ul>
          <div
            className={styles.invoice_item_list_add_button}
            onClick={isBonusAddClick ? undefined : onAddClick}
          >
            <PlusIcon className={styles.add_icon} />
            <p>Add Item</p>
          </div>
          <section className={styles.total_bonus_prize}>
            <div>
              <p>Total Amount</p>
              <p>
                {bonusSumPrize.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            </div>
          </section>
        </div>
        <div className={styles.button_area}>
          <div
            className={styles.cancel_button}
            onClick={() =>
              isBonusAddClick ? undefined : setIsAddInvoiceClick(false)
            }
          >
            Cancel
          </div>
          <div
            className={styles.add_button}
            onClick={isBonusAddClick ? undefined : onBonusAddClick}
          >
            {isBonusAddClick ? <InvoiceAddEditLoading /> : "Add"}
          </div>
        </div>
      </article>
    </section>
  );
}
//
