import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import Cookies from "js-cookie";
import GameStatusList from "../../components/GameStatusList/GameStatusList";
import GameStatusUpdateModal from "../../components/GameStatusUpdateModal/GameStatusUpdateModal";
import SearchAppList from "../../components/SearchAppList/SearchAppList";
import TerminateModal from "../../components/TerminateModal/TerminateModal";
import { useGameStatus } from "../../Context/NewContext/GameStatusContext";
import styles from "./GameStatus.module.scss";

export default function GameStatus() {
  const {
    getList,
    getSearchGameList,
    getSearchStudioList,
    downloadMaxCohort,
    downloadCostMZ,
    downloadCostUA,
  } = useGameStatus();
  const [isLoading, setIsLoaing] = useState<boolean>(false);
  const [gameList, setGameList] = useState<Array<GameInfo>>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isTerminate, setIsTerminate] = useState(false);
  const [searchText, setsearchText] = useState<string>("");
  const [isTypeSelect, setIsTypeSelect] = useState({ id: 1 });
  const [downCohortData, setDownCohortData] = useState<Cohort>({
    bundleId: "",
    startDate: "",
    endDate: "",
    country: "",
    platform: "",
  });
  const [downCostData, setDownCostData] = useState<{
    bundleId: string;
    startDate: string;
    endDate: string;
  }>({
    bundleId: "",
    startDate: "",
    endDate: "",
  });

  const [selectItem, setSelectItem] = useState<number>(0);
  const [downLoading, setDownLoading] = useState<boolean>(false);
  const [mzDownLoading, setMzDownLoading] = useState<boolean>(false);
  const [uaDownLoading, setUADownLoading] = useState<boolean>(false);
  const [isStatusSelect, setIsStatusSelect] = useState({ id: 1, type: "All" });
  const [isSelectedType, setIsSelectedType] = useState({
    id: 1,
    type: "Soft Launch",
  });
  const typeList = [
    { id: 1, menu: "Game" },
    { id: 2, menu: "Studio" },
  ];

  const onKeyPress = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (!searchText.length && e.keyCode === 13) {
      setIsLoaing(true);
      return getList().then((res: any) => {
        if (res.status === 401) {
          Cookies.remove("Authentication");

          window.location.reload();
        } else {
          setIsLoaing(false);
          setGameList(res.data);
        }
      });
    }
    if (e.keyCode === 13) {
      setIsLoaing(true);
      isTypeSelect.id === 1
        ? getSearchGameList(searchText).then((res: any) => {
            if (res.status === 401) {
              Cookies.remove("Authentication");
              window.location.reload();
            } else {
              setIsLoaing(false);
              setGameList(res.data);
            }
          })
        : getSearchStudioList(searchText).then((res: any) => {
            if (res.status === 401) {
              Cookies.remove("Authentication");
              window.location.reload();
            } else {
              setIsLoaing(false);
              setGameList(res.data);
            }
          });
    }
  };
  useEffect(() => {
    setIsLoaing(true);
    getList().then((res: any) => {
      if (res.status === 401) {
        Cookies.remove("Authentication");
        window.location.reload();
      } else {
        setIsLoaing(false);
        setGameList(res.data);
      }
    });
  }, [getList]);

  const onCohortDownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    switch (id) {
      case "bundleId":
        return setDownCohortData((prev) => ({ ...prev, bundleId: value }));
      case "startDate":
        return setDownCohortData((prev) => ({ ...prev, startDate: value }));
      case "endDate":
        return setDownCohortData((prev) => ({ ...prev, endDate: value }));
      case "country":
        return setDownCohortData((prev) => ({ ...prev, country: value }));
      case "platform":
        return setDownCohortData((prev) => ({ ...prev, platform: value }));
      default:
        return setDownCohortData((prev) => prev);
    }
  };
  const onCostDownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    switch (id) {
      case "bundleId":
        return setDownCostData((prev) => ({ ...prev, bundleId: value }));
      case "startDate":
        return setDownCostData((prev) => ({ ...prev, startDate: value }));
      case "endDate":
        return setDownCostData((prev) => ({ ...prev, endDate: value }));
      default:
        return setDownCohortData((prev) => prev);
    }
  };
  const onCohortDownClick = () => {
    if (!downCohortData.bundleId.length) {
      alert("Please enter bundleId");
    } else if (!downCohortData.startDate.length) {
      alert("Please enter Start Date");
    } else if (!downCohortData.endDate.length) {
      alert("Please enter End Date");
    } else {
      setDownLoading(true);
      downloadMaxCohort(downCohortData).then((res: any) => {
        if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else {
          fileDownload(
            res.data,
            `${downCohortData.bundleId}_${downCohortData.startDate}_${downCohortData.endDate}.zip`
          );
          setDownLoading(false);
        }
      });
    }
  };

  const onCostMZDownClick = () => {
    if (!downCostData.bundleId.length) {
      alert("Please enter bundleId");
    } else if (!downCostData.startDate.length) {
      alert("Please enter Start Date");
    } else if (!downCostData.endDate.length) {
      alert("Please enter End Date");
    } else {
      setMzDownLoading(true);
      downloadCostMZ(downCostData).then((res: any) => {
        if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else {
          fileDownload(
            res.data,
            `MZ_${downCostData.bundleId}_${downCostData.startDate}_${downCostData.endDate}.csv`
          );
          setMzDownLoading(false);
        }
      });
    }
  };
  const onCostUADownClick = () => {
    if (!downCostData.bundleId.length) {
      alert("Please enter bundleId");
    } else if (!downCostData.startDate.length) {
      alert("Please enter Start Date");
    } else if (!downCostData.endDate.length) {
      alert("Please enter End Date");
    } else {
      setUADownLoading(true);
      downloadCostUA(downCostData).then((res: any) => {
        if (res.status === 401) {
          Cookies.remove("Authentication");
          window.location.reload();
        } else {
          fileDownload(
            res.data,
            `UA_${downCostData.bundleId}_${downCostData.startDate}_${downCostData.endDate}.csv`
          );
          setUADownLoading(false);
        }
      });
    }
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Game Status</h1>
      <section className={styles.list_section}>
        <SearchAppList
          isTypeSelect={isTypeSelect}
          isStatusSelect={isStatusSelect}
          setIsTypeSelect={setIsTypeSelect}
          setIsStatusSelect={setIsStatusSelect}
          setsearchText={setsearchText}
          searchText={searchText}
          typeList={typeList}
          onKeyPress={onKeyPress}
        />
        <section>
          <p className={styles.cohort_title}>Cohort Data</p>
          <section className={styles.down_cohort_input}>
            <div>
              <p>
                bundle Id <span>*</span>
              </p>
              <input
                type="text"
                id="bundleId"
                onChange={onCohortDownChange}
                placeholder="공백없이작성"
              />
            </div>
            <div>
              <p>
                Start Date <span>*</span>
              </p>
              <input
                type="text"
                id="startDate"
                onChange={onCohortDownChange}
                placeholder="YYYY-MM-DD"
              />
            </div>
            <div>
              <p>
                End Date <span>*</span>
              </p>
              <input
                type="text"
                id="endDate"
                onChange={onCohortDownChange}
                placeholder="YYYY-MM-DD"
              />
            </div>
            <div>
              <p>Country</p>
              <input type="text" id="country" onChange={onCohortDownChange} />
            </div>
            <div>
              <p>Platform</p>
              <input type="text" id="platform" onChange={onCohortDownChange} />
            </div>
            <button
              onClick={onCohortDownClick}
              disabled={downLoading ? true : false}
            >
              <p>{downLoading ? "Downloading" : "Download"}</p>
            </button>
          </section>
        </section>
        <section>
          <p className={styles.cost_title}>Adnetwork Data</p>
          <section className={styles.down_cost_input}>
            <div>
              <p>
                bundle Id <span>*</span>
              </p>
              <input
                type="text"
                id="bundleId"
                onChange={onCostDownChange}
                placeholder="공백없이작성"
              />
            </div>
            <div>
              <p>
                Start Date <span>*</span>
              </p>
              <input
                type="text"
                id="startDate"
                onChange={onCostDownChange}
                placeholder="YYYY-MM-DD"
              />
            </div>
            <div>
              <p>
                End Date <span>*</span>
              </p>
              <input
                type="text"
                id="endDate"
                onChange={onCostDownChange}
                placeholder="YYYY-MM-DD"
              />
            </div>

            <button
              onClick={onCostMZDownClick}
              disabled={mzDownLoading ? true : false}
            >
              <p>{mzDownLoading ? "Downloading" : "MZ Download"}</p>
            </button>
            <button
              onClick={onCostUADownClick}
              disabled={mzDownLoading ? true : false}
            >
              <p>{uaDownLoading ? "Downloading" : "UA Download"}</p>
            </button>
          </section>
        </section>
        <GameStatusList
          setIsUpdate={setIsUpdate}
          setIsTerminate={setIsTerminate}
          isLoading={isLoading}
          setIsSelectedType={setIsSelectedType}
          gameList={gameList}
          setSelectItem={setSelectItem}
        />
      </section>
      {isUpdate && (
        <GameStatusUpdateModal
          setIsUpdate={setIsUpdate}
          isSelectedType={isSelectedType}
          setIsSelectedType={setIsSelectedType}
          game={gameList.find((item) => item.id === selectItem)}
        />
      )}
      {isTerminate && (
        <TerminateModal
          setIsTerminate={setIsTerminate}
          game={gameList.find((item) => item.id === selectItem)}
        />
      )}
    </div>
  );
}
