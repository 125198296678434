import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import styles from "./GameStatusUpdateModal.module.scss";
import avatarImg from "../../Img/GameStatus/avatar.png";
import { ReactComponent as DeleteIcon } from "../../Img/GameStatus/delete_x.svg";
import { ReactComponent as DropdownIcon } from "../../Img/GameStatus/dropdown_arrow.svg";
import { ReactComponent as CheckIcon } from "../../Img/GameStatus/check.svg";
import defaultImg from "../../Img/GameStatus/defaultImg.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useGameStatus } from "../../Context/NewContext/GameStatusContext";

export default function GameStatusUpdateModal({
  isSelectedType,
  setIsSelectedType,
  setIsUpdate,
  game,
}) {
  const { updateGameStatus } = useGameStatus();
  const typeList = [
    { id: 1, type: "Dashboard Test" },
    { id: 2, type: "Soft Launch" },
    { id: 3, type: "Commercial Launch" },
  ];

  const pmList = ["Richard", "Leslie", "Ale", "Lena", "Dahlia", "Cemil"];
  const [selectPm, setSelectPm] = useState([]);

  const [isClickStatus, setIsClickStatus] = useState(false);
  const [contractDate, setContractDate] = useState(new Date());
  const [isPmList, setIsPmList] = useState(false);
  const [sacleUpDate, setScaleupDate] = useState(null);

  useEffect(() => {
    game.studio.adminUserIds.map((item) =>
      setSelectPm((prev) => [...prev, item.name])
    );
    if (game.contract) {
      setContractDate(new Date(game.contract));
    }
    if (game.scaleUp) {
      setScaleupDate(new Date(game.scaleUp));
    }

    if (game.stage === "TEST") {
      return setIsSelectedType(typeList[0]);
    } else if (game.stage === "SL") {
      return setIsSelectedType(typeList[1]);
    } else {
      return setIsSelectedType(typeList[2]);
    }
  }, []);

  const isSelectedTypeClick = (id) => {
    return isSelectedType.id === id;
  };

  const ContractInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={styles.contract_date_btn}
      data-content={value.length ? "true" : "false"}
      onClick={onClick}
      ref={ref}
    >
      {value.length ? value : "DD MM YYYY"}
    </button>
  ));
  const ScaleupInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <button
        className={styles.scaleup_date_btn}
        data-content={value.length ? "true" : "false"}
        onClick={onClick}
        ref={ref}
      >
        {value.length ? value : "DD MM YYYY"}
      </button>
    );
  });
  const onPmSelectClick = (pm) => {
    setSelectPm((prev) => [...prev, pm]);
  };
  const onPmDeleteClick = (pm) => {
    setSelectPm((prev) => prev.filter((item) => item !== pm));
  };

  const onUpdateClick = () => {
    const updateData = {
      bundleId: game.bundleId,
      stage:
        isSelectedType.id === 1
          ? "TEST"
          : isSelectedType.id === 2
          ? "SL"
          : "CL",
      contract: moment(contractDate).format("YYYY-MM-DD"),
      scaleUp:
        sacleUpDate !== null ? moment(sacleUpDate).format("YYYY-MM-DD") : null,
      adminUserIds: selectPm,
    };
    return updateGameStatus(updateData).then((res) => {
      if (res.status === 201) {
        return window.location.reload();
      }
    });
  };
  const addDefaultImg = (e) => {
    e.target.src = defaultImg;
  };
  return (
    <section className={styles.container}>
      <section className={styles.contents}>
        <h2 className={styles.title}>Edit</h2>
        <div className={styles.game_name}>
          <img
            className={styles.game_icon}
            src={game.gameIconUrl}
            alt="icon"
            onError={addDefaultImg}
          />
          <span className={styles.game_name_studio}>
            <p className={styles.name}>{game.name}</p>
            <p className={styles.studio_name}>{game.studio.name}</p>
          </span>
        </div>
        <section className={styles.game_status_section}>
          <p className={styles.game_status_title}>Game Status</p>
          <button
            className={styles.selected_type}
            onClick={() => setIsClickStatus((prev) => !prev)}
          >
            <p data-content={isSelectedType.id}>{isSelectedType.type}</p>
            <DropdownIcon
              className={styles.dropdown_selected_type_icon}
              data-clicked={isClickStatus}
            />
            {isClickStatus && (
              <ul className={styles.type_list}>
                {typeList.map((item) => (
                  <li onClick={() => setIsSelectedType(item)}>
                    <p data-id={item.id}>{item.type}</p>
                    {isSelectedTypeClick(item.id) && <CheckIcon />}
                  </li>
                ))}
              </ul>
            )}
          </button>
        </section>
        <section className={styles.date_select_section}>
          <span className={styles.contract_date}>
            <p>Contract Date</p>
            <DatePicker
              className={styles.date_picker}
              selected={contractDate}
              onChange={(date) => setContractDate(date)}
              customInput={<ContractInput />}
              dateFormat="dd MMMM yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="scroll"
              formatWeekDay={(day) => day.substr(0, 1)}
              minDate={new Date("10-01-2022")}
              maxDate={new Date("10-01-2027")}
            />
          </span>
          <span className={styles.scaleup_date}>
            <p>Scale-up Date</p>
            <DatePicker
              selected={sacleUpDate}
              onChange={(date) => setScaleupDate(date)}
              customInput={<ScaleupInput />}
              dateFormat="dd MMMM yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="scroll"
            />
          </span>
        </section>
        <section className={styles.pm_list}>
          <p className={styles.pm_title}>Studio PM</p>
          <button
            className={styles.pm_list_btn}
            onClick={() => setIsPmList((prev) => !prev)}
            data-active={isPmList}
          >
            <p>Manager</p>
            <DropdownIcon />
            {isPmList && (
              <ul className={styles.pm_list_dropdown}>
                {pmList
                  .filter((item) => !selectPm.includes(item))
                  .map((item, idx) => (
                    <li
                      key={Math.random(idx)}
                      onClick={() => onPmSelectClick(item)}
                    >
                      {item}
                    </li>
                  ))}
              </ul>
            )}
          </button>
          <section>
            <ul className={styles.selected_pm_list}>
              {selectPm?.map((item) => (
                <li>
                  <img src={avatarImg} alt="img" />
                  <p>{item}</p>
                  <DeleteIcon onClick={() => onPmDeleteClick(item)} />
                </li>
              ))}
            </ul>
          </section>
        </section>
        <section className={styles.buttons}>
          <button onClick={() => setIsUpdate(false)}>
            <p>Cancel</p>
          </button>
          <button onClick={onUpdateClick}>
            <p>Update</p>
          </button>
        </section>
      </section>
    </section>
  );
}
