import React, { SetStateAction, useState } from "react";
import InvoiceForm from "../InvoiceForm/InvoiceForm";
import styles from "./ConfirmInvoice.module.scss";
import ConfirmInvoiceModal from "../ConfirmInvoiceModal/ConfirmInvoiceModal";
import { useKeyEscClose } from "../../common/escClose/escClose";

export default function ConfirmInvoice({
  setIsCheckInvoiceClick,
  date,
  list,
}: {
  setIsCheckInvoiceClick: React.Dispatch<SetStateAction<boolean>>;
  date: string;
  list: any;
}) {
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const confirmClick = () => {
    setIsConfirmModal(true);
  };
  useKeyEscClose(() => {
    setIsCheckInvoiceClick(false);
  });

  return (
    <section className={styles.container}>
      {isConfirmModal && (
        <ConfirmInvoiceModal
          studioId={list?.studioId}
          date={date}
          setIsConfirmModal={setIsConfirmModal}
        />
      )}
      <section className={styles.contents}>
        <header>
          <p>Check Invoice</p>
        </header>
        <InvoiceForm date={date} list={list} />
        <footer>
          <button onClick={() => setIsCheckInvoiceClick(false)}>Cancel</button>
          <button onClick={confirmClick}>Confirm</button>
        </footer>
      </section>
    </section>
  );
}
