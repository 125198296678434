import React from "react";
import { Routes, Route } from "react-router-dom";
import Complete from "../components/Complete/Complete";

import Progress from "../components/Progress/Progress";
import { AppInfoProvider } from "../Context/AppInfoContext";
import { AuthProvider, useAuth } from "../Context/AuthContext";
import AdsDetail from "../pages/AdsDetail/AdsDetail";
import AppInfo from "../pages/AppInfo/AppInfo";
import Home from "../pages/Home/Home";
import ProfileEdit from "../pages/ProfileEdit/ProfileEdit";
import PublishingDetail from "../pages/PublishingDetail/PublishingDetail";
import Invoice from "../pages/Invoice/Invoice";

import { getCookie } from "../db/cookie";
import InvoiceLogin from "../components/InvoiceLogin/InvoiceLogin";

import { GameListProvider } from "../Context/GameListContext";
import FinanceOverview from "../pages/FinanceOverview/FinanceOverview";
import GameStatus from "../pages/GameStatus/GameStatus";
import GameStatusProvider from "../Context/NewContext/GameStatusContext";
import AdUnitRequest from "../pages/AdUnitRequest/AdUnitRequest";
import NewMaxProvider from "../Context/NewContext/MaxContext";
import AdnetworkProvider from "../Context/Adnetwork";
import MaxProvider from "../Context/MaxContext";
export default function Router({
  adsService,
  adminClientAuth,
  maxService,
  adsNetworkService,
  appInfoService,
  gameInfoService,
  studioInfoService,
  gameStatusService,
  newMaxService,
}) {
  const { user, modifingInfo } = useAuth();

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <GameListProvider
            adsService={adsService}
            studioInfoService={studioInfoService}
          >
            <Home />
          </GameListProvider>
        }
      />
      {/* <Route
        exact
        path="/test/adUnit"
        element={
          getCookie("Authentication") ? (
            <NewMaxProvider
              adsService={adsService}
              newMaxService={newMaxService}
              studioInfoService={studioInfoService}
            >
              <AdUnitRequest />
            </NewMaxProvider>
          ) : (
            <InvoiceLogin path="/test/adUnit" />
          )
        }
      /> */}
      <Route
        exact
        path="/test/progress"
        element={
          <GameListProvider
            adsService={adsService}
            studioInfoService={studioInfoService}
          >
            <Progress />
          </GameListProvider>
        }
      />

      <Route
        exact
        path="/test/progress/:id"
        element={
          <AdsDetail
            adsService={adsService}
            maxService={maxService}
            adminClientAuth={adminClientAuth}
            adsNetworkService={adsNetworkService}
          />
        }
      />

      <Route
        exact
        path="/test/complete"
        element={
          <GameListProvider
            adsService={adsService}
            studioInfoService={studioInfoService}
          >
            <Complete />
          </GameListProvider>
        }
      />

      <Route
        exact
        path="/test/complete/:id"
        element={
          <AdsDetail
            adsService={adsService}
            maxService={maxService}
            adminClientAuth={adminClientAuth}
            adsNetworkService={adsNetworkService}
            appInfoService={appInfoService}
            gameInfoService={gameInfoService}
          />
        }
      />

      <Route
        exact
        path="/profile"
        element={
          <ProfileEdit
            user={user}
            adminClientAuth={adminClientAuth}
            modifingInfo={modifingInfo}
          />
        }
      />

      <Route
        path="/publishing/overview"
        element={
          <AdnetworkProvider adsNetworkService={adsNetworkService}>
            <PublishingDetail />
          </AdnetworkProvider>
        }
      />

      <Route
        path="/pulishing/appInfo"
        element={
          <AppInfoProvider appInfoService={appInfoService}>
            <AppInfo
              appInfoService={appInfoService}
              gameInfoService={gameInfoService}
            />
          </AppInfoProvider>
        }
      />
      <Route
        path="/pulishing/status"
        element={
          getCookie("Authentication") ? (
            <GameStatusProvider gameStatusService={gameStatusService}>
              <GameStatus />
            </GameStatusProvider>
          ) : (
            <InvoiceLogin path="/pulishing/status" />
          )
        }
      />
      <Route
        path="/finance/overview"
        element={
          getCookie("Authentication") ? (
            <FinanceOverview />
          ) : (
            <InvoiceLogin path="/finance/overview" />
          )
        }
      />
      <Route
        path="/finance/invoice"
        element={
          getCookie("Authentication") ? (
            <Invoice />
          ) : (
            <InvoiceLogin path="/finance/invoice" />
          )
        }
      />
    </Routes>
  );
}
